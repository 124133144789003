import useDebounce from '@/common/hooks/use-debounce';
import ClearTwoToneIcon from '@mui/icons-material/ClearTwoTone';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import {
  IconButton,
  InputAdornment,
  TextField as MuiTextField,
  Stack,
  styled,
  useTheme,
  Typography,
} from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import { Trans, useTranslation } from 'next-i18next';
import { useEffect, useRef, useState } from 'react';
import { selectedTablesAtom, storeAtom } from '../state';
import { SecondaryButton } from '@/common/components/buttons';
import {
  reservationSelectSeatedAtom,
  reservationWithMenuAtom,
} from '@/feat/settings/floor-plan-view/share/state';

const TextField = styled(MuiTextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root, & .MuiOutlinedInput-root:hover': {
    '& > fieldset': {
      borderColor: theme.palette.primary.main_32,
    },
  },
  '& .MuiOutlinedInput-root.Mui-focused': {
    '& > fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default function Search() {
  const { t } = useTranslation();
  const theme = useTheme();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const dispatch = useSetAtom(storeAtom);
  const selectedTables = useAtomValue(selectedTablesAtom);
  const setReservationSeated = useSetAtom(reservationSelectSeatedAtom);
  const setReservationWithMenu = useSetAtom(reservationWithMenuAtom);
  const isMultipleTables = selectedTables?.length > 1;

  const [value, setValue] = useState<string>('');
  const debouncedValue = useDebounce(value, 400);

  useEffect(() => {
    dispatch({ type: 'SET_RESERVATION_SEARCH', value: debouncedValue });
  }, [debouncedValue, dispatch]);

  return (
    <TextField
      inputRef={inputRef}
      fullWidth
      placeholder={t('Name, Phone No, Email')}
      InputProps={{
        onChange: (e) => setValue(e.target.value),
        value: value,
        startAdornment: (
          <InputAdornment position="start">
            <IconButton
              disableRipple
              onClick={() => {
                inputRef.current?.focus();
              }}
            >
              <SearchTwoToneIcon
                sx={{
                  fontSize: 24,
                  cursor: 'pointer',
                }}
              />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap={0.5}
          >
            {value ? (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setValue('');
                    inputRef.current?.focus();
                  }}
                >
                  <ClearTwoToneIcon />
                </IconButton>
              </InputAdornment>
            ) : null}

            {selectedTables?.length > 0 ? (
              <SecondaryButton
                variant="outlined"
                endIcon={<ClearTwoToneIcon />}
                onClick={() => {
                  dispatch({ type: 'CLEAR_SELECTED_TABLES' });
                  setReservationSeated(null);
                  setReservationWithMenu(null);
                }}
                sx={{
                  backgroundColor: theme.palette.grey[200],
                }}
              >
                <Typography
                  component="div"
                  variant="body2"
                  sx={{
                    display: 'flex',
                    maxWidth: '100px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontWeight: 700,
                  }}
                >
                  {isMultipleTables ? (
                    selectedTables.map((t) => t.name).join(', ')
                  ) : (
                    <>
                      <Trans>Table</Trans>&nbsp;
                      {selectedTables.map((t) => t.name).join(', ')}
                    </>
                  )}
                </Typography>
              </SecondaryButton>
            ) : null}
          </Stack>
        ),
      }}
    />
  );
}
