import { useSetAtom } from 'jotai';
import { useRef } from 'react';
import { isTransformingAtom } from '../state';

export function useTrackingGroundMovement(): () => void {
  const setTransforming = useSetAtom(isTransformingAtom);
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleTransformed = () => {
    setTransforming(true);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      setTransforming(false);
    }, 200);
  };

  return handleTransformed;
}
