import { useAreas } from '@/common/hooks/use-areas';
import { getUnixTimeMs } from '@/common/lib/date-time';
import { TableBlock } from '@/common/types/table-block';
import BlockIcon from '@mui/icons-material/Block';
import { LoadingButton } from '@mui/lab';
import { Box, Stack, useTheme } from '@mui/material';
import { endOfDay, isSameDay, parse, startOfDay } from 'date-fns';
import { useAtom, useAtomValue } from 'jotai';
import { flatten } from 'lodash-es';
import { Trans } from 'next-i18next';
import { useMemo } from 'react';
import { Z_INDEX } from '../constants';
import { useBlocking } from '../hooks/use-blocking';
import {
  areasAtom,
  selectedDateAtom,
  selectedItemAtom,
  visibleTimelineGroupAtom,
} from '../state';
import { SelectedItemType } from '../types';
import { memo } from 'react';
import { useGetLeftOffset } from '../hooks/use-timeline-util';
import { isEqual } from 'lodash-es';

interface BlockedTableItemProps {
  blockItem: TableBlock & {
    areaId: string;
    tableId: string;
    canvasTimeStart: number;
    canvasTimeEnd: number;
  };
  groupTops: number[];
  groupHeights: number[];
  canvasTimeStart: number;
  canvasTimeEnd: number;
  canvasWidth: number;
}

export const BlockedTableItem = ({
  blockItem,
  groupTops,
  groupHeights,
  canvasTimeStart,
  canvasTimeEnd,
  canvasWidth,
}: BlockedTableItemProps) => {
  const [selectedItem, setSelectedItem] = useAtom(selectedItemAtom);
  const { deleteTableBlock, isDeletingTableBlock } = useBlocking();
  const { refetch: refetchAreas } = useAreas();
  const theme = useTheme();
  const visibleGroups = useAtomValue(visibleTimelineGroupAtom);

  const groupIdx = useMemo(
    () => visibleGroups.findIndex((group) => group.id === blockItem.tableId),
    [blockItem.tableId, visibleGroups]
  );

  const handleSelect = () => {
    setSelectedItem({
      type: SelectedItemType.BLOCKED_TABLE,
      id: blockItem.id,
    });
  };

  const handleUnblock = () => {
    deleteTableBlock(blockItem.id).then(() => {
      refetchAreas();
      setSelectedItem(null);
    });
  };

  const isSelected =
    selectedItem?.type === SelectedItemType.BLOCKED_TABLE &&
    selectedItem.id === blockItem.id;
  const getLeftOffset = useGetLeftOffset(
    canvasTimeStart,
    canvasTimeEnd,
    canvasWidth
  );

  const { start, end } = useMemo(() => {
    return {
      start: getLeftOffset(blockItem.canvasTimeStart),
      end: getLeftOffset(blockItem.canvasTimeEnd),
    };
  }, [blockItem.canvasTimeEnd, blockItem.canvasTimeStart, getLeftOffset]);

  return (
    <Box
      sx={{
        position: 'absolute',
        top: groupTops[groupIdx],
        left: start,
        width: end - start,
        height: groupHeights[groupIdx],
        zIndex: Z_INDEX.areaTableBlockOut,
      }}
    >
      <Stack
        sx={{
          width: '100%',
          height: '100%',
          backgroundSize: 'auto auto',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          backgroundImage: `repeating-linear-gradient(135deg, transparent, transparent 2px, ${
            isSelected
              ? theme.palette.primary.main_12
              : 'rgba(205, 205, 205, 0.8)'
          }  2px, ${
            isSelected
              ? theme.palette.primary.main_12
              : 'rgba(205, 205, 205, 0.8)'
          } 20px )`,
          border: isSelected ? `1px dashed ${theme.palette.grey[500]}` : 'none',
        }}
        onClick={handleSelect}
        alignItems="center"
        justifyContent="center"
      >
        {isSelected && (
          <LoadingButton
            loading={isDeletingTableBlock}
            variant="contained"
            color="primary"
            startIcon={<BlockIcon />}
            onClick={handleUnblock}
          >
            <Trans>Unblock</Trans>
          </LoadingButton>
        )}
      </Stack>
    </Box>
  );
};

const BlockedTableItemMemoized = memo(
  BlockedTableItem,
  (prevProps, nextProps) => {
    return (
      isEqual(prevProps.groupHeights, nextProps.groupHeights) &&
      isEqual(prevProps.blockItem, nextProps.blockItem) &&
      isEqual(prevProps.groupTops, nextProps.groupTops) &&
      prevProps.canvasTimeStart === nextProps.canvasTimeStart &&
      prevProps.canvasTimeEnd === nextProps.canvasTimeEnd &&
      prevProps.canvasWidth === nextProps.canvasWidth
    );
  }
);

interface BlockedTableItemsProps {
  groupTops: number[];
  groupHeights: number[];
  canvasTimeStart: number;
  canvasTimeEnd: number;
  canvasWidth: number;
}

export function BlockedTableItems(props: BlockedTableItemsProps) {
  const areas = useAtomValue(areasAtom);
  const selectedDate = useAtomValue(selectedDateAtom);

  const tableBlocks = useMemo(() => {
    return flatten(
      areas.map((area) => {
        const areaId = area.areaId || area.id || '';
        return flatten(
          area.tables?.map((table) => {
            const tableId = table.id;
            return (
              table.tableBlocks
                ?.filter((it) =>
                  isSameDay(
                    parse(it.date, 'yyyy-MM-dd', new Date()),
                    selectedDate
                  )
                )
                .map((block) => {
                  const startTime = getUnixTimeMs(
                    block.entireDay === 1
                      ? startOfDay(parse(block.date, 'yyyy-MM-dd', new Date()))
                      : parse(
                          `${block.date} ${block.from}`,
                          'yyyy-MM-dd HH:mm:ss',
                          new Date()
                        )
                  );

                  const endTime = getUnixTimeMs(
                    block.entireDay === 1
                      ? endOfDay(parse(block.date, 'yyyy-MM-dd', new Date()))
                      : parse(
                          `${block.date} ${block.to}`,
                          'yyyy-MM-dd HH:mm:ss',
                          new Date()
                        )
                  );
                  return {
                    ...block,
                    areaId,
                    tableId,
                    canvasTimeStart: startTime,
                    canvasTimeEnd: endTime,
                  };
                }) || []
            );
          }) || []
        );
      })
    );
  }, [areas, selectedDate]);

  const visibleTableBlocks = useMemo(() => {
    return tableBlocks.filter(
      (block) =>
        block.entireDay === 1 ||
        (block.canvasTimeEnd > props.canvasTimeStart &&
          block.canvasTimeStart < props.canvasTimeEnd)
    );
  }, [props.canvasTimeEnd, props.canvasTimeStart, tableBlocks]);

  return (
    <>
      {visibleTableBlocks.map((b) => (
        <BlockedTableItemMemoized key={b.id} blockItem={b} {...props} />
      ))}
    </>
  );
}

export default memo(BlockedTableItems);
