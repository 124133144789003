import { PrimaryButton } from '@/common/components/buttons';
import { Box, ButtonProps, Tooltip, useTheme } from '@mui/material';

interface IProps extends ButtonProps {
  description?: string;
  disabled?: boolean;
}

const QuickActionBtn = (props: IProps) => {
  const { children, description, disabled, ...rest } = props;
  const theme = useTheme();

  return (
    <>
      <Tooltip arrow title={description || ''} disableFocusListener>
        <Box onClick={(event) => event.stopPropagation()}>
          <PrimaryButton
            variant="outlined"
            size="large"
            sx={{
              background: '#fff',
              borderColor: theme.palette.grey[500_32],
              color: 'grey.600',
              minWidth: '36px',
              height: '36px',
              p: 0,

              '&:hover': {
                borderColor: theme.palette.grey[500_32],
                color: 'grey.600',
                background: '#fff',
              },

              '&.Mui-disabled': {
                color: theme.palette.grey[500_48],
              },
            }}
            disabled={disabled}
            {...rest}
          >
            {children}
          </PrimaryButton>
        </Box>
      </Tooltip>
    </>
  );
};

export default QuickActionBtn;
