import { Area, AreaTable } from '@/common/types/area';
import { ReservationItem } from '@/common/types/reservation';
import { atom } from 'jotai';
import { atomWithStore } from 'jotai-redux';
import { groupBy, orderBy, sumBy } from 'lodash-es';
import { combineReducers, createStore } from 'redux';
import { TabStatus, statusesTab } from '../list-view/common';
import { canvasListInteractionReducer } from './reducers/canvas-list-interaction';
import { groundControlReducer } from './reducers/ground-control';
import { masterDataReducer } from './reducers/master-data';
import { reservationListReducer } from './reducers/reservation-list';
import { reassignTableReducer } from './reducers/reassign-table';

export const currentTimeAtom = atom<Date>(new Date());

const rootReducer = combineReducers({
  groundControl: groundControlReducer,
  masterData: masterDataReducer,
  reservationList: reservationListReducer,
  canvasListInteraction: canvasListInteractionReducer,
  reassignTable: reassignTableReducer,
});

const store = createStore(rootReducer);
export const storeAtom = atomWithStore(store);

export const tablesAtom = atom((get) => {
  const areas = get(storeAtom).masterData.areas;

  return areas.reduce((acc, it) => {
    return [...acc, ...(it.tables || [])];
  }, [] as AreaTable[]);
});

export const selectedTablesAtom = atom((get) => {
  const { selectedTableIds } = get(storeAtom).canvasListInteraction;
  const tables = get(tablesAtom);

  return tables.filter((it) => selectedTableIds.includes(it.id));
});

export const dataTabsAtom = atom((get) => {
  const { reservations } = get(storeAtom).masterData;
  const { selectedTableIds } = get(storeAtom).canvasListInteraction;
  const { search } = get(storeAtom).reservationList;

  const searchFilteredReservations = search
    ? reservations.filter((it) => {
        const searchTerm = search.toLowerCase();
        return (
          it.customerFirstName?.toLowerCase().includes(searchTerm) ||
          it.customerLastName?.toLowerCase().includes(searchTerm) ||
          it.customerPhone?.toLowerCase().includes(searchTerm) ||
          it.code?.toLowerCase().includes(searchTerm) ||
          it.user?.email?.toLowerCase().includes(searchTerm)
        );
      })
    : reservations;

  const visibleReservations = selectedTableIds.length
    ? searchFilteredReservations.filter((it) => {
        return it.tables?.some((table) => selectedTableIds.includes(table.id));
      })
    : searchFilteredReservations;

  const tabs: {
    type: TabStatus;
    numPax: number;
    numReservations: number;
    data: ReservationItem[];
  }[] = [
    { type: 'ALL', numPax: 0, numReservations: 0, data: [] },
    { type: 'UPCOMING', numPax: 0, numReservations: 0, data: [] },
    { type: 'SEATED', numPax: 0, numReservations: 0, data: [] },
    { type: 'COMPLETED', numPax: 0, numReservations: 0, data: [] },
    { type: 'ABSENT', numPax: 0, numReservations: 0, data: [] },
    { type: 'PENDING', numPax: 0, numReservations: 0, data: [] },
    { type: 'EXPIRED', numPax: 0, numReservations: 0, data: [] },
  ];

  if (!visibleReservations.length) return tabs;

  const groupDataByStatus = groupBy(visibleReservations, (item) => item.status); // is Record<status, reservation[]>
  tabs.forEach((it) => {
    if (it.type === 'ALL') {
      it.numPax = sumBy(
        visibleReservations,
        (reservation) => reservation.pax || 0
      );
      it.numReservations = visibleReservations.length;
      it.data = visibleReservations;
      return;
    }

    statusesTab[it.type].forEach((statusTab) => {
      it.data.push(...(groupDataByStatus[statusTab] || []));
    });
    it.numPax = sumBy(it.data, (reservation) => reservation.pax || 0);
    it.numReservations = it.data.length;
  });

  return tabs;
});

export const reservationsByTableAtom = atom((get) => {
  const { reservations } = get(storeAtom).masterData;
  const visibleReservations = orderBy(
    reservations.filter(
      (r) => r.rStatus !== 'R::NO_SHOW' && r.rStatus !== 'R::CANCELLED'
    ),
    [(r) => r.reservationDate, (r) => r.reservationTime]
  );
  const tableReservations: Record<string, ReservationItem[]> = {};

  visibleReservations.forEach((reservation) => {
    reservation.tables?.forEach((table) => {
      if (!tableReservations[table.id]) {
        tableReservations[table.id] = [];
      }
      tableReservations[table.id]?.push(reservation);
    });
  });

  return tableReservations;
});

export const areaByTableAtom = atom((get) => {
  // return a map of tableId to area
  const { areas } = get(storeAtom).masterData;
  const areaByTableId: Record<string, Area> = {};
  areas.forEach((area) => {
    area.tables?.forEach((table) => {
      areaByTableId[table.id] = area;
    });
  });
  return areaByTableId;
});

export const currentAssigningTablesAtom = atom((get) => {
  const assigningTableIds = get(storeAtom)?.reassignTable?.newTableIds;

  return get(tablesAtom)?.filter((it) => assigningTableIds.includes(it?.id));
});

export const isReassigningModeAtom = atom((get) => {
  const { reassigningReservation } = get(storeAtom).reassignTable;

  return !!reassigningReservation;
});

export const isSeatingModeAtom = atom((get) => {
  const { seatingTableId } = get(storeAtom).canvasListInteraction;
  return !!seatingTableId;
});

export const selectedReservationAtom = atom((get) => {
  const { reservations } = get(storeAtom).masterData;
  const { selectedReservationId } = get(storeAtom).reservationList;
  return selectedReservationId
    ? reservations.find((r) => r.id === selectedReservationId)
    : null;
});

export const isTransformingAtom = atom(false);
