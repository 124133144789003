import {
  useUpdateReservation as useUpdateReservationMutation,
  useUpdateWalkIn,
} from '@/common/hooks/use-reservations';
import { ReservationItem } from '@/common/types/reservation';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import cloneDeep from 'lodash/cloneDeep';
import { useCallback, useEffect } from 'react';
import { getUpdateReservationPayload, getUpdateWalkInPayload } from '../parser';
import {
  reservationChangeProblemsAtom,
  reservationChangesAtom,
  reservationItemByIdAtom,
  reservationItemsAtom,
  timelineItemsByIdAtom,
  updatingReservationAtom,
} from '../state';
import { ReservationChangeType, TimelineItemType } from '../types';
import { useTranslation } from 'next-i18next';
import { useDebounceFn } from '@/common/hooks/use-debounce';

export const useUpdateReservation = () => {
  const timelineItemsById = useAtomValue(timelineItemsByIdAtom);
  const reservationById = useAtomValue(reservationItemByIdAtom);
  const reservationChanges = useAtomValue(reservationChangesAtom);
  const [updatingReservation, setUpdatingReservation] = useAtom(
    updatingReservationAtom
  );
  const { t } = useTranslation();

  const setReservationItems = useSetAtom(reservationItemsAtom);
  const updateWalkInMutation = useUpdateWalkIn();
  const updateReservationMutation = useUpdateReservationMutation();

  const resetUpdatingReservation = useCallback(() => {
    setUpdatingReservation(null);
  }, [setUpdatingReservation]);

  // use debounce here to prevent call applyChanges() many times from usePushReservationUpdate hook
  const applyChanges = useDebounceFn(async () => {
    if (!updatingReservation || updateReservationMutation.isLoading) {
      return;
    }
    if (updatingReservation?.source === 'walkIn') {
      if (
        reservationChanges?.some(
          (c) => c.type === ReservationChangeType.RESERVATION_TIME
        )
      ) {
        alert(
          t(
            'Walk-in timings may not be edited. Please use Reservation bookings for advanced bookings where the timings may be edited'
          )
        );
        resetUpdatingReservation();
        return;
      }

      return updateWalkInMutation
        .mutateAsync(getUpdateWalkInPayload(updatingReservation))
        .then((res) => {
          setReservationItems((prev) =>
            prev.map((it) => (it.id === res.id ? res : it))
          );
          resetUpdatingReservation();
          return res;
        })
        .catch(() => {
          resetUpdatingReservation();
        });
    }
    return updateReservationMutation
      .mutateAsync(getUpdateReservationPayload(updatingReservation))
      .then((res) => {
        setReservationItems((prev) =>
          prev.map((it) => (it.id === res.id ? res : it))
        );
        resetUpdatingReservation();
        return res;
      })
      .catch(() => {
        resetUpdatingReservation();
      });
  }, 100);

  const updateReservation = useCallback(
    (
      reservationItem: ReservationItem,
      updatingValue: Partial<ReservationItem>
    ) => {
      setUpdatingReservation({
        ...cloneDeep(reservationItem),
        ...updatingValue,
      });
    },
    [setUpdatingReservation]
  );

  const getReservationByTimelineItemId = useCallback(
    (itemId: string) => {
      const item = timelineItemsById[itemId];
      if (!item || item.type !== TimelineItemType.RESERVATION) {
        return null;
      }
      const currentReservation = reservationById[item.reservationId];
      return currentReservation;
    },
    [reservationById, timelineItemsById]
  );

  return {
    isUpdatingReservation: updateReservationMutation.isLoading,
    applyChanges,
    updateReservation,
    getReservationByTimelineItemId,
    resetUpdatingReservation,
  };
};

export const usePushReservationUpdate = () => {
  const problems = useAtomValue(reservationChangeProblemsAtom);
  const updatingReservation = useAtomValue(updatingReservationAtom);
  const { applyChanges } = useUpdateReservation();

  useEffect(() => {
    if (problems.length > 0) {
      return;
    }
    applyChanges();
  }, [applyChanges, problems.length, updatingReservation?.source]);
};
