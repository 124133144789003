import React from 'react';
import { useTranslation } from 'next-i18next';
import { TopBarBtn } from '../top-bar-btn';

import { SearchSvg } from '@/feat/reservation/components/list-view/search-svg';
import { isShowSearchBar } from '@/feat/reservation/state';
import { useAtom } from 'jotai';

const SearchButton = (): JSX.Element => {
  const { t } = useTranslation();
  const [isShowSearch, setIsShowSearch] = useAtom(isShowSearchBar);

  const handleOpen = () => {
    setIsShowSearch(!isShowSearch);
  };

  return (
    <>
      <TopBarBtn
        className="SearcnhBtn-root"
        toolTipTitle={t('Search')}
        onClick={handleOpen}
      >
        <SearchSvg fill={'#637381'} />
      </TopBarBtn>
    </>
  );
};

export default SearchButton;
