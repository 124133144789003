import { TabItem } from '@/common/components/tabs';
import { ReservationItem } from '@/common/types/reservation';
import {
  API_RSRV_STATUS_MAP,
  ApiRsrvStatusInt,
} from '@/common/types/reservation-status-flow';
import { Trans } from 'next-i18next';

export const tabLabels = {
  ALL: <Trans>All</Trans>,
  REQUESTED: <Trans>Requested</Trans>,
  UPCOMING: <Trans>Upcoming</Trans>,
  SEATED: <Trans>Seated</Trans>,
  COMPLETED: <Trans>Completed</Trans>,
  ABSENT: <Trans>Absent</Trans>,
  PENDING: <Trans>Pending</Trans>,
  EXPIRED: <Trans>Expired</Trans>,
} as const;

export type TabStatus = keyof typeof tabLabels;

export const statusesTab: Record<TabStatus, ApiRsrvStatusInt[]> = {
  ALL: [
    API_RSRV_STATUS_MAP.BOOKED,
    API_RSRV_STATUS_MAP.CANCELLED,
    API_RSRV_STATUS_MAP.CONFIRMED,
    API_RSRV_STATUS_MAP.COMPLETED,
    API_RSRV_STATUS_MAP.LATE,
    API_RSRV_STATUS_MAP.NO_SHOW,
    API_RSRV_STATUS_MAP.SEATED,
  ],
  REQUESTED: [],
  UPCOMING: [
    API_RSRV_STATUS_MAP.BOOKED,
    API_RSRV_STATUS_MAP.CONFIRMED,
    API_RSRV_STATUS_MAP.LATE,
  ],
  SEATED: [API_RSRV_STATUS_MAP.SEATED],
  COMPLETED: [API_RSRV_STATUS_MAP.COMPLETED],
  ABSENT: [API_RSRV_STATUS_MAP.CANCELLED, API_RSRV_STATUS_MAP.NO_SHOW],
  PENDING: [API_RSRV_STATUS_MAP.PENDING],
  EXPIRED: [API_RSRV_STATUS_MAP.EXPIRED],
};

export interface IDataTab {
  type: TabStatus;
  data: ReservationItem[];
  numPax: number;
  numReservations: number;
}

export interface TabItemsReservation extends TabItem {
  type: TabStatus;
}

export type ListViewProps = {
  isFetching: boolean;
  isSearchActive: boolean;
  setIsSearchActive: React.Dispatch<React.SetStateAction<boolean>>;
  inputSearch: string;
  setInputSearch: React.Dispatch<React.SetStateAction<string>>;
  tab: TabStatus;
  changeTab: (tab: TabStatus) => void;
  dataTabs: IDataTab[];
  tabData: IDataTab['data'];
};
