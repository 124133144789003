import { useAreas } from '@/common/hooks/use-areas';
import { AreaTable } from '@/common/types/area';
import TableBlockOutModal, {
  FormData as BlockOutFormData,
} from '@/feat/settings/table-and-floor-plan/table-blockout-modal';
import { format, isSameDay } from 'date-fns';
import { useAtomValue, useSetAtom } from 'jotai';
import { useBlocking } from '../hooks/use-blocking';
import { selectedDateAtom, selectedItemAtom } from '../state';

export default function BlockingTableDialog() {
  const {
    blockingTable,
    setBlockingTableId,
    createTableBlock,
    isCreatingTableBlock,
  } = useBlocking();
  const setSelectedItem = useSetAtom(selectedItemAtom);
  const { refetch: refetchAreas } = useAreas();
  const selectedDate = useAtomValue(selectedDateAtom);

  const handleChangeTableBlockOut = (
    table: AreaTable,
    blockData: BlockOutFormData
  ) => {
    const { blockIndefinitely, blockTemporarily } = blockData;

    const blocks = blockData.blocks.filter((b) =>
      b.date ? isSameDay(b.date, selectedDate) : false
    );

    createTableBlock({
      tableId: table.id,
      blockIndefinitely,
      date: format(selectedDate, 'yyyy-MM-dd'),
      blockTemporarily:
        blockIndefinitely || !blocks.length ? false : blockTemporarily,
      blocks: blocks.map((b) => ({
        id: b.id,
        date: format(b.date || new Date(), 'yyyy-MM-dd'),
        from: b.from || '00:00:00',
        to: b.to || '23:45:00',
      })),
    }).then(() => {
      setBlockingTableId(null);
      setSelectedItem(null);
      refetchAreas();
    });
  };

  return (
    <TableBlockOutModal
      table={blockingTable || null}
      isOpen={!!blockingTable}
      selectedDate={selectedDate}
      onClose={() => {
        setBlockingTableId(null);
      }}
      onConfirm={handleChangeTableBlockOut}
      isLoading={isCreatingTableBlock}
    />
  );
}
