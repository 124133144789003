import {
  Box,
  Checkbox,
  Menu,
  MenuItem,
  Stack,
  ToggleButton,
  Typography,
  useTheme,
} from '@mui/material';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ReactElement, useMemo, useState } from 'react';
import { filterAtom } from '@/feat/reservation/components/gantt-view/state';
import { useAtom } from 'jotai';
import { Trans } from 'next-i18next';
import {
  RESERVATION_STATUS,
  ReservationStatus,
} from '@/common/types/reservation-status-flow';
import {
  RESERVATION_STATUS_LABELS,
  RESERVATION_STATUS_ICONS,
} from '@/common/components/reservation-status-btn';

export default function ReservationStatusFilter() {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [{ reservationStatus }, setReservationFilter] = useAtom(filterAtom);
  const open = Boolean(anchorEl);
  const theme = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelectStatus = (status: string) => {
    if (!status) return;

    setReservationFilter((prev) => ({
      ...prev,
      reservationStatus: ([] as string[])
        .concat(prev?.reservationStatus as string[])
        .concat(status),
    }));
  };

  const handleToggleStatusItem = (statusValue: string) => {
    if (!!reservationStatus?.includes(statusValue)) {
      setReservationFilter((prev) => ({
        ...prev,
        reservationStatus: reservationStatus?.filter(
          (item) => item !== statusValue
        ),
      }));
      return;
    }

    handleSelectStatus(statusValue);
  };

  const statusOptions: {
    label: ReactElement;
    icon: ReactElement;
    value: ReservationStatus;
  }[] = useMemo(
    () =>
      RESERVATION_STATUS.reduce(
        (acc, cur) => {
          const label = RESERVATION_STATUS_LABELS[cur];
          const icon = RESERVATION_STATUS_ICONS[cur];

          if (!label || !icon) return acc;

          return [
            ...acc,
            {
              value: cur as ReservationStatus,
              label,
              icon,
            },
          ];
        },
        [] as {
          label: ReactElement;
          icon: ReactElement;
          value: ReservationStatus;
        }[]
      ),
    []
  );

  return (
    <>
      <Stack flexDirection="row" gap={0.5} alignItems="center" flexWrap="wrap">
        <ToggleButton
          value=""
          sx={{
            background: theme.palette.common.white,
            touchAction: 'manipulation',
          }}
          size="small"
          onClick={handleClick}
        >
          <Stack direction="row" spacing={3}>
            <Stack direction="row">
              <Box px={0.5} display="flex" justifyItems="center">
                <RestaurantIcon fontSize="medium" />
              </Box>
              <Typography>
                <Trans>Status</Trans>
              </Typography>
            </Stack>
            <KeyboardArrowDownIcon fontSize="medium" />
          </Stack>
        </ToggleButton>
      </Stack>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {statusOptions.map(({ label, icon, value }, index) => (
          <MenuItem key={index} onClick={() => handleToggleStatusItem(value)}>
            <Checkbox checked={!!reservationStatus?.includes(value)} />
            <Stack direction="row" gap={1} alignItems="center">
              {icon}
              {label}
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
