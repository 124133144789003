import useResponsive from '@/minimals.cc/hooks/useResponsive';
import { Box } from '@mui/material';
import Canvas from './canvas';
import { useCurrentTime } from './hooks/use-current-time';
import useInitState from './hooks/use-init-state';
import ReservationPanel from './reservation-panel';
import ReassignTableToast from './reassign-table-toast';

export const FloorPlanView = () => {
  const isMobile = useResponsive('down', 'md');

  // Effects
  useInitState();
  useCurrentTime();

  return (
    <>
      <Box
        sx={{
          height: '100%',
          overflowY: 'hidden',
          display: 'flex',
        }}
      >
        <ReservationPanel />
        {!isMobile && <Canvas />}
        <ReassignTableToast />
      </Box>
    </>
  );
};
