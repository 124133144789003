import { useEffect, useRef, RefObject } from 'react';

export function useResizeObserver(
  ref: RefObject<HTMLElement>,
  callback: (width: number, height: number) => void
): void {
  const observerRef = useRef<ResizeObserver | null>(null);

  useEffect(() => {
    const elm = ref.current;
    observerRef.current = new ResizeObserver((entries) => {
      for (let entry of entries) {
        callback(entry.contentRect.width, entry.contentRect.height);
      }
    });

    if (elm) {
      observerRef.current.observe(elm);
    }

    return () => {
      if (elm && observerRef.current) {
        observerRef.current.unobserve(elm);
      }
    };
  }, [ref, callback]);
}
