import { ActionType } from 'react-table';

export interface ReservationListState {
  currentTab: string;
  isReservationPanelMinimized: boolean;
  search: string;
  selectedReservationId: string | null;
}

const initialState: ReservationListState = {
  currentTab: 'ALL',
  isReservationPanelMinimized: false,
  search: '',
  selectedReservationId: null,
};

export const reservationListReducer = (
  state = initialState,
  action: ActionType
): ReservationListState => {
  switch (action.type) {
    case 'SET_RESERVATION_SEARCH': {
      return {
        ...state,
        search: action.value,
      };
    }
    case 'SET_RESERVATION_TAB': {
      return {
        ...state,
        currentTab: action.value,
      };
    }
    case 'TOGGLE_RESERVATION_PANEL': {
      return {
        ...state,
        isReservationPanelMinimized: !state.isReservationPanelMinimized,
      };
    }
    case 'MINIMIZE_RESERVATION_PANEL': {
      return {
        ...state,
        isReservationPanelMinimized: action.isReservationPanelMinimized,
      };
    }
    case 'TOGGLE_SELECTED_RESERVATION': {
      return {
        ...state,
        selectedReservationId:
          state.selectedReservationId === action.reservationId
            ? null
            : action.reservationId,
      };
    }
    default:
      return state;
  }
};
