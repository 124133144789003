import { Stack, Card, Button } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Typography } from '@mui/material';
import { Trans, useTranslation } from 'next-i18next';
import { useAtomValue, useAtom } from 'jotai';
import {
  isReassigningModeAtom,
  currentAssigningTablesAtom,
  storeAtom,
} from '@/feat/reservation/components/floor-plan-view/state';
import useI18nTimeUtils from '@/common/i18n-time-utils';
import {
  useUpdateReservation,
  useUpdateWalkIn,
} from '@/common/hooks/use-reservations';
import {
  getUpdateReservationPayload,
  getUpdateWalkInPayload,
} from '@/feat/reservation/components/gantt-view/parser';
import { LoadingButton } from '@mui/lab';
import { useCustomerNameFmt } from '@/common/hooks/use-customers';
import { ReservationItem } from '@/common/types/reservation';
import ReassignTableWarning from './reassign-table-warning';
import { useSnackbar } from '@/common/components/snack-bar';

export default function ReassignTableToast() {
  const updateReservationMutation = useUpdateReservation();
  const updateWalkIn = useUpdateWalkIn();

  const isReassigningMode = useAtomValue(isReassigningModeAtom);
  const [
    {
      reassignTable: { reassigningReservation, newTableIds },
    },
    dispatch,
  ] = useAtom(storeAtom);
  const newTables = useAtomValue(currentAssigningTablesAtom);

  const snackbar = useSnackbar();
  const { t } = useTranslation();
  const { to12HourBase } = useI18nTimeUtils();
  const customerNameFmt = useCustomerNameFmt();
  const customerName =
    reassigningReservation?.user &&
    customerNameFmt(reassigningReservation?.user);

  const handleExitReassigningFlow = () => {
    dispatch({
      type: 'CLEAR_REASSIGNING_RESERVATION',
    });
    dispatch({
      type: 'MINIMIZE_RESERVATION_PANEL',
      isReservationPanelMinimized: false,
    });
  };

  const handleReassignTable = async () => {
    if (reassigningReservation?.isWalkIn) {
      await updateWalkIn.mutateAsync({
        ...getUpdateWalkInPayload(reassigningReservation as ReservationItem),
        tableIds: [...newTableIds],
      });
    } else {
      await updateReservationMutation.mutateAsync({
        ...getUpdateReservationPayload(
          reassigningReservation as ReservationItem
        ),
        tableIds: [...newTableIds],
      });
    }

    snackbar.showMessage(t('Reservation has been re-assigned successfully'));
    if (reassigningReservation?.rStatus === 'R::SEATED') {
      dispatch({
        type: 'SET_SELECTED_TABLE',
        tableId: [...newTableIds],
      });
    }
    handleExitReassigningFlow();
  };

  return (
    <Stack
      alignItems="center"
      sx={{
        position: 'absolute',
        bottom: 32,
        left: 0,
        padding: (theme) => theme.spacing(0, 2),
        zIndex: 10,
        width: '100%',
        ...(!isReassigningMode && {
          visibility: 'hidden',
        }),
      }}
    >
      <Card
        sx={{
          width: {
            xs: '100%',
            lg: '80%',
          },
          p: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 2,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          sx={{ maxWidth: '30%' }}
        >
          <Typography color="primary" variant="subtitle1">
            {t(`Table {{currentTables}}`, {
              currentTables: reassigningReservation?.tables
                ?.map((t) => t?.name)
                .join(', '),
            })}
          </Typography>

          <ArrowRightAltIcon sx={{ width: 32, height: 32 }} />

          {!newTables?.length ? (
            <Typography color="textSecondary" variant="body1">
              <Trans>Select New Table</Trans>
            </Typography>
          ) : (
            <Typography color="primary" variant="subtitle1">
              {t(`Table {{newTables}}`, {
                newTables: newTables?.map((t) => t?.name).join(', '),
              })}
            </Typography>
          )}
        </Stack>

        <Stack alignItems="center" gap={0.25} sx={{ maxWidth: '50%' }}>
          <Stack direction="row" alignItems="center" gap={1.5}>
            <Typography variant="subtitle1">{customerName}</Typography>
            <Typography variant="body1" color="textSecondary">
              {t(`{{pax}} Pax`, { pax: reassigningReservation?.pax })}
            </Typography>
            {reassigningReservation?.reservationTime && (
              <Typography
                variant="body1"
                color="textSecondary"
                sx={{ textTransform: 'uppercase' }}
              >
                {to12HourBase(reassigningReservation?.reservationTime)}
              </Typography>
            )}
          </Stack>

          <ReassignTableWarning />
        </Stack>

        <Stack
          direction={{
            xs: 'column-reverse',
            lg: 'row',
          }}
          alignItems="center"
          gap={1}
          sx={{
            maxWidth: '20%',
            minWidth: {
              xs: 'none',
              lg: 215,
            },
          }}
        >
          <Button
            variant="outlined"
            size="large"
            sx={{
              width: '100%',
            }}
            onClick={handleExitReassigningFlow}
          >
            <Trans>Cancel</Trans>
          </Button>

          <LoadingButton
            variant="contained"
            size="large"
            sx={{ minWidth: 120 }}
            onClick={handleReassignTable}
            loading={updateReservationMutation.isLoading}
            disabled={!newTableIds?.length}
          >
            <Trans>Re-assign</Trans>
          </LoadingButton>
        </Stack>
      </Card>
    </Stack>
  );
}
