import { OnItemDragObjectMove } from '@oddle.me/react-calendar-timeline';
import { endOfDay, format, fromUnixTime, startOfDay } from 'date-fns';
import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  INTERVAL_TIME_TO_SCROLL_LEFT_RIGHT,
  INTERVAL_TIME_TO_SCROLL_TOP_BOT,
  SCROLL_ELM_EDGE_OFFSET,
  SIDEBAR_WIDTH,
} from '../constants';
import {
  itemDraggingAtom,
  outerScrollElAtom,
  selectedDateAtom,
  selectedItemAtom,
  tableByIdAtom,
  timelineItemsByIdAtom,
  visibleTimeAtom,
  visibleTimelineGroupAtom,
} from '../state';
import { TimelineItemType } from '../types';
import { useUpdateReservation } from './use-update-reservation';

export const useDragging = () => {
  const visibleTimelineGroups = useAtomValue(visibleTimelineGroupAtom);
  const { updateReservation, getReservationByTimelineItemId } =
    useUpdateReservation();
  const tableById = useAtomValue(tableByIdAtom);
  const timelineItemsById = useAtomValue(timelineItemsByIdAtom);
  const setSelectedItem = useSetAtom(selectedItemAtom);
  const setItemDragging = useSetAtom(itemDraggingAtom);

  const moveReservation = useCallback(
    (itemId: string, dragTime: number, newGroupOrder: number) => {
      setItemDragging({ isDragging: false, obj: null });
      const item = timelineItemsById[itemId];
      if (!item || item.type !== TimelineItemType.RESERVATION) {
        return;
      }
      const currentReservation = getReservationByTimelineItemId(itemId);
      if (!currentReservation) {
        return;
      }
      const group = visibleTimelineGroups[newGroupOrder];
      if (!group || group.type !== 'table') {
        return;
      }
      const newTable = tableById[group.id];
      if (!newTable) {
        return;
      }
      let newTables =
        currentReservation.tables?.map((it) =>
          it.id === item.tableId ? newTable : it
        ) ?? [];
      const [, newTime] = format(
        fromUnixTime(dragTime / 1000),
        'yyyy-MM-dd HH:mm:ss'
      ).split(' ');
      updateReservation(currentReservation, {
        reservationTime: newTime,
        tables: newTables,
      });
      setSelectedItem(null);
    },
    [
      setItemDragging,
      timelineItemsById,
      getReservationByTimelineItemId,
      visibleTimelineGroups,
      tableById,
      updateReservation,
      setSelectedItem,
    ]
  );

  const handleItemDragging = useCallback(
    (obj: OnItemDragObjectMove) => {
      setItemDragging({ isDragging: true, obj });
    },
    [setItemDragging]
  );

  return { moveReservation, handleItemDragging };
};

export const useAutoScroll = () => {
  const scrollElm = useAtomValue(outerScrollElAtom);
  const setVisibleTime = useSetAtom(visibleTimeAtom);
  const selectedDate = useAtomValue(selectedDateAtom);
  const startOfDate = useMemo(
    () => startOfDay(selectedDate).valueOf(),
    [selectedDate]
  );
  const endOfDate = useMemo(
    () => endOfDay(selectedDate).valueOf(),
    [selectedDate]
  );

  const mousePosition = useMousePositionInScrollElm();

  const scrollElmHeight = scrollElm?.clientHeight ?? 0;
  const scrollElmWidth = scrollElm?.clientWidth ?? 0;

  const mouseSensor = useMemo(() => {
    if (!mousePosition) {
      return null;
    }
    return {
      isNearBottom: mousePosition.y >= scrollElmHeight - SCROLL_ELM_EDGE_OFFSET,
      isNearTop: mousePosition.y <= SCROLL_ELM_EDGE_OFFSET,
      isNearRight: mousePosition.x >= scrollElmWidth - SCROLL_ELM_EDGE_OFFSET,
      isNearLeft: mousePosition.x <= SCROLL_ELM_EDGE_OFFSET + SIDEBAR_WIDTH,
    };
  }, [mousePosition, scrollElmHeight, scrollElmWidth]);

  useEffect(() => {
    let leftRightInterval: ReturnType<typeof setInterval> | null = null;
    let topBotInterval: ReturnType<typeof setInterval> | null = null;
    if (mouseSensor) {
      if (mouseSensor.isNearTop) {
        topBotInterval = setInterval(() => {
          scrollElm?.scrollBy({
            top: -SCROLL_ELM_EDGE_OFFSET,
            behavior: 'smooth',
          });
        }, INTERVAL_TIME_TO_SCROLL_TOP_BOT);
      } else if (mouseSensor.isNearBottom) {
        topBotInterval = setInterval(() => {
          scrollElm?.scrollBy({
            top: SCROLL_ELM_EDGE_OFFSET,
            behavior: 'smooth',
          });
        }, INTERVAL_TIME_TO_SCROLL_TOP_BOT);
      }

      if (mouseSensor.isNearLeft) {
        leftRightInterval = setInterval(() => {
          setVisibleTime((prev) => {
            const dist = prev.end - prev.start;
            const newStart = Math.max(prev.start - 10 * 60 * 1000, startOfDate);
            return {
              start: newStart,
              end: newStart + dist,
            };
          });
        }, INTERVAL_TIME_TO_SCROLL_LEFT_RIGHT);
      } else if (mouseSensor.isNearRight) {
        leftRightInterval = setInterval(() => {
          setVisibleTime((prev) => {
            const dist = prev.end - prev.start;
            const newEnd = Math.min(prev.end + 10 * 60 * 1000, endOfDate);
            return {
              start: newEnd - dist,
              end: newEnd,
            };
          });
        }, INTERVAL_TIME_TO_SCROLL_LEFT_RIGHT);
      }
    }
    return () => {
      leftRightInterval && clearInterval(leftRightInterval);
      topBotInterval && clearInterval(topBotInterval);
    };
  }, [endOfDate, mouseSensor, scrollElm, setVisibleTime, startOfDate]);
};

const useMousePositionInScrollElm = () => {
  const scrollElm = useAtomValue(outerScrollElAtom);
  const itemDragging = useAtomValue(itemDraggingAtom);

  const [position, setPosition] = useState<{ x: number; y: number } | null>(
    null
  );

  useEffect(() => {
    const mouseMoveHandler = (event: MouseEvent) => {
      if (!scrollElm) {
        return;
      }
      const rect = scrollElm.getBoundingClientRect();
      const x = event.clientX - rect.left; // x position within the element.
      const y = event.clientY - rect.top; // y position within the element.
      setPosition({ x, y });
    };

    const touchMoveHandler = (event: TouchEvent) => {
      if (!scrollElm) {
        return;
      }
      const rect = scrollElm.getBoundingClientRect();
      const touch = event.touches[0];
      const x = (touch?.clientX ?? 0) - rect.left; // x position within the element.
      const y = (touch?.clientY ?? 0) - rect.top; // y position within the element.
      setPosition({ x, y });
    };

    if (itemDragging.isDragging) {
      scrollElm?.addEventListener('mousemove', mouseMoveHandler);
      scrollElm?.addEventListener('touchmove', touchMoveHandler);
    }
    return () => {
      scrollElm?.removeEventListener('mousemove', mouseMoveHandler);
      scrollElm?.addEventListener('touchmove', touchMoveHandler);
    };
  }, [itemDragging.isDragging, scrollElm]);

  return itemDragging.isDragging ? position : null;
};
