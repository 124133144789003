import { useAreas } from '@/common/hooks/use-areas';
import { Area } from '@/common/types/area';
import AreaBlockOutModal, {
  FormData as BlockOutFormData,
} from '@/feat/settings/table-and-floor-plan/area-blockout-modal';
import { format, isSameDay } from 'date-fns';
import { useAtomValue, useSetAtom } from 'jotai';
import { useBlocking } from '../hooks/use-blocking';
import { selectedDateAtom, selectedItemAtom } from '../state';

export default function BlockingAreaDialog() {
  const {
    blockingArea,
    setBlockingAreaId,
    createAreaBlock,
    isCreatingAreaBlock,
  } = useBlocking();
  const setSelectedItem = useSetAtom(selectedItemAtom);
  const { refetch: refetchAreas } = useAreas();
  const selectedDate = useAtomValue(selectedDateAtom);

  const areaId = blockingArea?.areaId || blockingArea?.id || '';

  const handleChangeAreaBlockOut = (
    area: Area,
    blockData: BlockOutFormData
  ) => {
    const { blockTemporarily, blockIndefinitely } = blockData;

    const blocks = blockData.blocks.filter((b) =>
      b.date ? isSameDay(b.date, selectedDate) : false
    );

    createAreaBlock({
      id: areaId,
      blockIndefinitely,
      blockTemporarily: !blocks.length ? false : blockTemporarily,
      date: format(selectedDate, 'yyyy-MM-dd'),
      blocks: blocks.map((b) => ({
        id: b.id,
        date: format(b.date || new Date(), 'yyyy-MM-dd'),
        from: b.from || '00:00:00',
        to: b.to || '23:45:00',
      })),
    }).then(() => {
      setBlockingAreaId(null);
      setSelectedItem(null);
      refetchAreas();
    });
  };

  return (
    <AreaBlockOutModal
      area={blockingArea || null}
      isOpen={!!blockingArea}
      selectedDate={selectedDate}
      onClose={() => {
        setBlockingAreaId(null);
      }}
      onConfirm={handleChangeAreaBlockOut}
      isLoading={isCreatingAreaBlock}
    />
  );
}
