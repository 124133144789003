import { PrimaryButton, SecondaryButton } from '@/common/components/buttons';
import DatePicker from '@/common/components/date-picker';
import Select from '@/common/components/select';
import { AreaTable } from '@/common/types/area';
import { zodResolver } from '@hookform/resolvers/zod';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteTwoTone';
import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Stack,
  Switch,
  Typography,
  useTheme,
} from '@mui/material';
import { isSameDay, parse, startOfDay } from 'date-fns';
import { nanoid } from 'nanoid';
import { Trans, useTranslation } from 'next-i18next';
import { useEffect, useMemo } from 'react';
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { z } from 'zod';
import { generateBlockingTimeOptions } from './util';
import { useReservationsByTable } from '@/common/hooks/use-reservations';
import WarningAmberTwoToneIcon from '@mui/icons-material/WarningAmberTwoTone';
import { Box } from '@mui/material';

const blockSchema = z.object({
  id: z.string(),
  date: z.date(),
  from: z.string(),
  to: z.string(),
});

const formDataSchema = z.object({
  blockIndefinitely: z.boolean(),
  blockTemporarily: z.boolean(),
  blocks: z.array(blockSchema),
});

export type FormData = z.infer<typeof formDataSchema>;
export type TableBlock = z.infer<typeof blockSchema>;

interface Props {
  table: AreaTable | null;
  isOpen: boolean;
  isLoading?: boolean;
  selectedDate?: Date;
  onClose: () => void;
  onConfirm: (table: AreaTable, data: FormData) => void;
}

export default function TableBlockOutModal({
  isOpen,
  isLoading,
  onClose,
  selectedDate,
  table,
  onConfirm,
}: Props) {
  const { t } = useTranslation();
  const theme = useTheme();

  const defaultValues: FormData = useMemo(() => {
    const blocks =
      table?.tableBlocks?.filter((b) => {
        if (!selectedDate) {
          return (
            parse(b.date, 'yyyy-MM-dd', new Date()) >= startOfDay(new Date())
          );
        }
        return isSameDay(selectedDate, parse(b.date, 'yyyy-MM-dd', new Date()));
      }) || [];
    return {
      blockIndefinitely: !table?.enabled,
      blockTemporarily: !!blocks.length,
      blocks:
        blocks.map((t) => ({
          id: t.id,
          from: t.from,
          to: t.to,
          date: parse(t.date, 'yyyy-MM-dd', new Date()),
        })) || [],
    };
  }, [selectedDate, table?.enabled, table?.tableBlocks]);

  const { control, handleSubmit, reset, watch } = useForm<FormData>({
    resolver: zodResolver(formDataSchema),
    defaultValues,
  });

  const blockIndefinitely = watch('blockIndefinitely');
  const blockTemporarily = watch('blockTemporarily');

  const { fields, append, remove } = useFieldArray<FormData, 'blocks'>({
    control,
    name: 'blocks',
  });

  const handleClose = () => {
    reset();
    onClose();
  };

  const onSubmit: SubmitHandler<FormData> = (data) => {
    if (!table) return;
    onConfirm(table, data);
  };

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);
  const { data: dataRes } = useReservationsByTable({
    tableId: table?.id || '',
    status: 1,
  });

  const isTableHasReservation = Array.isArray(dataRes) && dataRes.length > 0;

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="sm">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          <Trans>
            Block {{ tableName: table?.name }} ({{ minPax: table?.minPax }}-
            {{ maxPax: table?.maxPax }})
          </Trans>
        </DialogTitle>
        {isTableHasReservation && (
          <Stack
            sx={{
              backgroundColor: '#FDE1D3',
              borderRadius: 1,
              mx: 2,
            }}
          >
            <Box
              sx={{
                flexDirection: 'row',
                ml: 1,
                p: 2,
                display: 'flex',
              }}
            >
              <WarningAmberTwoToneIcon color={'warning'} fontSize="small" />

              <Typography
                sx={{
                  color: theme.palette.warning.main,
                  fontSize: '14px',
                  fontWeight: '400',
                  mx: 1,
                }}
              >
                <Trans>
                  This table is currently in a reservation, please check again
                  before blocking the table
                </Trans>
              </Typography>
            </Box>
          </Stack>
        )}
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>
              <Trans>Block Indefinitely</Trans>
            </Typography>
            <FormControl>
              <Controller
                name="blockIndefinitely"
                control={control}
                render={({ field }) => (
                  <Switch {...field} checked={field.value} />
                )}
              />
            </FormControl>
          </Stack>
          {!blockIndefinitely && (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography>
                <Trans>Block Temporarily</Trans>
              </Typography>
              <Controller
                name="blockTemporarily"
                control={control}
                render={({ field }) => (
                  <Switch {...field} checked={field.value} />
                )}
              />
            </Stack>
          )}
          {!blockIndefinitely && blockTemporarily && (
            <Stack justifyContent="flex-start" gap={2}>
              {fields.map((field, index) => {
                const fromOptions = generateBlockingTimeOptions(
                  '00:00:00',
                  '23:45:00'
                );
                const toOptions = generateBlockingTimeOptions(
                  '00:00:00',
                  '23:45:00'
                );
                return (
                  <Grid container key={field.id} columnSpacing={1}>
                    <Grid item xs={5}>
                      <Controller
                        name={`blocks.${index}.date`}
                        control={control}
                        render={({
                          field: { ref, value, onChange, ...restField },
                        }) => (
                          <DatePicker
                            {...restField}
                            disabled={!!selectedDate}
                            inputRef={ref}
                            label={t('Date')}
                            value={value || null}
                            onChange={(value) => onChange(value)}
                            desktopModeMediaQuery={theme.breakpoints.up('sm')}
                            minDate={new Date()}
                            InputProps={{ size: 'small' }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        control={control}
                        name={`blocks.${index}.from`}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={fromOptions}
                            label={t('From')}
                            size="small"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Controller
                        control={control}
                        name={`blocks.${index}.to`}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={toOptions}
                            label={t('To')}
                            size="small"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <SecondaryButton
                        variant="outlined"
                        size="small"
                        sx={{
                          minWidth: 36,
                          p: 1,
                        }}
                        onClick={() => {
                          remove(index);
                        }}
                      >
                        <DeleteIcon fontSize="small" color="error" />
                      </SecondaryButton>
                    </Grid>
                  </Grid>
                );
              })}
              <PrimaryButton
                variant="text"
                startIcon={<AddIcon />}
                onClick={() => {
                  append({
                    id: nanoid(),
                    date: selectedDate || new Date(),
                    from: '00:00:00',
                    to: '23:45:00',
                  });
                }}
              >
                <Trans>Add Block Periods</Trans>
              </PrimaryButton>
            </Stack>
          )}
        </DialogContent>
        <DialogActions>
          <SecondaryButton variant="text" onClick={handleClose}>
            <Trans>Cancel</Trans>
          </SecondaryButton>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            color="primary"
            type="submit"
          >
            <Trans>Confirm</Trans>
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
