import { useTopNavDate } from '@/common/hooks/storage';
import { useAreas } from '@/common/hooks/use-areas';
import { useRefetchReservationsOnEvents } from '@/common/hooks/use-refetch-reservation-on-event';
import { useReservations } from '@/common/hooks/use-reservations';
import { format } from 'date-fns';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { storeAtom } from '../state';

export default function useInitState() {
  const [, dispatch] = useAtom(storeAtom);
  const [topNavDate] = useTopNavDate();
  const { data: areas, isLoading: areasLoading } = useAreas();

  const {
    data,
    isLoading: reservationLoading,
    refetch,
  } = useReservations({
    params: {
      date: format(topNavDate, 'yyyy-MM-dd'),
    },
  });

  useRefetchReservationsOnEvents(refetch);

  useEffect(() => {
    console.log('loadingChanged');
    dispatch({
      type: 'SET_DATA_LOADING',
      value: areasLoading || reservationLoading,
    });
  }, [areasLoading, dispatch, reservationLoading]);

  useEffect(() => {
    dispatch({
      type: 'INIT_STATE_DATA',
      data: {
        areas: areas || [],
        reservations: data?.data || [],
      },
    });
  }, [dispatch, data?.data, areas]);
}
