import { Stack, Typography } from '@mui/material';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import { Trans, useTranslation } from 'next-i18next';
import useI18nTimeUtils from '@/common/i18n-time-utils';
import { useAtomValue } from 'jotai';
import { TableError } from '@/feat/reservation/components/add-reservation/assign-table-menu/type';
import {
  areaByTableAtom,
  currentAssigningTablesAtom,
  reservationsByTableAtom,
  storeAtom,
} from '@/feat/reservation/components/floor-plan-view/state';
import { assertUnreachable } from '@/common/utils';
import { handleFilterTableErrors } from '../../add-reservation/assign-table-menu/utils';
import { useMemo } from 'react';
import { useSelectedTime } from '../hooks/use-current-time';
import { getTableErrors } from '../utils';
import { ReservationItem } from '@/common/types/reservation';
import useServiceTimingItem from '../hooks/use-service-timing-item';
import { ServiceTiming } from '@/common/types/schedule';
import { Area } from '@/common/types/area';
export default function ReassignTableWarning() {
  const { formatDate } = useI18nTimeUtils();
  const currentAssigningTables = useAtomValue(currentAssigningTablesAtom);
  const {
    reassignTable: { reassigningReservation },
  } = useAtomValue(storeAtom);
  const selectedServiceTiming = useServiceTimingItem();

  const maxPax = currentAssigningTables?.reduce((acc, cur) => {
    return (acc += cur?.maxPax || 0);
  }, 0);

  const selectedTime = useSelectedTime();
  const reservationsByTable = useAtomValue(reservationsByTableAtom);
  const areaByTableId = useAtomValue(areaByTableAtom);

  const errors = useMemo(() => {
    let tableErrors = [] as TableError[];

    currentAssigningTables.forEach((table) => {
      const error = getTableErrors(
        table,
        selectedTime,
        reservationsByTable[table.id] || [],
        areaByTableId,
        reassigningReservation as ReservationItem,
        maxPax,
        selectedServiceTiming as ServiceTiming,
        areaByTableId[table?.id] as Area
      );
      tableErrors = [...tableErrors, ...error] as TableError[];
    });

    return handleFilterTableErrors(tableErrors);
  }, [
    areaByTableId,
    maxPax,
    reassigningReservation,
    reservationsByTable,
    currentAssigningTables,
    selectedTime,
    selectedServiceTiming,
  ]);

  return (
    <Stack>
      {errors?.map((error, index) => {
        return (
          <Stack
            key={index}
            direction="row"
            alignItems="flex-start"
            sx={{
              mt: 1,
              color: 'warning.dark',
            }}
          >
            {error?.source === 'maxPax' ? (
              <InfoTwoToneIcon sx={{ color: 'secondary.main', mr: '5px' }} />
            ) : (
              <WarningTwoToneIcon sx={{ color: 'warning.main', mr: '5px' }} />
            )}
            <Typography
              variant="caption"
              sx={{
                ...(error?.source === 'maxPax' && {
                  color: 'secondary.main',
                }),
              }}
            >
              <WarningMessage error={error} formatDateInView={formatDate} />
            </Typography>
          </Stack>
        );
      })}
    </Stack>
  );
}
function WarningMessage({
  error,
  formatDateInView,
}: {
  error: TableError;
  formatDateInView: (
    date: Date | number,
    formats: { en: string; zh?: string }
  ) => string;
}) {
  const { source, value, conflictValue } = error;
  let warnText = null;
  const { t } = useTranslation();
  switch (source) {
    case 'maxPax': {
      warnText = (
        <>
          {t(`You will have {{ pax }} additional vacant seats.`, {
            pax: conflictValue,
          })}
        </>
      );
      break;
    }
    case 'minPax': {
      warnText = (
        <>
          {t(`Short of {{ pax }} seats. Please select more tables.`, {
            pax: conflictValue,
          })}
        </>
      );
      break;
    }
    case 'clashed': {
      const reservationTime = formatDateInView(value as Date, { en: 'h:mm a' });
      warnText = (
        <Trans>
          Table selected has an upcoming reservation at {{ reservationTime }},
          adding a reservation will result in a clash.
        </Trans>
      );
      break;
    }
    case 'occupied': {
      const reservationTime = formatDateInView(value as Date, { en: 'h:mm a' });
      warnText = (
        <Trans>
          Table selected is occupied until {{ reservationTime }}, seating
          customer will result in a clash.
        </Trans>
      );
      break;
    }
    case 'outsideAreasSchedule': {
      warnText = (
        <Trans>
          Only tables in {{ value }} areas are bookable options online.
        </Trans>
      );
      break;
    }
    case 'blocked': {
      warnText = (
        <Trans>
          Selected table is currently blocked out, proceed to accept clash.
        </Trans>
      );
      break;
    }
    default:
      assertUnreachable(source);
      break;
  }
  return warnText;
}
