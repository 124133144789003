import { useTopNavDate } from '@/common/hooks/storage';
import { useMerchantReservation } from '@/common/hooks/use-reservations';
import {
  useGetAvailableTimeSlotInfiniteByDate,
  useSchedules,
} from '@/common/hooks/use-schedules';
import { ServiceTiming } from '@/common/types/schedule';
import { format } from 'date-fns';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import { storeAtom } from '../state';
import { useSelectedTime } from './use-current-time';

export default function useServiceTimingItem() {
  const [topNavDate] = useTopNavDate();
  const selectedTimeValue = useSelectedTime();
  const valueDate = format(topNavDate, 'yyyy-MM-dd');
  const [listServiceId, setListServiceId] = useState<string[]>([]);
  const [serviceTimingItem, setServiceTimingItem] =
    useState<ServiceTiming | null>(null);
  const {
    reassignTable: { reassigningReservation },
  } = useAtomValue(storeAtom);
  const ticketId = reassigningReservation?.ticket?.id;

  const { data: dataMerchantReservation } = useMerchantReservation({
    config: {
      enabled: true,
    },
  });

  const { data: dataServiceTimings, isFetching: isFetchingSchedules } =
    useSchedules({
      params: {
        outletId: dataMerchantReservation?.outlet?.id,
        date: valueDate,
      },
      config: {
        initialData: [],
        enabled: !!valueDate && !!dataMerchantReservation?.outlet?.id,
      },
    });

  const {
    getTimeSlotsAvailableQuery: { data: timeSlotPages },
  } = useGetAvailableTimeSlotInfiniteByDate({
    date: valueDate,
    enabled: !!valueDate,
  });

  useEffect(() => {
    if (!topNavDate) return;

    const date = format(topNavDate, 'yyyy-MM-dd');
    const time = format(selectedTimeValue, 'HH:mm:ss');
    const page = timeSlotPages?.pages.find((p) => p.date === date);

    if (page) {
      const timeSlot = page.timeSlots.find((slot) => slot.time === time);

      if (timeSlot) {
        setListServiceId(timeSlot.listServiceTimingIds);
      }
    }
  }, [timeSlotPages?.pages, topNavDate, selectedTimeValue]);

  useEffect(() => {
    if (!isFetchingSchedules && dataServiceTimings?.length && ticketId) {
      if (!listServiceId?.length) {
        setServiceTimingItem(null);
        return;
      }

      const listServiceMatchTimeSlot = dataServiceTimings?.filter(
        (service) =>
          listServiceId?.includes(service?.id) && service?.ticketId === ticketId
      );

      setServiceTimingItem(
        listServiceMatchTimeSlot?.length
          ? listServiceMatchTimeSlot?.[0] || null
          : null
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dataServiceTimings?.length,
    isFetchingSchedules,
    ticketId,
    listServiceId,
  ]);

  return serviceTimingItem;
}
