import { getUnixTimeMs } from '@/common/lib/date-time';
import { parse } from 'date-fns';
import { useCallback } from 'react';
import { useUpdateReservation } from './use-update-reservation';
import { selectedItemAtom } from '../state';
import { useSetAtom } from 'jotai';

export const useResize = () => {
  const { updateReservation, getReservationByTimelineItemId } =
    useUpdateReservation();
  const setSelectedItem = useSetAtom(selectedItemAtom);

  const resizeReservation = useCallback(
    (itemId: string, newEndTime: number) => {
      const currentReservation = getReservationByTimelineItemId(itemId);
      if (!currentReservation) {
        return;
      }
      const { reservationDate, reservationTime } = currentReservation;
      const newDuration =
        (newEndTime -
          getUnixTimeMs(
            parse(
              reservationDate + ' ' + reservationTime,
              'yyyy-MM-dd HH:mm:ss',
              new Date()
            )
          )) /
        1000;

      updateReservation(currentReservation, { diningInterval: newDuration });
      setSelectedItem(null);
    },
    [getReservationByTimelineItemId, setSelectedItem, updateReservation]
  );

  return { resizeReservation };
};
