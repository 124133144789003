import {
  Box,
  Chip,
  Menu,
  MenuItem,
  Stack,
  ToggleButton,
  Typography,
  useTheme,
} from '@mui/material';
import LocalActivityTwoToneIcon from '@mui/icons-material/LocalActivityTwoTone';
import { useState } from 'react';
import { activeTicketsAtom, filterAtom } from '../state';
import { useAtom, useAtomValue } from 'jotai';
import { Ticket } from '@/common/types/ticket';
import { TruncateLabel } from '@/common/components/truncate-label';
import CircleIcon from '@mui/icons-material/Circle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { Trans } from 'next-i18next';

export default function ReservationFilter() {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [{ reservationTicket }, setReservationFilter] = useAtom(filterAtom);
  const activeTickets = useAtomValue(activeTicketsAtom);

  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelectTicket = (ticket: Ticket) => {
    setReservationFilter((prev) => ({ ...prev, reservationTicket: ticket }));
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const theme = useTheme();

  return (
    <>
      <Stack flexDirection="row" gap={0.5} alignItems="center">
        <ToggleButton
          value=""
          sx={{ background: theme.palette.common.white }}
          size="small"
          onClick={handleClick}
        >
          {reservationTicket && (
            <Chip
              sx={{ maxHeight: '24px' }}
              icon={
                <CircleIcon
                  sx={{
                    color: reservationTicket.color,
                    fill: reservationTicket.color,
                    fontSize: 18,
                  }}
                />
              }
              label={TruncateLabel(reservationTicket.name, 24)}
              onDelete={() => {
                setReservationFilter((prev) => ({
                  ...prev,
                  reservationTicket: null,
                }));
              }}
            />
          )}

          <Stack direction="row" spacing={3}>
            <Stack direction="row">
              <Box px={0.5} display="flex" justifyItems="center">
                <LocalActivityTwoToneIcon fontSize="medium" />
              </Box>
              <Typography>
                <Trans>Ticket</Trans>
              </Typography>
            </Stack>
            <KeyboardArrowDownIcon fontSize="medium" />
          </Stack>
        </ToggleButton>
      </Stack>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {activeTickets.map((ticket) => (
          <MenuItem
            key={ticket.id}
            onClick={() => {
              handleSelectTicket(ticket);
            }}
          >
            {ticket.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
