import PersonTwoToneIcon from '@mui/icons-material/PersonTwoTone';
import RoomServiceTwoToneIcon from '@mui/icons-material/RoomServiceTwoTone';
import { ListViewProps, tabLabels } from './common';
import { css } from '@emotion/react';
import {
  Divider,
  InputAdornment,
  Stack,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import { MobileReservationItem } from '@/feat/reservation/components/mobile-reservation-item';
import { MobileTopNavSlot2 } from '@/common/layouts/left-side-bar/mobile-top-nav';
import { useTranslation } from 'next-i18next';
import { SearchSvg } from './search-svg';
import React, { createRef, useEffect, useMemo } from 'react';
import DateNavigation from '@/common/components/date-navigation';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material';
import { sortBy } from 'lodash-es';
import { IconButton } from '@mui/material';
import ClearTwoToneIcon from '@mui/icons-material/ClearTwoTone';
import { isShowSearchBar } from '../../state';
import { useAtomValue } from 'jotai';

export const MobileListView = ({
  dataTabs,
  tab,
  changeTab,
  tabData,
  inputSearch,
  setInputSearch,
  setIsSearchActive,
  isSearchActive,
}: ListViewProps) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const isShowSearch = useAtomValue(isShowSearchBar);
  const focusRef = createRef<HTMLInputElement>();

  const { numReservations, numPax } = useMemo(() => {
    const allData = dataTabs.find((item) => item.type === 'ALL');
    return {
      numReservations: allData?.numReservations || 0,
      numPax: allData?.numPax || 0,
    };
  }, [dataTabs]);

  const sortedReservations = useMemo(
    () => sortBy(tabData, [(it) => it.rDateTime]),
    [tabData]
  );

  useEffect(() => {
    setIsSearchActive(!!inputSearch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputSearch]);

  useEffect(() => {
    if (isShowSearch) {
      focusRef.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowSearch]);

  return (
    <>
      <MobileTopNavSlot2.Source>
        <Stack gap={2} flexDirection="column" flex="1" paddingTop="16px">
          {isShowSearch && (
            <TextField
              inputRef={focusRef}
              InputProps={{
                value: inputSearch,
                onChange: (e) => setInputSearch(e.target.value),
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchSvg />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    sx={{
                      display: isSearchActive ? undefined : 'none',
                      m: -1,
                    }}
                    position="end"
                  >
                    <IconButton
                      onClick={() => {
                        setIsSearchActive(false);
                        setInputSearch('');
                      }}
                    >
                      <ClearTwoToneIcon sx={{ cursor: 'pointer' }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              placeholder={t('Customer search')}
            />
          )}

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <DateNavigation sx={{ flex: 1, justifyContent: 'flex-start' }} />
            <Stack
              css={css`
                padding-left: 10px;
                .MuiSvgIcon-root {
                  font-size: 16px;
                  vertical-align: sub;
                  margin-right: 2px;
                }
                .MuiTypography-root {
                  color: ${palette.grey[500]};
                }
              `}
            >
              <Typography variant="caption">
                <RoomServiceTwoToneIcon />
                {numReservations}
              </Typography>
              <Typography variant="caption">
                <PersonTwoToneIcon />
                {numPax}
              </Typography>
            </Stack>
          </div>

          <div
            css={css`
              margin: 0 -16px;
            `}
          >
            <Tabs
              value={tab}
              onChange={(_, newVal) => changeTab(newVal)}
              variant="scrollable"
              allowScrollButtonsMobile
              css={css`
                position: relative;
                background: white;
                & > .MuiTabs-scrollButtons {
                  z-index: 1;
                  background: white;
                  border-radius: 0;
                  position: absolute;
                  top: 0;
                  width: 48px;
                  height: 48px;

                  &.Mui-disabled {
                    display: none;
                  }
                  &:first-of-type {
                    left: 0;
                  }
                  &:last-of-type {
                    right: 0;
                  }
                  & > .MuiSvgIcon-root {
                    width: 28px;
                    height: 28px;
                  }
                }
                .MuiTab-root.MuiTab-root {
                  margin-right: 0;
                  padding: 0 5px;
                }
                .MuiTab-root.Mui-selected {
                  color: ${palette.primary.main};
                }
              `}
            >
              {dataTabs.map(({ type, numReservations }) => (
                <Tab
                  key={type}
                  value={type}
                  label={
                    <span>
                      {tabLabels[type]}({numReservations})
                    </span>
                  }
                />
              ))}
            </Tabs>
            <Divider />
          </div>
        </Stack>
      </MobileTopNavSlot2.Source>
      <Stack
        gap={2}
        sx={{ display: { md: 'none' } }}
        css={css`
          padding-bottom: 16px;
          margin: 0 -4px;
        `}
      >
        {sortedReservations.map((reservation, i) => (
          <MobileReservationItem
            reservation={reservation}
            key={[tab, i].join('-')}
          />
        ))}
      </Stack>
    </>
  );
};
