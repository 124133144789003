import { ActionType } from '../action';

export interface CanvasListInteractionState {
  selectedTableIds: string[];
  seatingTableId: string | null;
  departingTableId: string | null;
}

const initialState: CanvasListInteractionState = {
  selectedTableIds: [],
  seatingTableId: null,
  departingTableId: null,
};

export const canvasListInteractionReducer = (
  state = initialState,
  action: ActionType
): CanvasListInteractionState => {
  switch (action.type) {
    case 'SEATING_TABLE': {
      return {
        ...state,
        seatingTableId: action.tableId,
      };
    }
    case 'DEPARTING_TABLE': {
      return {
        ...state,
        departingTableId: action.tableId,
      };
    }
    case 'CLEAR_SELECTED_TABLES': {
      return {
        ...state,
        selectedTableIds: [],
        seatingTableId: null,
      };
    }
    case 'SET_SELECTED_TABLE': {
      return {
        ...state,
        selectedTableIds: Array.isArray(action.tableId)
          ? action.tableId
          : [action.tableId],
      };
    }
    default: {
      return state;
    }
  }
};
