import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import {
  currentTimeAtom,
  reservationItemsAtom,
  selectedItemAtom,
} from '../state';
import {
  WALK_IN_BOUND,
  DEFAULT_WALK_IN_DURATION,
  DEFAULT_RESERVATION_DURATION,
} from '../constants';
import { getUnixTimeMs } from '@/common/lib/date-time';
import { PlaceholderType, SelectedItemType, TimelineItemType } from '../types';
import { nanoid } from 'nanoid';
import { reservationDrawerAtom } from '../../global-reservation-drawer';
import { useTopNavDate } from '@/common/hooks/storage';
import { format } from 'date-fns';
import { tableByIdAtom } from '../state';
import { fromUnixTime } from 'date-fns';
import useCallbackRef from '@/common/hooks/use-callback-ref';

const getStartTime = (timeInMs: number) => {
  const dateFromMs = fromUnixTime(timeInMs / 1000);
  const m = dateFromMs.getMinutes();
  let newMin = m;
  if (m >= 45) {
    newMin = 45;
  } else if (m >= 30) {
    newMin = 30;
  } else if (m >= 15) {
    newMin = 15;
  } else {
    newMin = 0;
  }
  dateFromMs.setMinutes(newMin);
  return getUnixTimeMs(dateFromMs);
};

export const usePlaceholder = () => {
  const setSelectedItem = useSetAtom(selectedItemAtom);
  const currentTime = useAtomValue(currentTimeAtom);
  const [selectedDate] = useTopNavDate();
  const tableById = useAtomValue(tableByIdAtom);
  const setReservationItems = useSetAtom(reservationItemsAtom);

  const addPlaceholder = useCallbackRef((tableId: string, time: number) => {
    if (!tableById[tableId]) {
      return;
    }
    const startTime = getStartTime(time);
    if (startTime < currentTime) {
      return;
    }
    const isWalkIn = startTime - currentTime < WALK_IN_BOUND;
    setSelectedItem({
      type: SelectedItemType.TIMELINE_ITEM,
      item: {
        type: TimelineItemType.PLACEHOLDER,
        id: nanoid(),
        group: tableId,
        span: 1,
        start_time: startTime,
        end_time:
          startTime +
          (isWalkIn ? DEFAULT_WALK_IN_DURATION : DEFAULT_RESERVATION_DURATION),
        tableId: tableId,
        placeholderType: isWalkIn
          ? PlaceholderType.WALK_IN
          : PlaceholderType.RESERVATION,
        canMove: false,
        canResize: false,
        canChangeGroup: false,
      },
    });
  });

  const setReservationDrawer = useSetAtom(reservationDrawerAtom);

  const addReservationWalkIn = useCallback(
    (type: PlaceholderType, tableId?: string, time?: number) => {
      const table = tableId ? tableById[tableId] : null;
      setReservationDrawer({
        mode: 'Add',
        reservationType:
          type === PlaceholderType.RESERVATION ? 'Reservation' : 'WalkIn',
        initialValue: {
          diningInterval:
            type === PlaceholderType.RESERVATION
              ? DEFAULT_RESERVATION_DURATION / 1000
              : DEFAULT_WALK_IN_DURATION / 1000,
          reservationDate: format(selectedDate, 'yyyy-MM-dd'),
          reservationTime: time
            ? format(fromUnixTime(time / 1000), 'HH:mm:ss')
            : undefined,
          tables: table
            ? [
                {
                  id: table.id,
                  name: table.name,
                  maxPax: table.maxPax,
                },
              ]
            : undefined,
        },
        onSuccess: (item) => {
          setReservationItems((prev) => prev.concat(item));
          setSelectedItem(null);
        },
      });
    },
    [
      tableById,
      setReservationDrawer,
      selectedDate,
      setReservationItems,
      setSelectedItem,
    ]
  );

  return { addPlaceholder, addReservationWalkIn };
};
