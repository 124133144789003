import { ReactNode } from 'react';
import {
  Tabs,
  Tab,
  Box,
  useTheme,
  TabsProps,
  SxProps,
  Theme,
} from '@mui/material';
import { alpha } from '@mui/material/styles';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
  sx?: SxProps<Theme>;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, sx, ...other } = props;

  return value === index ? (
    <Box
      role="tabpanel"
      aria-labelledby={`simple-tab-${index}`}
      sx={sx}
      {...other}
    >
      {children}
    </Box>
  ) : null;
}

export interface TabItem {
  iconLabel?: JSX.Element;
  label: string | ReactNode;
  render?: ReactNode;
  disabled?: boolean;
}

interface IProps {
  tabs: TabItem[];
  tab: number;
  onChange: (index: number) => void;
  TabsProps?: TabsProps;
  minimal?: boolean;
  containerSx?: SxProps<Theme>;
  tabPanelSx?: SxProps<Theme>;
}

const BasicTabs = (props: IProps) => {
  const { tabs, onChange, tab, TabsProps, minimal = false } = props;
  const theme = useTheme();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    onChange(newValue);
  };

  return (
    <Box sx={{ width: '100%', ...props.containerSx }}>
      <Tabs
        value={tab}
        onChange={handleChange}
        sx={{
          width: '100%',
          ...(minimal
            ? { mb: 3 }
            : {
                background: alpha(theme.palette.grey[500], 0.08),
                borderRadius: '8px 8px 0 0',
                mb: 4,
                '& .MuiButtonBase-root.MuiTabScrollButton-root.Mui-disabled': {
                  opacity: 0.3,
                },
              }),
        }}
        variant="scrollable"
        scrollButtons="auto"
        {...TabsProps}
      >
        {tabs.map(({ label, iconLabel, disabled }, i) => (
          <Tab
            sx={{
              whiteSpace: 'nowrap',
              textAlign: 'center',
              px: 1.5,
              margin: '0 !important',
              borderRadius: 0,
              '&.Mui-selected': {
                background: minimal
                  ? null
                  : alpha(theme.palette.primary.main, 0.08),
                color: theme.palette.primary.main,
              },
            }}
            key={i}
            icon={iconLabel}
            label={label}
            disabled={disabled}
          />
        ))}
      </Tabs>
      {tabs.map(({ render }, i) => (
        <TabPanel value={tab} index={i} key={i} sx={props.tabPanelSx}>
          {render}
        </TabPanel>
      ))}
    </Box>
  );
};

export default BasicTabs;
