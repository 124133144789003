import { ReservationItem } from '@/common/types/reservation';
import {
  addMinutes,
  differenceInMinutes,
  intervalToDuration,
  parse,
  subMinutes,
} from 'date-fns';
import { useCurrentTime, useSelectedTime } from './use-current-time';
import {
  getReservationSeatedDepartedTime,
  getReservationStartEndTime,
} from '@/feat/reservation/utils';

export const useUpcomingReservation = (reservations: ReservationItem[]) => {
  const selectedTime = useSelectedTime();
  return reservations.find((it) => {
    const reservationTime = parse(
      `${it.reservationDate} ${it.reservationTime}`,
      'yyyy-MM-dd HH:mm:ss',
      new Date()
    );
    return (
      (it.rStatus === 'R::BOOKED' || it.rStatus === 'R::CONFIRMED') &&
      reservationTime > selectedTime &&
      reservationTime <= addMinutes(selectedTime, 120)
    );
  });
};

export const useFutureReservations = (reservations: ReservationItem[]) => {
  const selectedTime = useSelectedTime();
  return reservations.filter((it) => {
    const reservationTime = parse(
      `${it.reservationDate} ${it.reservationTime}`,
      'yyyy-MM-dd HH:mm:ss',
      new Date()
    );
    return reservationTime > selectedTime;
  });
};

export const useLateReservation = (reservations: ReservationItem[]) => {
  const currentTime = useCurrentTime();
  return reservations.find((it) => {
    const reservationTime = parse(
      `${it.reservationDate} ${it.reservationTime}`,
      'yyyy-MM-dd HH:mm:ss',
      new Date()
    );
    return (
      (it.rStatus === 'R::BOOKED' || it.rStatus === 'R::CONFIRMED') &&
      reservationTime >= subMinutes(currentTime, 120) &&
      reservationTime <= currentTime
    );
  });
};

export const useSeatedReservation = (reservations: ReservationItem[]) => {
  const selectedTime = useSelectedTime();
  const item = reservations.find((it) => {
    const { seated, departed } = getReservationSeatedDepartedTime(it);
    return (
      it.rStatus === 'R::SEATED' &&
      // add 1 minute as a buffer to avoid 1 minute interval update of selectedTime
      (!seated || seated <= addMinutes(selectedTime, 1)) &&
      (!departed || departed >= subMinutes(selectedTime, 1))
    );
  });
  if (!item) {
    return null;
  }
  const { start, end } = getReservationStartEndTime(item);
  const { seated } = getReservationSeatedDepartedTime(item);
  const seatedDuration = intervalToDuration({
    end: selectedTime,
    start: seated || start,
  });

  const seatedDurationInMin = Math.abs(
    differenceInMinutes(seated || start, selectedTime)
  );

  const reservationDuration = Math.abs(
    differenceInMinutes(seated || start, end)
  );
  const seatedPctg = Math.round(
    (seatedDurationInMin / reservationDuration) * 100
  );

  const timeLeftDuration = intervalToDuration({
    start: selectedTime,
    end: end,
  });
  return { item, seatedPctg, seatedDuration, timeLeftDuration };
};

export const useClashingReservations = (reservations: ReservationItem[]) => {
  return reservations.filter((r1) => {
    // filter by status first
    if (
      r1.rStatus !== 'R::BOOKED' &&
      r1.rStatus !== 'R::CONFIRMED' &&
      r1.rStatus !== 'R::SEATED'
    ) {
      return false;
    }
    return reservations.some((r2) => {
      if (
        r1.id === r2.id ||
        (r2.rStatus !== 'R::BOOKED' &&
          r2.rStatus !== 'R::CONFIRMED' &&
          r2.rStatus !== 'R::SEATED')
      ) {
        return false;
      }
      const { start: r1Start, end: r1End } = getReservationStartEndTime(r1);
      const { start: r2Start, end: r2End } = getReservationStartEndTime(r2);
      return r1Start < r2End && r1End > r2Start;
    });
  });
};

export const useClashingNextReservation = (reservations: ReservationItem[]) => {
  const clashingReservations = useClashingReservations(reservations);
  const seatedReservation = useSeatedReservation(reservations);
  if (!clashingReservations.length || !seatedReservation) {
    return null;
  }
  const { start: rStart, end: rEnd } = getReservationStartEndTime(
    seatedReservation.item
  );
  return clashingReservations.find((it) => {
    const { start: itStart, end: itEnd } = getReservationStartEndTime(it);
    return itStart < rEnd && itEnd > rStart;
  });
};
