import SortTwoToneIcon from '@mui/icons-material/SortTwoTone';
import { Button, Menu, MenuItem, MenuList, Stack } from '@mui/material';
import { useAtom } from 'jotai';
import { useTranslation } from 'next-i18next';
import { useRef, useState } from 'react';
import { SIDEBAR_WIDTH } from '../constants';
import { timelineGroupSortingAtom } from '../state';
import { TimelineGroupSortingOption } from '../types';

const SortingSideBarHeader = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const { t } = useTranslation();
  const [timelineGroupSorting, setTimelineGroupSorting] = useAtom(
    timelineGroupSortingAtom
  );

  const sortOptions: { label: string; value: TimelineGroupSortingOption }[] = [
    {
      label: t('By Priority'),
      value: TimelineGroupSortingOption.PRIORITY,
    },
    {
      label: t('By Alphabetical'),
      value: TimelineGroupSortingOption.ALPHABETICAL,
    },
  ];

  const open = Boolean(anchorEl);

  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      sx={{ width: SIDEBAR_WIDTH }}
    >
      <Button
        onClick={(evt) => {
          setAnchorEl(evt.currentTarget);
        }}
        variant="text"
        aria-haspopup="true"
        ref={buttonRef}
      >
        <SortTwoToneIcon fontSize="medium" />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        <MenuList>
          {sortOptions.map((it, idx) => (
            <MenuItem
              key={idx}
              onClick={() => {
                setTimelineGroupSorting(it.value);
                setAnchorEl(null);
              }}
            >
              {it.label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Stack>
  );
};

export default SortingSideBarHeader;
