import { Stack } from '@mui/material';
import PaxFilter from './PaxFilter';
import SearchBarFilter from './SearchBarFilter';
import ReservationFilter from './ReservationFilter';
import ReservationStatusFilter from './ReservationStatusFilter';

export default function Filter() {
  return (
    <Stack
      alignItems="center"
      justifyContent="space-between"
      direction="row"
      flexWrap="wrap"
      gap={1}
    >
      <Stack
        gap={1}
        direction="row"
        flexWrap="wrap"
        sx={{
          maxWidth: '100%',
        }}
      >
        <SearchBarFilter />
        <PaxFilter />
        <ReservationFilter />
        <ReservationStatusFilter />
      </Stack>
    </Stack>
  );
}
