import { Area } from '@/common/types/area';
import { ReservationItem } from '@/common/types/reservation';
import { ActionType } from 'react-table';

export interface MasterDataState {
  areas: Area[];
  reservations: ReservationItem[];
  lastFetched: number | null;
  isLoading: boolean;
}

const initialState: MasterDataState = {
  areas: [],
  reservations: [],
  lastFetched: null,
  isLoading: false,
};

export const masterDataReducer = (
  state = initialState,
  action: ActionType
): MasterDataState => {
  switch (action.type) {
    case 'SET_DATA_LOADING': {
      return { ...state, isLoading: action.value };
    }
    case 'INIT_STATE_DATA': {
      return {
        ...state,
        ...action.data,
        lastFetched: Date.now(),
      };
    }
    default: {
      return state;
    }
  }
};
