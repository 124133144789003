import { PrimaryButton } from '@/common/components/buttons';
import BlockIcon from '@mui/icons-material/Block';
import LinkIcon from '@mui/icons-material/Link';
import PersonOutlineTwoTone from '@mui/icons-material/PersonOutlineTwoTone';
import { Stack, Typography, useTheme } from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { Trans } from 'next-i18next';
import { useSelection } from '../hooks/use-selection';
import { blockingTableIdAtom, selectedItemAtom, tableByIdAtom } from '../state';
import { SelectedItemType, TimelineGroup } from '../types';

export default function TableHeader({ group }: { group: TimelineGroup }) {
  const [selectedItem, setSelectedItemId] = useAtom(selectedItemAtom);
  const setBlockingTableId = useSetAtom(blockingTableIdAtom);
  const tableById = useAtomValue(tableByIdAtom);

  const theme = useTheme();
  const isSelected =
    selectedItem?.type === SelectedItemType.TABLE &&
    selectedItem.id === group.id;

  const { isMultiTablesReservationSelected, selectedReservationTableIds } =
    useSelection();

  const table = tableById[group.id];

  const handleToggleBlockTableClick = (
    evt: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    evt.stopPropagation();
    if (!table) {
      return;
    }
    setBlockingTableId(group.id);
  };

  const isMultiTableHighlighted =
    isMultiTablesReservationSelected &&
    selectedReservationTableIds.includes(group.id.toString());

  return (
    <Stack
      direction="column"
      sx={{
        width: '100%',
        height: '100%',
        paddingX: 0.5,
        cursor: 'pointer',
        userSelect: 'none',
        border: `1px solid ${
          isSelected ? theme.palette.primary.main : 'transparent'
        } !important`,
        borderRadius: 0.5,
        position: 'relative',
        justifyContent: 'center',
        background: isMultiTableHighlighted
          ? theme.palette.primary.main_12
          : undefined,
      }}
      gap={0.25}
      onClick={() => {
        setSelectedItemId(
          isSelected ? null : { type: SelectedItemType.TABLE, id: group.id }
        );
      }}
    >
      <Stack
        direction="row"
        gap={0.5}
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="body2"
          color={
            isMultiTableHighlighted ? theme.palette.primary.main : undefined
          }
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            lineClamp: 1,
            WebkitLineClamp: 1,
            WebkitBoxOrient: 'vertical',
            textAlign: 'center',
            verticalAlign: 'center',
          }}
        >
          {group.title}
        </Typography>
        {isMultiTableHighlighted ? (
          <LinkIcon fontSize="small" color="primary" />
        ) : null}
      </Stack>
      {group.minPax || group.maxPax ? (
        <Typography
          variant="caption2"
          color={
            isMultiTableHighlighted ? theme.palette.primary.main : undefined
          }
          sx={{
            color: theme.palette.text.disabled,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {group.minPax || 0} - {group.maxPax || 0}&nbsp;
          <PersonOutlineTwoTone />
        </Typography>
      ) : null}
      {isSelected && (
        <Stack
          sx={{
            position: 'absolute',
            left: '110%',
            top: '50%',
            zIndex: 100,
            transform: 'translate(0%, -50%)',
          }}
        >
          <PrimaryButton
            onClick={handleToggleBlockTableClick}
            startIcon={<BlockIcon />}
          >
            <Trans>Block table</Trans>
          </PrimaryButton>
        </Stack>
      )}
    </Stack>
  );
}
