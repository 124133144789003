import { isSameDay } from 'date-fns';
import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { selectedDateAtom, visibleTimeAtom } from '../state';

/**
 * Used for limiting gantt view scroll within a day
 */
export const useLimitGanttView = () => {
  const setVisibleTime = useSetAtom(visibleTimeAtom);
  const selectedDate = useAtomValue(selectedDateAtom);

  const handleVisibleTimeChange = useCallback(
    (start: number, end: number) => {
      if (isSameDay(start, selectedDate) && isSameDay(end, selectedDate)) {
        setVisibleTime({ start, end });
      }
    },
    [selectedDate, setVisibleTime]
  );

  return { handleVisibleTimeChange };
};
