import ReservationTimelineItem from '@/feat/gantt/reservation-timeline-item';
import { css } from '@emotion/react';
import PersonTwoToneIcon from '@mui/icons-material/PersonTwoTone';
import RoomServiceTwoToneIcon from '@mui/icons-material/RoomServiceTwoTone';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import Timeline, {
  DateHeader,
  DisableOverlay,
  ReactCalendarGroupRendererProps,
  ReactCalendarItemRendererProps,
  SidebarHeader,
  TimelineHeaders,
  TimelineMarkers,
  TodayMarker,
} from '@oddle.me/react-calendar-timeline';
import '@oddle.me/react-calendar-timeline/lib/Timeline.css';
import clsx from 'clsx';
import { format, fromUnixTime } from 'date-fns';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { Moment } from 'moment';
import { ReactNode, useCallback, useMemo } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { LINE_HEIGHT, SIDEBAR_WIDTH, Z_INDEX } from '../constants';
import { useBlocking } from '../hooks/use-blocking';
import { useCurrentTime } from '../hooks/use-current-time';
import { useAutoScroll, useDragging } from '../hooks/use-dragging';
import { useLimitGanttView } from '../hooks/use-limit-gantt-view';
import { useMultiTableCheckboxUpdate } from '../hooks/use-multi-table';
import { usePlaceholder } from '../hooks/use-placeholder';
import { useResize } from '../hooks/use-resize';
import { useTimeBlockChange } from '../hooks/use-time-block-change';
import { usePushReservationUpdate } from '../hooks/use-update-reservation';
import {
  collapsedAreaIdsAtom,
  currentTimeAtom,
  disabledOverlayItemsAtom,
  outerScrollElAtom,
  reservationItemByIdAtom,
  reservationStatByHourAtom,
  scrollTopAtom,
  selectedItemAtom,
  timelineItemsAtom,
  timelineItemsByIdAtom,
  visibleTimeAtom,
  visibleTimelineGroupAtom,
} from '../state';
import {
  SelectedItemType,
  TimelineGroup,
  TimelineItem,
  TimelineItemType,
} from '../types';
import AreaHeader from './AreaHeader';
import BlockAreaItems from './BlockAreaItem';
import BlockedTableItems from './BlockedTableItem';
import DisabledTableItems from './DisabledTableItem';
import DumpHeader from './DumpHeader';
import MultipleTableHighlightItems from './MultiTableHighlightItem';
import { PlaceholderItem } from './PlaceholderItem';
import SortingSideBarHeader from './SortingSideBarHeader';
import TableHeader from './TableHeader';
import TimelineExtraWrapper, {
  ChildrenParamsType,
} from './TimelineExtraWrapper';

export default function GanttViewTimeline() {
  const { width, ref } = useResizeDetector();
  const visibleGroups = useAtomValue(visibleTimelineGroupAtom);
  const visibleTime = useAtomValue(visibleTimeAtom);
  const reservationStatByHour = useAtomValue(reservationStatByHourAtom);
  const [collapsedAreaIds, setCollapsedAreaIds] = useAtom(collapsedAreaIdsAtom);
  const timelineItems = useAtomValue(timelineItemsAtom);
  const currentTime = useAtomValue(currentTimeAtom);
  const reservationItemById = useAtomValue(reservationItemByIdAtom);
  const [selectedItem, setSelectedItem] = useAtom(selectedItemAtom);
  const timelineItemsById = useAtomValue(timelineItemsByIdAtom);
  const [scrollTop, setScrollTop] = useAtom(scrollTopAtom);
  const disabledOverlayItems = useAtomValue(disabledOverlayItemsAtom);
  const setScrollEl = useSetAtom(outerScrollElAtom);
  // more behaviors here
  const { addPlaceholder } = usePlaceholder();
  const { handleVisibleTimeChange } = useLimitGanttView();
  const { resizeReservation } = useResize();
  const { moveReservation, handleItemDragging } = useDragging();
  const { setBlockingAreaId } = useBlocking();

  const theme = useTheme();

  const timelineGroup = useMemo(
    () =>
      visibleGroups.map((g) => ({
        ...g,
        title:
          g.type === 'table' ? (
            g.title
          ) : g.type === 'dump-table' ? (
            <DumpHeader width={width || 0} height={LINE_HEIGHT} />
          ) : (
            <AreaHeader
              width={width || 0}
              title={g.title}
              height={LINE_HEIGHT}
              isCollapsed={collapsedAreaIds[g.id] || false}
              onToggleCollapse={(value) => {
                setCollapsedAreaIds((prev) => ({ ...prev, [g.id]: value }));
              }}
              onBlock={() => {
                setBlockingAreaId(g.id);
              }}
            />
          ),
      })),
    [
      visibleGroups,
      width,
      collapsedAreaIds,
      setCollapsedAreaIds,
      setBlockingAreaId,
    ]
  );

  const itemRenderer = useCallback(
    ({
      item,
      itemContext: ctx,
      getItemProps,
      getResizeProps,
    }: ReactCalendarItemRendererProps<TimelineItem>) => {
      const itemProps = getItemProps(item.itemProps || {});
      const { right } = getResizeProps();
      let render: ReactNode = null;
      if (item.type === TimelineItemType.RESERVATION) {
        const reservationItem = reservationItemById[item.reservationId];
        if (reservationItem) {
          render = (
            <ReservationTimelineItem
              reservation={reservationItem}
              resizeRef={right?.ref}
              resizeClassName={right?.className}
              timelineItem={item}
            />
          );
        }
      }
      if (item.type === TimelineItemType.PLACEHOLDER) {
        render = <PlaceholderItem type={item.placeholderType} item={item} />;
      }
      if (!render) {
        return null;
      }
      return (
        <Box
          {...itemProps}
          style={{
            ...itemProps.style,
            height: LINE_HEIGHT * item.span,
            background: 'none',
            backgroundColor: 'none',
            border: 'none',
            padding: 0,
          }}
          className={clsx(
            itemProps.className,
            ctx.selected && 'rct-item-selected',
            ctx.dragging && 'rct-item-dragging'
          )}
        >
          {render}
        </Box>
      );
    },
    [reservationItemById]
  );

  const groupRenderer = useCallback(
    ({ group }: ReactCalendarGroupRendererProps<TimelineGroup>) => {
      if (group.type !== 'table') {
        return group.title;
      }
      return <TableHeader group={group} />;
    },
    []
  );

  const handleCanvasClick = useCallback(
    (tableId: string, time: number) => {
      addPlaceholder(tableId, time);
    },
    [addPlaceholder]
  );

  const handleItemSelect = useCallback(
    (id: string) => {
      const item = timelineItemsById[id];
      if (item && item.type === TimelineItemType.RESERVATION) {
        setSelectedItem({ type: SelectedItemType.TIMELINE_ITEM, item });
      }
    },
    [setSelectedItem, timelineItemsById]
  );

  const handleItemDeselect = useCallback(() => {
    setSelectedItem(null);
  }, [setSelectedItem]);

  const handleItemResize = useCallback(
    (itemId: string, endTime: number) => {
      resizeReservation(itemId, endTime);
    },
    [resizeReservation]
  );

  const handleItemMove = useCallback(
    (itemId: string, dragTime: number, newGroupOrder: number) => {
      moveReservation(itemId, dragTime, newGroupOrder);
    },
    [moveReservation]
  );

  const handleRenderExtraTimelineItem = useCallback(
    ({ timelineChildrenProps }: ChildrenParamsType) => {
      return (
        <>
          <DisabledTableItems
            groupHeights={timelineChildrenProps.groupHeights}
            groupTops={timelineChildrenProps.groupTops}
            canvasTimeStart={timelineChildrenProps.canvasTimeStart}
            canvasTimeEnd={timelineChildrenProps.canvasTimeEnd}
            canvasWidth={timelineChildrenProps.canvasWidth}
          />
          <BlockedTableItems
            groupHeights={timelineChildrenProps.groupHeights}
            groupTops={timelineChildrenProps.groupTops}
            canvasTimeStart={timelineChildrenProps.canvasTimeStart}
            canvasTimeEnd={timelineChildrenProps.canvasTimeEnd}
            canvasWidth={timelineChildrenProps.canvasWidth}
          />
          <BlockAreaItems
            groupHeights={timelineChildrenProps.groupHeights}
            groupTops={timelineChildrenProps.groupTops}
            canvasTimeStart={timelineChildrenProps.canvasTimeStart}
            canvasTimeEnd={timelineChildrenProps.canvasTimeEnd}
            canvasWidth={timelineChildrenProps.canvasWidth}
          />
          <MultipleTableHighlightItems
            groupHeights={timelineChildrenProps.groupHeights}
            groupTops={timelineChildrenProps.groupTops}
            canvasTimeStart={timelineChildrenProps.canvasTimeStart}
            canvasTimeEnd={timelineChildrenProps.canvasTimeEnd}
            canvasWidth={timelineChildrenProps.canvasWidth}
          />
        </>
      );
    },
    []
  );

  // Effects
  useCurrentTime();
  useMultiTableCheckboxUpdate();
  usePushReservationUpdate();
  useAutoScroll();
  useTimeBlockChange();

  if (!visibleGroups || !visibleGroups.length) {
    return null;
  }

  return (
    <>
      <Box
        sx={{ flex: 1, height: 0 }}
        ref={ref}
        css={css`
          .rct-item {
            cursor: pointer !important;
          }
          .rct-header-time-marker {
            background-color: #277c09;
            color: white;
          }
          .rct-item-handler-resize-left,
          .rct-item-handler-resize-right {
            display: none;
            cursor: ew-resize !important;
          }
          .rct-item-selected .rct-item-handler-resize-left,
          .rct-item-selected .rct-item-handler-resize-right {
            display: block;
          }
          .rct-vl {
            background: repeating-linear-gradient(
              to right,
              rgba(145, 158, 171, 0.24) 0px 1px,
              transparent 1px 25%
            );
          }
          .react-calendar-timeline {
            height: 100%;
            border-radius: 16px;
          }
          .react-calendar-timeline .rct-sidebar .rct-sidebar-row {
            overflow: visible;
            padding: 0;
          }
          .react-calendar-timeline .rct-header-root {
            background-color: #fff;
            > div {
              border-top: none;
              border-right: none;
              border-bottom: none;
              > div > div {
                border: none;
                border-left: 1px solid #bbb;
              }
            }
          }
          .react-calendar-timeline .rct-horizontal-lines .rct-hl-even {
            background-color: #fff;
          }
          .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
            background-color: #f4f6f8;
          }
          .react-calendar-timeline .rct-dateHeader.rct-dateHeader-marker {
            align-items: flex-start;
            justify-content: flex-start;
            background-color: white;
          }
          .disabled-table-item-wrapper {
            z-index: ${Z_INDEX.timelinePointer} !important;
          }
        `}
      >
        <Timeline<TimelineItem, TimelineGroup>
          groups={timelineGroup}
          items={timelineItems}
          useResizeHandle
          visibleTimeStart={visibleTime.start}
          visibleTimeEnd={visibleTime.end}
          onTimeChange={handleVisibleTimeChange}
          itemRenderer={itemRenderer}
          groupRenderer={groupRenderer}
          lineHeight={LINE_HEIGHT}
          sidebarWidth={SIDEBAR_WIDTH}
          selected={
            selectedItem?.type === SelectedItemType.TIMELINE_ITEM
              ? [selectedItem.item.id]
              : []
          }
          itemHeightRatio={1}
          onCanvasClick={handleCanvasClick}
          onItemSelect={handleItemSelect}
          onItemDeselect={handleItemDeselect}
          onItemResize={handleItemResize}
          onItemMove={handleItemMove}
          onItemDrag={handleItemDragging}
          onVerticalScroll={setScrollTop}
          initialScrollTop={scrollTop}
          outerScrollRef={(el) => {
            setScrollEl(el);
          }}
          stackItems
        >
          <TimelineHeaders>
            <SidebarHeader>{SortingSideBarHeader}</SidebarHeader>
            <DateHeader
              height={52}
              markerTime={{
                time: fromUnixTime(currentTime / 1000),
                renderer: (_, style) => {
                  return (
                    <Box
                      style={style}
                      sx={{
                        position: 'absolute',
                        borderRadius: 5,
                        paddingX: 1,
                        background: theme.palette.success.main,
                        color: theme.palette.common.white,
                        transform: 'translate(-50%)',
                        bottom: 0,
                      }}
                    >
                      <Typography variant="caption">
                        {format(currentTime, 'hh:mm aa')}
                      </Typography>
                    </Box>
                  );
                },
              }}
              unit="hour"
              intervalRenderer={(ctx) => {
                if (!ctx) return null;
                const intervalProps = ctx.getIntervalProps();
                const startTime = ctx.intervalContext.interval
                  .startTime as unknown as Moment;
                const hour = startTime.hour();
                const statByHour = reservationStatByHour[hour];
                return (
                  <Stack
                    className={clsx('rct-dateHeader', 'rct-dateHeader-marker')}
                    {...intervalProps}
                    direction="row"
                    pt={1}
                    gap={1}
                    px={1}
                  >
                    <Typography variant="subtitle1" sx={{ paddingLeft: 0.5 }}>
                      {startTime.format('hh:mm A')}
                    </Typography>
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap={0.25}
                      sx={{ color: 'grey.500' }}
                      className="div-center"
                      pt={0.25}
                    >
                      <RoomServiceTwoToneIcon />
                      <Typography variant="caption" fontSize={12}>
                        {statByHour ? statByHour.reservationCount : 0}
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap={0.25}
                      sx={{ color: 'grey.500' }}
                      className="div-center"
                      pt={0.25}
                    >
                      <PersonTwoToneIcon />
                      <Typography variant="caption" fontSize={12}>
                        {statByHour ? statByHour.paxCount : 0}
                      </Typography>
                    </Stack>
                  </Stack>
                );
              }}
            />
          </TimelineHeaders>
          <TimelineMarkers>
            <TodayMarker date={currentTime}>
              {({ styles }) => {
                return (
                  <Box
                    style={styles}
                    sx={{
                      backgroundColor:
                        theme.palette.success.main + ' !important',
                      zIndex: Z_INDEX.timelineMarker,
                      width: 2,
                    }}
                  />
                );
              }}
            </TodayMarker>
          </TimelineMarkers>
          {disabledOverlayItems.length > 0 ? (
            disabledOverlayItems.map((item, idx) => (
              <DisableOverlay
                key={idx}
                fromTime={item.startTime}
                toTime={item.endTime}
              />
            ))
          ) : (
            <DisableOverlay toTime={currentTime} />
          )}
          <TimelineExtraWrapper>
            {handleRenderExtraTimelineItem}
          </TimelineExtraWrapper>
        </Timeline>
      </Box>
    </>
  );
}
