import { Box, useTheme } from '@mui/material';
import { Z_INDEX } from '../constants';

interface DumpHeader {
  width: number;
  height: number;
}

const DumpHeader = ({ width, height }: DumpHeader) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: width,
        height: height,
        position: 'relative',
        zIndex: Z_INDEX.group,
        background: theme.palette.grey[300],
        pointerEvents: 'none',
      }}
    />
  );
};

export default DumpHeader;
