import { ActionType } from '../action';

export interface GroundControlState {
  timeline: 'current' | 'upcoming' | 'all-day';
  seatedStatus: 'time-left' | 'duration';
  tableInfo: {
    upcomingReminder: boolean;
    upcomingName: boolean;
    seatedPax: boolean;
  };
  maxZoomLevel: number;
  minZoomLevel: number;
}

const initialState: GroundControlState = {
  timeline: 'current',
  seatedStatus: 'time-left',
  tableInfo: {
    upcomingReminder: true,
    upcomingName: true,
    seatedPax: true,
  },
  minZoomLevel: 0.01,
  maxZoomLevel: 8,
};

export const groundControlReducer = (
  state: GroundControlState = initialState,
  action: ActionType
): GroundControlState => {
  switch (action.type) {
    case 'UPDATE_GROUND_CONTROL_VIEW_MODE': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'UPDATE_FIT_SCREEN_SCALE': {
      console.log(action);
      return {
        ...state,
        minZoomLevel: action.value,
      };
    }
    default: {
      return state;
    }
  }
};
