import { SecondaryButton } from '@/common/components/buttons';
import {
  AutoRsrvStatusBtn,
  RESERVATION_STATUS_COLORS,
  RESERVATION_STATUS_LABELS,
} from '@/common/components/reservation-status-btn';
import RestaurantSourceIcon from '@/common/components/restaurant-src-icon';
import useChangeReservationStatus from '@/common/hooks/use-change-reservation-status';
import { useCustomerFirstName } from '@/common/hooks/use-customers';
import { getFormattedPhone } from '@/common/lib/phone-number';
import { ReservationItem } from '@/common/types/reservation';
import { TICKET_TYPE_PAYMENT } from '@/common/types/ticket.base';
import { assertUnreachable, getFallbackUserAvatar } from '@/common/utils';
import { reservationDrawerAtom } from '@/feat/reservation/components/global-reservation-drawer';
import { NoteSvg } from '@/feat/reservation/components/list-view/note-svg';
import {
  occasionLabels,
  reservationSegmentsAtom,
} from '@/feat/reservation/state';
import { css } from '@emotion/react';
import AirlineSeatReclineNormalTwoToneIcon from '@mui/icons-material/AirlineSeatReclineNormalTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';
import TableBarTwoToneIcon from '@mui/icons-material/TableBarTwoTone';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  ButtonBase,
  Chip,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { format } from 'date-fns';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { Trans, useTranslation } from 'next-i18next';
import React, { useCallback, useMemo } from 'react';
import { selectedReservationAtom, storeAtom } from '../floor-plan-view/state';
import CustomerSegmentTags from '@/common/components/reserve-intelligence/customer-segment-tags';
import { getTimeDifference } from '@/feat/reservation/utils';

type ChipItem = { label: string; tooltip?: string };
const Chips = ({ items }: { items: ChipItem[] }) => {
  return (
    <Stack
      gap={0.5}
      flexDirection="row"
      flexWrap="wrap"
      onClick={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.stopPropagation()}
      onTouchStart={(e) => e.stopPropagation()}
      sx={{ display: 'flex' }}
    >
      {items.map((item, i) => {
        const chip = <OccasionItem label={item.label} />;
        if (item.tooltip) {
          return (
            <Tooltip
              key={i}
              enterTouchDelay={0}
              leaveTouchDelay={30000}
              title={item.tooltip}
            >
              <span>{chip}</span>
            </Tooltip>
          );
        }
        return <React.Fragment key={i}>{chip}</React.Fragment>;
      })}
    </Stack>
  );
};

const OccasionItem = ({ label }: { label: string }) => {
  const theme = useTheme();

  return (
    <Chip
      variant="outlined"
      label={occasionLabels(label)}
      css={css`
        height: 22px;
        background: white;
        border-radius: 6px;
        font-weight: 700;
        font-size: 12px;
        color: ${theme.palette.grey[600]};
        .MuiChip-label {
          padding: 0 8px;
        }
      `}
    />
  );
};

interface MobileReservationItemProps {
  reservation: ReservationItem;
  disableClick?: boolean;
}

export function MobileReservationItem({
  reservation,
  disableClick,
}: MobileReservationItemProps) {
  const { t } = useTranslation();
  const customerFirstName = useCustomerFirstName();
  const customerName = customerFirstName(reservation.user);
  const theme = useTheme();
  const colors = RESERVATION_STATUS_COLORS(theme.palette)[reservation.rStatus];
  const typePayment = reservation.ticket?.typePayment;
  const setReservationDrawer = useSetAtom(reservationDrawerAtom);
  const listSegmentReservation = useAtomValue(reservationSegmentsAtom);

  const dataCustomerCDP = listSegmentReservation.find(
    (item) => item.id === reservation?.user?.id
  );

  const paymentTypeLabel = useMemo(() => {
    switch (typePayment) {
      case null:
      case undefined:
        return '';
      case TICKET_TYPE_PAYMENT['No Payment']:
        return t('No Payment');
      case TICKET_TYPE_PAYMENT.Deposit:
        return t('Deposit');
      case TICKET_TYPE_PAYMENT.Prepaid:
        return t('Prepaid');
      case TICKET_TYPE_PAYMENT.CardGuarantee:
        return t('Card Guarantee');
      default:
        assertUnreachable(typePayment);
        return '';
    }
  }, [t, typePayment]);

  const chipItems: ChipItem[] = useMemo(() => {
    const othersChip: ChipItem | null = reservation.otherOccasions
      ? { label: t('Others'), tooltip: reservation.otherOccasions }
      : null;
    const occasionChips: ChipItem[] = (reservation.occasions || '')
      .split(',')
      .map((it) => it.trim())
      .filter(Boolean)
      .map((label) => ({ label }));
    const paymentTypeChip: ChipItem | null = paymentTypeLabel
      ? { label: paymentTypeLabel }
      : null;
    return [...occasionChips, othersChip, paymentTypeChip].filter(Boolean);
  }, [paymentTypeLabel, reservation.occasions, reservation.otherOccasions, t]);

  const handleClickItem = () => {
    if (disableClick) return;
    setReservationDrawer({ mode: 'View', reservationId: reservation.id });
  };

  return (
    <Stack gap={1} flexDirection="row">
      <Stack
        onClick={(event) => event.stopPropagation()}
        sx={{
          '& > .MuiBox-root': {
            height: '100%',
          },
        }}
      >
        <AutoRsrvStatusBtn
          btnVariant="VERTICAL"
          reservationItem={reservation}
          css={css`
            height: 100%;
          `}
        />
      </Stack>
      <Stack
        sx={{
          background: theme.palette.grey[500_8],
          flex: 1,
          borderRadius: theme.spacing(1),
          padding: theme.spacing(1),
          minWidth: 0,
          '& .MuiListItem-root': {
            padding: 0,
            '& .MuiTypography-root': {
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            },
          },
          '& .MuiListItemAvatar-root': {
            marginRight: theme.spacing(1),
          },
          '& .MuiListItemIcon-root': {
            marginRight: theme.spacing(0.5),
          },
        }}
        gap={1}
        component={ButtonBase}
        justifyContent="normal"
        alignItems="normal"
        onClick={handleClickItem}
      >
        <Stack gap={1} flexDirection="row">
          <Typography
            css={css`
              font-weight: 600;
              font-size: 20px;
              color: ${theme.palette.grey[800]};
            `}
          >
            {format(reservation.rDateTime, 'h:mm aa')}
          </Typography>
          <div style={{ flex: 1 }} />
          <Typography
            variant="subtitle2"
            css={css`
              align-self: flex-end;
              color: ${colors.bg};
            `}
          >
            {RESERVATION_STATUS_LABELS[reservation.rStatus]}
          </Typography>
        </Stack>
        <ListItem>
          <ListItemAvatar
            css={css`
              position: relative;
            `}
          >
            <Avatar
              src={
                reservation.user?.photoUrl ||
                getFallbackUserAvatar(reservation.user?.salutation)
              }
            />
            <div
              css={css`
                position: absolute;
                bottom: -3px;
                right: -3px;
              `}
            >
              <RestaurantSourceIcon source={reservation.source} />
            </div>
          </ListItemAvatar>
          <ListItemText
            primary={customerName}
            secondary={
              <Stack>
                {dataCustomerCDP?.reservationLastVisitedDate && (
                  <Typography variant="body2" sx={{ color: 'grey.600' }}>
                    <Trans>
                      Last visited{' '}
                      {{
                        timeDifference: getTimeDifference(
                          dataCustomerCDP?.reservationLastVisitedDate
                        ),
                      }}{' '}
                      ago
                    </Trans>
                  </Typography>
                )}
                <Typography variant="body2">
                  {getFormattedPhone(reservation.user?.phone)}
                </Typography>
              </Stack>
            }
          />
          <Stack gap={0.75} direction="column" alignItems="flex-end">
            <Typography variant="subtitle1">
              <Trans>{{ pax: reservation.pax }} Pax</Trans>
            </Typography>

            <Stack
              sx={{
                background: theme.palette.grey[300],
                borderRadius: 1,
                p: theme.spacing(0.75, 1),
                width: 60,
              }}
            >
              {Array.isArray(reservation?.tables) &&
                reservation?.tables?.length &&
                reservation?.tables.map((table, index) => {
                  return (
                    <Stack
                      key={table?.id}
                      sx={{
                        p: theme.spacing(0.25, 0),
                        ...(!!reservation?.tables?.length &&
                          index !== reservation?.tables?.length - 1 && {
                            borderBottom: `1px solid ${theme.palette.grey[500_24]}`,
                          }),
                      }}
                    >
                      <Typography
                        variant="caption"
                        sx={{
                          margin: 'auto',
                          lineHeight: 1.5,
                          maxWidth: '100%',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {table?.name}
                      </Typography>
                    </Stack>
                  );
                })}
            </Stack>
          </Stack>
        </ListItem>

        <CustomerSegmentTags
          segments={dataCustomerCDP?.segments ?? []}
          type={'RESERVE'}
          sx={{
            flexWrap: 'wrap',
            '& .MuiChip-root': {
              height: 22,
            },
          }}
        >
          {!!chipItems.length ? <Chips items={chipItems} /> : null}
        </CustomerSegmentTags>

        {reservation.notes && (
          <ListItem>
            <ListItemIcon>
              <NoteSvg />
            </ListItemIcon>
            <Typography
              css={css`
                color: ${theme.palette.grey[600]};
                font-weight: 500;
                font-size: 14px;
              `}
            >
              “{reservation.notes}”
            </Typography>
          </ListItem>
        )}
        {reservation.staffNotes && (
          <ListItem>
            <ListItemIcon>
              <InfoTwoToneIcon />
            </ListItemIcon>
            <Typography
              css={css`
                color: ${theme.palette.grey[600]};
                font-weight: 600;
                font-size: 14px;
              `}
            >
              <Trans>
                Staff Notes: {{ staffNotes: reservation.staffNotes }}
              </Trans>
            </Typography>
          </ListItem>
        )}
      </Stack>
    </Stack>
  );
}

export function MobileReservationItemForFloorPlan({
  reservation,
}: MobileReservationItemProps) {
  const theme = useTheme();

  const [
    {
      canvasListInteraction: { selectedTableIds },
    },
    dispatch,
  ] = useAtom(storeAtom);

  const selectedReservation = useAtomValue(selectedReservationAtom);
  const setReservationDrawer = useSetAtom(reservationDrawerAtom);
  const isSelectedReservation =
    !!selectedReservation && selectedReservation.id === reservation.id;
  const changeReservationStatusMutation = useChangeReservationStatus();

  const isNonActionButton =
    !isSelectedReservation &&
    ['R::BOOKED', 'R::CONFIRMED', 'R::SEATED'].includes(reservation.rStatus);

  const handleToggleSelectedReservation = useCallback(() => {
    if (!isSelectedReservation && !isNonActionButton) {
      setReservationDrawer({ mode: 'View', reservationId: reservation.id });
    } else {
      if (reservation?.rStatus === 'R::SEATED' && !isSelectedReservation) {
        dispatch({
          type: 'SET_SELECTED_TABLE',
          tableId: reservation?.tables?.map((it) => it?.id),
        });
      }
      if (isSelectedReservation) {
        dispatch({
          type: 'CLEAR_SELECTED_TABLES',
        });
      }

      dispatch({
        type: 'TOGGLE_SELECTED_RESERVATION',
        reservationId: reservation.id,
      });
    }
  }, [
    dispatch,
    isNonActionButton,
    isSelectedReservation,
    reservation.id,
    reservation?.tables,
    reservation?.rStatus,
    setReservationDrawer,
  ]);

  const handleEditReservation = useCallback(() => {
    setReservationDrawer({
      mode: 'Edit',
      reservationId: reservation.id,
    });
  }, [reservation.id, setReservationDrawer]);

  const handleViewReservation = useCallback(() => {
    setReservationDrawer({
      mode: 'View',
      reservationId: reservation.id,
    });
  }, [reservation.id, setReservationDrawer]);

  const handleSeatCustomer = useCallback(() => {
    changeReservationStatusMutation
      .mutateAsync({
        newStatus: 'R::SEATED',
        reservation,
      })
      .then(() => {
        dispatch({
          type: 'SET_SELECTED_TABLE',
          tableId: reservation?.tables?.map((it) => it?.id),
        });
      });
  }, [changeReservationStatusMutation, dispatch, reservation]);

  const handleDepartCustomer = useCallback(() => {
    changeReservationStatusMutation
      .mutateAsync({
        newStatus: 'R::COMPLETED',
        reservation,
      })
      .then(() => {
        dispatch({ type: 'CLEAR_SELECTED_TABLES' });
      });
  }, [changeReservationStatusMutation, dispatch, reservation]);

  const handleReassignReservation = useCallback(() => {
    dispatch({
      type: 'INITIAL_REASSIGNING_RESERVATION',
      reassigningReservation: reservation,
    });
    dispatch({
      type: 'MINIMIZE_RESERVATION_PANEL',
      isReservationPanelMinimized: true,
    });
  }, [dispatch, reservation]);

  const handleCancelSeatedCustomer = useCallback(() => {
    dispatch({
      type: 'TOGGLE_SELECTED_RESERVATION',
      reservationId: reservation.id,
    });
    dispatch({ type: 'CLEAR_SELECTED_TABLES' });
  }, [dispatch, reservation]);

  const actionElm = useMemo(() => {
    if (!isSelectedReservation) {
      return null;
    }
    if (['R::BOOKED', 'R::CONFIRMED'].includes(reservation.rStatus)) {
      if (selectedTableIds.length) {
        return (
          <>
            <SecondaryButton
              sx={{ height: '48px', width: '100%', backgroundColor: '#FFF' }}
              onClick={handleCancelSeatedCustomer}
            >
              <Trans>Cancel</Trans>
            </SecondaryButton>
            <LoadingButton
              sx={{ height: '48px', width: '100%' }}
              onClick={handleSeatCustomer}
              startIcon={<AirlineSeatReclineNormalTwoToneIcon />}
              color="primary"
              variant="contained"
              loading={changeReservationStatusMutation.isLoading}
            >
              <Trans>Seat Customer</Trans>
            </LoadingButton>
          </>
        );
      } else {
        return (
          <>
            <LoadingButton
              sx={{ height: '48px', width: '100%' }}
              onClick={handleSeatCustomer}
              startIcon={<AirlineSeatReclineNormalTwoToneIcon />}
              color="primary"
              variant="contained"
              loading={changeReservationStatusMutation.isLoading}
            >
              <Trans>Seat</Trans>
            </LoadingButton>
            <SecondaryButton
              sx={{ height: '48px', width: '100%', backgroundColor: '#FFF' }}
              onClick={handleReassignReservation}
              startIcon={<TableBarTwoToneIcon />}
            >
              <Trans>Reassign</Trans>
            </SecondaryButton>
            <IconButton onClick={handleEditReservation}>
              <EditTwoToneIcon sx={{ color: theme.palette.primary.main }} />
            </IconButton>
            <IconButton onClick={handleViewReservation}>
              <VisibilityTwoToneIcon
                sx={{ color: theme.palette.primary.main }}
              />
            </IconButton>
          </>
        );
      }
    } else if (reservation.rStatus === 'R::SEATED') {
      if (selectedTableIds.length) {
        return (
          <>
            <LoadingButton
              sx={{ height: '48px', width: '100%' }}
              onClick={handleDepartCustomer}
              startIcon={<LogoutTwoToneIcon />}
              color="error"
              variant="contained"
              loading={changeReservationStatusMutation.isLoading}
            >
              <Trans>Depart</Trans>
            </LoadingButton>
            <SecondaryButton
              sx={{ height: '48px', width: '100%', backgroundColor: '#FFF' }}
              onClick={handleReassignReservation}
              startIcon={<TableBarTwoToneIcon />}
            >
              <Trans>Reassign</Trans>
            </SecondaryButton>
            <IconButton onClick={handleEditReservation}>
              <EditTwoToneIcon sx={{ color: theme.palette.primary.main }} />
            </IconButton>
            <IconButton onClick={handleViewReservation}>
              <VisibilityTwoToneIcon
                sx={{ color: theme.palette.primary.main }}
              />
            </IconButton>
          </>
        );
      } else {
        return (
          <>
            <LoadingButton
              sx={{ height: '48px', width: '100%' }}
              onClick={handleDepartCustomer}
              startIcon={<LogoutTwoToneIcon />}
              color="error"
              variant="contained"
              loading={changeReservationStatusMutation.isLoading}
            >
              <Trans>Depart</Trans>
            </LoadingButton>
            <SecondaryButton
              sx={{ height: '48px', width: '100%', backgroundColor: '#FFF' }}
              onClick={handleReassignReservation}
              startIcon={<TableBarTwoToneIcon />}
            >
              <Trans>Reassign</Trans>
            </SecondaryButton>
            <IconButton onClick={handleEditReservation}>
              <EditTwoToneIcon sx={{ color: theme.palette.primary.main }} />
            </IconButton>
            <IconButton onClick={handleViewReservation}>
              <VisibilityTwoToneIcon
                sx={{ color: theme.palette.primary.main }}
              />
            </IconButton>
          </>
        );
      }
    } else {
      return null;
    }
  }, [
    changeReservationStatusMutation.isLoading,
    handleCancelSeatedCustomer,
    handleDepartCustomer,
    handleEditReservation,
    handleReassignReservation,
    handleSeatCustomer,
    handleViewReservation,
    isSelectedReservation,
    reservation.rStatus,
    selectedTableIds.length,
    theme.palette.primary.main,
  ]);

  return (
    <Stack
      flexDirection="column"
      sx={[
        {
          backgroundColor: 'transparent',
          borderRadius: '8px',
        },
        isSelectedReservation
          ? {
              backgroundColor: theme.palette.primary.main_12,
            }
          : null,
      ]}
      onClick={handleToggleSelectedReservation}
    >
      <MobileReservationItem disableClick reservation={reservation} />
      {actionElm ? (
        <Stack
          direction="row"
          gap={0.5}
          sx={{
            my: '12px',
            mx: '6px',
          }}
          onClick={(evt) => {
            evt.stopPropagation();
          }}
        >
          {actionElm}
        </Stack>
      ) : null}
    </Stack>
  );
}
