import { useSetAtom } from 'jotai';
import { reservationDrawerAtom } from '../../global-reservation-drawer';
import { useCallback } from 'react';
import { ReservationItem } from '@/common/types/reservation';
import { reservationItemsAtom } from '../state';

export const useReservationItemAction = () => {
  const setReservationDrawer = useSetAtom(reservationDrawerAtom);
  const setReservationItems = useSetAtom(reservationItemsAtom);

  const openViewReservationDrawer = useCallback(
    (reservationItem: ReservationItem) => {
      setReservationDrawer({
        mode: 'View',
        reservationId: reservationItem.id,
      });
    },
    [setReservationDrawer]
  );

  const openEditReservationDrawer = useCallback(
    (reservationItem: ReservationItem) => {
      setReservationDrawer({
        mode: 'Edit',
        reservationId: reservationItem.id,
        onSuccess: (res) => {
          setReservationItems((prev) =>
            prev.map((r) => (r.id !== res.id ? r : res))
          );
        },
      });
    },
    [setReservationDrawer, setReservationItems]
  );

  return { openViewReservationDrawer, openEditReservationDrawer };
};
