import { useAreas } from '@/common/hooks/use-areas';
import { getUnixTimeMs } from '@/common/lib/date-time';
import BlockIcon from '@mui/icons-material/Block';
import { LoadingButton } from '@mui/lab';
import { Box, Stack, useTheme } from '@mui/material';
import { endOfDay, format, isSameDay, parse, startOfDay } from 'date-fns';
import { useAtom, useAtomValue } from 'jotai';
import { findLastIndex } from 'lodash-es';
import { Trans } from 'next-i18next';
import { memo, useMemo } from 'react';
import { useBlocking } from '../hooks/use-blocking';
import { useGetLeftOffset } from '../hooks/use-timeline-util';
import {
  areasAtom,
  selectedDateAtom,
  selectedItemAtom,
  visibleTimelineGroupAtom,
} from '../state';
import { SelectedItemType } from '../types';
import { Z_INDEX } from '../constants';

interface BlockingAreaItemType {
  areaId: string;
  areaBlockId: string | null;
  left: number;
  top: number;
  width: number;
  height: number;
}

const BlockedAreaItem = ({
  areaId,
  areaBlockId,
  left,
  top,
  width,
  height,
}: BlockingAreaItemType) => {
  const { deleteAreaBlock, isDeletingAreaBlock } = useBlocking();
  const [selectedItem, setSelectedItem] = useAtom(selectedItemAtom);
  const selectedDate = useAtomValue(selectedDateAtom);
  const { refetch: refetchAreas } = useAreas();

  const theme = useTheme();

  const handleSelect = () => {
    setSelectedItem({
      type: SelectedItemType.BLOCKED_AREA,
      id: areaBlockId,
    });
  };

  const handleUnblock = () => {
    deleteAreaBlock({
      id: areaId,
      ...(areaBlockId && {
        date: format(selectedDate, 'yyyy-MM-dd'),
        areaBlockId,
      }),
    }).then(() => {
      refetchAreas();
      setSelectedItem(null);
    });
  };

  const isSelected =
    selectedItem?.type === SelectedItemType.BLOCKED_AREA &&
    selectedItem.id === areaBlockId;

  return (
    <Box
      sx={{
        position: 'absolute',
        top,
        left,
        width,
        height,
        zIndex: Z_INDEX.areaTableBlockOut,
      }}
    >
      <Stack
        sx={{
          width: '100%',
          height: '100%',
          backgroundSize: 'auto auto',
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
          backgroundImage: `repeating-linear-gradient(135deg, transparent, transparent 2px, ${
            isSelected
              ? theme.palette.primary.main_12
              : 'rgba(205, 205, 205, 0.7)'
          }  2px, ${
            isSelected
              ? theme.palette.primary.main_12
              : 'rgba(205, 205, 205, 0.7)'
          } 20px )`,
          border: isSelected ? `1px dashed ${theme.palette.grey[500]}` : 'none',
        }}
        onClick={handleSelect}
        alignItems="center"
        justifyContent="center"
      >
        {isSelected && (
          <LoadingButton
            loading={isDeletingAreaBlock}
            variant="contained"
            color="primary"
            startIcon={<BlockIcon />}
            onClick={handleUnblock}
          >
            <Trans>Unblock</Trans>
          </LoadingButton>
        )}
      </Stack>
    </Box>
  );
};

const MemoizedBlockedAreaItem = memo(BlockedAreaItem);

interface BlockAreaItemsProps {
  groupTops: number[];
  groupHeights: number[];
  canvasTimeStart: number;
  canvasTimeEnd: number;
  canvasWidth: number;
}

const BlockAreaItems = ({
  groupHeights,
  groupTops,
  canvasTimeEnd,
  canvasTimeStart,
  canvasWidth,
}: BlockAreaItemsProps) => {
  const areas = useAtomValue(areasAtom);
  const visibleGroups = useAtomValue(visibleTimelineGroupAtom);
  const selectedDate = useAtomValue(selectedDateAtom);
  const getLeftOffset = useGetLeftOffset(
    canvasTimeStart,
    canvasTimeEnd,
    canvasWidth
  );
  const items = useMemo((): BlockingAreaItemType[] => {
    return areas.reduce((acc, area) => {
      const areaId = area.areaId || area.id || '';
      const childrenGroupsStartIndex = visibleGroups.findIndex(
        (g) => g.parent === areaId
      );
      const childrenGroupsEndIndex = findLastIndex(
        visibleGroups,
        (g) => g.parent === areaId
      );
      const childrenGroupsHeight = groupHeights
        .slice(childrenGroupsStartIndex, childrenGroupsEndIndex + 1)
        .reduce((acc, it) => acc + it, 0);
      if (
        childrenGroupsStartIndex < 0 ||
        childrenGroupsEndIndex < 0 ||
        childrenGroupsStartIndex > childrenGroupsEndIndex
      ) {
        return acc;
      }
      if (typeof area.enabled === 'number' && !area.enabled) {
        acc.push({
          areaId,
          areaBlockId: null,
          top: groupTops[childrenGroupsStartIndex] || 0,
          left: getLeftOffset(canvasTimeStart),
          width: getLeftOffset(canvasTimeEnd) - getLeftOffset(canvasTimeStart),
          height: childrenGroupsHeight,
        });
      }
      const currentDateAreaBlocks = area.areaBlocks?.filter((it) =>
        isSameDay(parse(it.date, 'yyyy-MM-dd', new Date()), selectedDate)
      );
      if (currentDateAreaBlocks && currentDateAreaBlocks.length) {
        currentDateAreaBlocks.forEach((block) => {
          const canvasBlockStart = getLeftOffset(
            getUnixTimeMs(
              block.entireDay === 1
                ? startOfDay(parse(block.date, 'yyyy-MM-dd', new Date()))
                : parse(
                    `${block.date} ${block.from}`,
                    'yyyy-MM-dd HH:mm:ss',
                    new Date()
                  )
            )
          );
          const canvasBlockEnd = getLeftOffset(
            getUnixTimeMs(
              block.entireDay === 1
                ? endOfDay(parse(block.date, 'yyyy-MM-dd', new Date()))
                : parse(
                    `${block.date} ${block.from}`,
                    'yyyy-MM-dd HH:mm:ss',
                    new Date()
                  )
            )
          );
          acc.push({
            areaId,
            areaBlockId: block.id,
            left: canvasBlockStart,
            top: groupTops[childrenGroupsStartIndex] || 0,
            width: canvasBlockEnd - canvasBlockStart,
            height: childrenGroupsHeight,
          });
        });
      }
      return acc;
    }, [] as BlockingAreaItemType[]);
  }, [
    areas,
    canvasTimeEnd,
    canvasTimeStart,
    getLeftOffset,
    groupHeights,
    groupTops,
    selectedDate,
    visibleGroups,
  ]);
  return (
    <>
      {items.map((it) => (
        <MemoizedBlockedAreaItem
          key={it.areaId + '_' + it.areaBlockId}
          {...it}
        />
      ))}
    </>
  );
};

export default memo(BlockAreaItems);
