import React, { createRef, useMemo } from 'react';
import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import Card from '@mui/material/Card';
import ListViewTable from './list-view-table';
import PersonTwoToneIcon from '@mui/icons-material/PersonTwoTone';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import SearchOffTwoToneIcon from '@mui/icons-material/SearchOffTwoTone';
import ClearTwoToneIcon from '@mui/icons-material/ClearTwoTone';
import RoomServiceTwoToneIcon from '@mui/icons-material/RoomServiceTwoTone';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import BasicTabs from '@/common/components/tabs';
import { useTranslation } from 'next-i18next';
import { PrimaryButton } from '@/common/components/buttons';
import { isToday } from 'date-fns';
import { useTopNavDate } from '@/common/hooks/storage';
import { useSetAtom } from 'jotai';
import { reservationDrawerAtom } from '../global-reservation-drawer';
import useI18nTimeUtils from '@/common/i18n-time-utils';
import { tabLabels, ListViewProps, TabItemsReservation } from './common';

export const DesktopListView = ({
  isFetching,
  isSearchActive,
  setIsSearchActive,
  inputSearch,
  setInputSearch,
  tab,
  changeTab,
  dataTabs,
  tabData,
}: ListViewProps) => {
  const { t } = useTranslation();
  const { formatDate } = useI18nTimeUtils();
  const theme = useTheme();

  const setReservationDrawer = useSetAtom(reservationDrawerAtom);
  const [topNavDate] = useTopNavDate();
  const focusRef = createRef<HTMLInputElement>();

  const renderNumReservationsAndPax = React.useMemo(() => {
    const allData = dataTabs.find((item) => item.type === 'ALL');

    if (!allData) return null;

    const { numReservations, numPax } = allData;

    return (
      <Box sx={{ color: 'grey.500', mt: 1 }} className="div-center">
        <RoomServiceTwoToneIcon sx={{ mr: 0.5 }} />
        <Typography variant="caption" sx={{ mr: 0.5 }}>
          {numReservations === 1
            ? t('1 Reservation')
            : t('{{numReservations}} Reservations', { numReservations })}
        </Typography>
        <PersonTwoToneIcon sx={{ mr: 0.5 }} />
        <Typography variant="caption">
          {numPax === 1 ? t('1 Cover') : t('{{numPax}} Covers', { numPax })}
        </Typography>
      </Box>
    );
  }, [dataTabs, t]);

  const tabItemsReservationByStatus = React.useMemo<
    TabItemsReservation[]
  >(() => {
    return dataTabs.map(({ type, numPax, numReservations }) => ({
      type,
      label: (
        <Box className="div-center">
          {/* <Box className="div-center" sx={{ mr: 0.5 }}>
            {getIconByStatus(type)}
          </Box> */}
          <Box sx={{ mr: 0.5 }}>
            {tabLabels[type]} ({numReservations})
          </Box>
          <Box className="div-center" sx={{ mr: 0.5 }}>
            <PersonTwoToneIcon
              sx={{
                color: tab === type ? 'primary.main' : 'grey.600',
                fontSize: 16,
              }}
            />
          </Box>
          {numPax}
        </Box>
      ),
    }));
  }, [dataTabs, tab]);

  const renderLabelDate = React.useMemo(() => {
    const isTodayDate = isToday(topNavDate);
    const formattedDate = formatDate(topNavDate, {
      en: 'd MMM yyyy',
      zh: 'yyyy MMM do',
    });
    const dayOfWeekDate = formatDate(topNavDate, { en: 'EEE' });

    return `${isTodayDate ? t('Today') : dayOfWeekDate}, ${formattedDate}`;
  }, [topNavDate, formatDate, t]);

  const renderEmptySearch = useMemo(() => {
    if (!isSearchActive) return null;

    if (isFetching) {
      return <CircularProgress />;
    }

    return (
      <Box className="div-center" sx={{ height: '500px' }}>
        <Box sx={{ textAlign: 'center' }}>
          <SearchOffTwoToneIcon
            sx={{ color: theme.palette.grey[500_48], fontSize: 72 }}
          />
          <Typography variant="body1" sx={{ color: theme.palette.grey[500] }}>
            {t('No results found')}
          </Typography>
        </Box>
      </Box>
    );
  }, [isFetching, isSearchActive, t, theme.palette.grey]);

  return (
    <Card
      variant="outlined"
      sx={{
        p: 2,
        flexGrow: tabData?.length ? 'unset' : 1,
        display: {
          xs: 'none',
          md: 'flex',
        },
        flexDirection: 'column',
      }}
    >
      {/* info all reservations & ADD button */}
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 1.5 }}
      >
        <Stack flexDirection="row" justifyContent="center" alignItems="center">
          <Typography variant="h6" sx={{ mr: 2 }}>
            {renderLabelDate}
          </Typography>
          {renderNumReservationsAndPax}
        </Stack>
        <Box>
          <PrimaryButton
            onClick={() => {
              setReservationDrawer({ mode: 'Add' });
            }}
          >
            <AddTwoToneIcon sx={{ fontSize: 24, mr: 1 }} />
            {t('Add')}
          </PrimaryButton>
        </Box>
      </Stack>

      {/* tabs & search */}
      <Box className="div-center">
        {!isSearchActive && (
          <Box
            sx={{
              width: 'calc(100% - 56px)',
              '& .MuiTabs-root': {
                background: '#fff',
                mb: 2,
                minHeight: '36px',
              },
              '& .MuiTab-root': {
                borderBottom: `1px solid ${theme.palette.grey[500_24]}`,
                minHeight: '36px',
              },
              '& .MuiButtonBase-root.MuiTab-root.Mui-selected': {
                background: '#fff',
              },
            }}
          >
            <BasicTabs
              tabs={tabItemsReservationByStatus}
              tab={tabItemsReservationByStatus.findIndex(
                (item) => item.type === tab
              )}
              onChange={(index: number) => {
                const type = tabItemsReservationByStatus[index]?.type;
                if (type) changeTab(type);
              }}
            />
          </Box>
        )}

        <Box
          sx={{
            width: isSearchActive ? '100%' : '48px',
            px: isSearchActive ? 0 : 1,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Box
            sx={{
              width: isSearchActive ? '100%' : '40px',
              transition: 'all 350ms ease-out',
            }}
          >
            <TextField
              inputRef={focusRef}
              sx={{
                '& .MuiOutlinedInput-root, & .MuiOutlinedInput-root:hover': {
                  '& > fieldset': {
                    borderColor: isSearchActive
                      ? theme.palette.grey[500_32]
                      : 'white',
                  },
                },
                '& .MuiOutlinedInput-root.Mui-focused': {
                  '& > fieldset': {
                    borderColor: 'primary.main',
                  },
                },
              }}
              fullWidth
              InputProps={{
                onChange: (e) => setInputSearch(e.target.value),
                value: inputSearch,
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      sx={{ m: -1 }}
                      disableRipple
                      onClick={() => {
                        if (!isSearchActive) {
                          setIsSearchActive(true);
                          focusRef?.current?.focus();
                        }
                      }}
                    >
                      <SearchTwoToneIcon
                        sx={{
                          fontSize: 24,
                          cursor: 'pointer',
                        }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    sx={{
                      display: isSearchActive ? undefined : 'none',
                      m: -1,
                    }}
                    position="end"
                  >
                    <IconButton
                      onClick={() => {
                        setIsSearchActive(false);
                        setInputSearch('');
                      }}
                    >
                      <ClearTwoToneIcon sx={{ cursor: 'pointer' }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
      </Box>

      <ListViewTable
        data={tabData}
        renderEmpty={renderEmptySearch}
        onViewResrv={(id) => {
          setReservationDrawer({
            mode: 'View',
            reservationId: id,
          });
        }}
        onEditResrv={(reservationId) => {
          setReservationDrawer({ mode: 'Edit', reservationId });
        }}
      />
    </Card>
  );
};
