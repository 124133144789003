export const NoteSvg = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.95508 5.66667H9.28841V7H1.95508V5.66667ZM1.95508 4.33333H9.28841V3H1.95508V4.33333ZM1.95508 9.66667H6.62175V8.33333H1.95508V9.66667ZM11.9617 7.58L12.4351 7.10667C12.6951 6.84667 13.1151 6.84667 13.3751 7.10667L13.8484 7.58C14.1084 7.84 14.1084 8.26 13.8484 8.52L13.3751 8.99333L11.9617 7.58ZM11.4884 8.05333L7.95508 11.5867V13H9.36841L12.9017 9.46667L11.4884 8.05333Z"
      fill="#212B36"
    />
  </svg>
);
