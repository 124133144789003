import { debounce } from 'lodash-es';
import { useMemo } from 'react';
import { globalEmitter } from '../lib/global-emitter';
import useEmitterEvent from './use-emitter-event';

export const useRefetchReservationsOnEvents = (
  refetchReservations: () => void
) => {
  const debouncedRefetchReservations = useMemo(
    () => debounce(refetchReservations, 1000),
    [refetchReservations]
  );
  useEmitterEvent(globalEmitter, 'CLASHING_RESERVATIONS_DEPARTED', () => {
    debouncedRefetchReservations();
  });
  useEmitterEvent(globalEmitter, 'RESERVATIONS_UPDATED', () => {
    debouncedRefetchReservations();
  });
  useEmitterEvent(globalEmitter, 'SINGLE_RESERVATION_UPDATED', () => {
    debouncedRefetchReservations();
  });
  useEmitterEvent(globalEmitter, 'SINGLE_TABLE_UPDATED', () => {
    debouncedRefetchReservations();
  });
  useEmitterEvent(globalEmitter, 'MQTT_RECONNECTED', () => {
    debouncedRefetchReservations();
  });
};
