import {
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Trans } from 'next-i18next';
import { SecondaryButton } from '@/common/components/buttons';
import { useBlocking } from '../hooks/use-blocking';
import { useSetAtom } from 'jotai';
import { useAreas } from '@/common/hooks/use-areas';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import { LoadingButton } from '@mui/lab';
import { selectedItemAtom } from '../state';

export default function UnblockingTableDialog() {
  const {
    unblockingTable,
    setUnblockingTableId,
    deleteTableBlock,
    isDeletingTableBlock,
  } = useBlocking();
  const { refetch: refetchAreas } = useAreas();
  const setSelectedItem = useSetAtom(selectedItemAtom);

  const handleClose = () => {
    setUnblockingTableId(null);
  };

  const handleConfirm = () => {
    if (!unblockingTable || !unblockingTable.tableBlocks?.length) {
      return;
    }
    Promise.allSettled(
      unblockingTable.tableBlocks.map((block) => deleteTableBlock(block.id))
    ).then(() => {
      setUnblockingTableId(null);
      setSelectedItem(null);
      refetchAreas();
    });
  };

  return (
    <Dialog
      onClose={handleClose}
      open={!!unblockingTable}
      fullWidth
      maxWidth="sm"
      PaperProps={{ sx: { display: 'flex', flexDirection: 'column' } }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: 2,
          paddingRight: 1.5,
          paddingLeft: 3,
        }}
      >
        <Stack flexDirection="row" gap={1} alignItems="center">
          <WarningTwoToneIcon fontSize="small" />
          <Typography variant="h6">
            <Trans>
              Unblock table {{ tableName: unblockingTable?.name }} for the whole
              day
            </Trans>
          </Typography>
        </Stack>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Stack sx={{ paddingX: 3, paddingBottom: 2 }}>
        <Typography variant="body1">
          <Trans>
            Unblock all blocked periods on table{' '}
            {{ tableName: unblockingTable?.name }}?
          </Trans>
        </Typography>
      </Stack>
      <DialogActions>
        <SecondaryButton onClick={handleClose}>
          <Trans>Cancel</Trans>
        </SecondaryButton>
        <LoadingButton
          onClick={handleConfirm}
          color="primary"
          variant="contained"
          loading={isDeletingTableBlock}
        >
          <Trans>Confirm</Trans>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
