import { getUnixTimeMs } from '@/common/lib/date-time';
import { getReservationStartEndTime } from '@/feat/reservation/utils';
import { Box, Stack, useTheme } from '@mui/material';
import { startOfDay } from 'date-fns';
import { useAtomValue } from 'jotai';
import { findIndex } from 'lodash-es';
import { memo, useMemo } from 'react';
import { useGetLeftOffset } from '../hooks/use-timeline-util';
import {
  areaByTableIdAtom,
  selectedDateAtom,
  selectedReservationAtom,
  tablesAtom,
  visibleTimelineGroupAtom,
} from '../state';

interface MultipleHighlightItemType {
  id: string;
  left: number;
  top: number;
  width: number;
  height: number;
}

export const MultiTableHighlightItem = ({
  top,
  left,
  width,
  height,
}: MultipleHighlightItemType) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: 'absolute',
        top,
        left,
        width,
        height,
      }}
    >
      <Stack
        sx={{
          width: '100%',
          height: '100%',
          backgroundColor: theme.palette.primary.main_12,
        }}
      />
    </Box>
  );
};

const MemoizedMultiTableHighlightItem = memo(MultiTableHighlightItem);

interface MultipleTabHighlightItemsProps {
  groupTops: number[];
  groupHeights: number[];
  canvasTimeStart: number;
  canvasTimeEnd: number;
  canvasWidth: number;
}

const MultipleTableHighlightItems = ({
  groupHeights,
  groupTops,
  canvasTimeStart,
  canvasTimeEnd,
  canvasWidth,
}: MultipleTabHighlightItemsProps) => {
  const selectedReservation = useAtomValue(selectedReservationAtom);
  const areaByTableId = useAtomValue(areaByTableIdAtom);
  const selectedDate = useAtomValue(selectedDateAtom);
  const tables = useAtomValue(tablesAtom);
  const getLeftOffset = useGetLeftOffset(
    canvasTimeStart,
    canvasTimeEnd,
    canvasWidth
  );
  const visibleGroups = useAtomValue(visibleTimelineGroupAtom);

  const items = useMemo(() => {
    if (!selectedReservation || (selectedReservation.tables?.length ?? 0) < 2) {
      return [];
    }
    const { start: rStartTime, end: rEndTime } =
      getReservationStartEndTime(selectedReservation);

    const rStartCanvasTime = getLeftOffset(getUnixTimeMs(rStartTime));
    const rEndCanvasTime = getLeftOffset(getUnixTimeMs(rEndTime));

    const horzHighlightItems: MultipleHighlightItemType[] =
      selectedReservation.tables?.map((t) => {
        const groupIdx = visibleGroups.findIndex((g) => g.id === t.id);
        const areaId = areaByTableId[t.id];
        const start = getLeftOffset(getUnixTimeMs(startOfDay(selectedDate)));

        return {
          id: `multi_table_highlight_${areaId}_${t.id}`,
          left: start,
          width: rEndCanvasTime - start,
          top: groupTops[groupIdx] || 0,
          height: groupHeights[groupIdx] || 0,
        };
      }) || [];

    const rTables = selectedReservation.tables || [];
    const rTableIndexes = rTables.map((it) =>
      findIndex(tables, (t) => t.id === it.id)
    );

    const tableStartIndex = Math.min(...rTableIndexes);
    const tableEndIndex = Math.max(...rTableIndexes);
    const vertHighlightItems = tables.reduce((acc, it, idx) => {
      if (
        tableStartIndex < idx &&
        idx < tableEndIndex &&
        !rTableIndexes.includes(idx)
      ) {
        const areaId = areaByTableId[it.id];
        const groupIdx = visibleGroups.findIndex((g) => g.id === it.id);
        acc.push({
          id: `multi_table_highlight_${areaId}_${it.id}`,
          left: rStartCanvasTime,
          width: rEndCanvasTime - rStartCanvasTime,
          height: groupHeights[groupIdx] || 0,
          top: groupTops[groupIdx] || 0,
        });
      }
      return acc;
    }, [] as MultipleHighlightItemType[]);
    return [...horzHighlightItems, ...vertHighlightItems];
  }, [
    areaByTableId,
    getLeftOffset,
    groupHeights,
    groupTops,
    selectedDate,
    selectedReservation,
    tables,
    visibleGroups,
  ]);
  return (
    <>
      {items.map((it) => (
        <MemoizedMultiTableHighlightItem key={it.id} {...it} />
      ))}
    </>
  );
};

export default memo(MultipleTableHighlightItems);
