import FitScreenIcon from '@mui/icons-material/FitScreen';
import ZoomInTwoToneIcon from '@mui/icons-material/ZoomInTwoTone';
import ZoomOutTwoToneIcon from '@mui/icons-material/ZoomOutTwoTone';
import {
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { Trans } from 'next-i18next';
import useZoom from '../hooks/use-zoom';

export const ZoomControl = () => {
  const { zoomIn, zoomOut, resetZoom, canZoomIn, canZoomOut } = useZoom();

  return (
    <ToggleButtonGroup
      color="primary"
      exclusive
      sx={{
        border: (theme) => `1px solid ${theme.palette.grey[300]}`,
        height: 40,
        touchAction: 'manipulation',
      }}
    >
      <Tooltip
        placement="bottom"
        arrow
        title={
          <Stack direction="row" gap={0.5}>
            <ZoomOutTwoToneIcon fontSize="medium" />
            <Typography>
              <Trans>Zoom Out</Trans>
            </Typography>
          </Stack>
        }
      >
        <ToggleButton
          value="out"
          aria-label="zoom out"
          disabled={!canZoomOut}
          onClick={zoomOut}
        >
          <ZoomOutTwoToneIcon fontSize="medium" />
        </ToggleButton>
      </Tooltip>
      <Tooltip
        placement="bottom"
        arrow
        title={
          <Stack direction="row" gap={0.5}>
            <FitScreenIcon fontSize="medium" />
            <Typography>
              <Trans>Fit To Screen</Trans>
            </Typography>
          </Stack>
        }
      >
        <ToggleButton value="reset" aria-label="reset zoom" onClick={resetZoom}>
          <FitScreenIcon fontSize="medium" />
        </ToggleButton>
      </Tooltip>
      <Tooltip
        placement="bottom"
        arrow
        title={
          <Stack direction="row" gap={0.5}>
            <ZoomInTwoToneIcon fontSize="medium" />
            <Typography>
              <Trans>Zoom In</Trans>
            </Typography>
          </Stack>
        }
      >
        <ToggleButton
          value="in"
          aria-label="zoom in"
          disabled={!canZoomIn}
          onClick={zoomIn}
        >
          <ZoomInTwoToneIcon fontSize="medium" />
        </ToggleButton>
      </Tooltip>
    </ToggleButtonGroup>
  );
};
