import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import useV2Api from './use-v2-api';
import { z } from 'zod';
import { TableBlockPayload, ZTableBlockPayload } from '../types/table-block';
import { AreaBlockPayload, ZAreaBlockPayload } from '../types/area-block';
import { ZAreaTable } from '../types/area';

export const useCreateTableBlock = (args?: {
  config?: UseMutationOptions<
    any,
    Error,
    TableBlockPayload & { tableId: string },
    unknown
  >;
}) => {
  const v2Api = useV2Api();
  return useMutation(
    z
      .function()
      .args(ZTableBlockPayload.extend({ tableId: z.string() }))
      .implement(
        async (
          addTableBlockPayload: TableBlockPayload & { tableId: string }
        ) => {
          return await v2Api
            .put(
              `/table-blocks/${addTableBlockPayload.tableId}/blockout`,
              addTableBlockPayload
            )
            .then((res) => ZAreaTable.parse(res.data));
        }
      ),
    args?.config
  );
};

export const useDeleteTableBlock = (args?: {
  config?: UseMutationOptions<any, Error, string, unknown>;
}) => {
  const v2Api = useV2Api();
  return useMutation(
    z
      .function()
      .args(z.string())
      .implement(async (id: string) => {
        return await v2Api.delete(`/table-blocks/${id}`);
      }),
    args?.config
  );
};

export const useCreateAreaBlock = (args?: {
  config?: UseMutationOptions<any, Error, AreaBlockPayload, unknown>;
}) => {
  const v2Api = useV2Api();
  return useMutation(
    z
      .function()
      .args(ZAreaBlockPayload.extend({ id: z.string() }))
      .implement(
        async ({
          id,
          ...addAreaBlockPayload
        }: AreaBlockPayload & { id: string }) => {
          return await v2Api.put(
            `/table-blocks/${id}/area-blockout`,
            addAreaBlockPayload
          );
        }
      ),
    args?.config
  );
};

export const useDeleteAreaBlock = (args?: {
  config?: UseMutationOptions<
    any,
    Error,
    { date?: string; areaBlockId?: string },
    unknown
  >;
}) => {
  const v2Api = useV2Api();
  return useMutation(
    z
      .function()
      .args(
        z.object({
          id: z.string(),
          date: z.string().optional(),
          areaBlockId: z.string().optional(),
        })
      )
      .implement(
        async ({
          id,
          date,
          areaBlockId,
        }: {
          id: string;
          date?: string;
          areaBlockId?: string;
        }) => {
          return await v2Api.patch(`/table-blocks/unblock-by-areaId/${id}`, {
            date,
            areaBlockId,
          });
        }
      ),
    args?.config
  );
};
