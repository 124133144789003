import { endOfDay, startOfDay } from 'date-fns';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import { MAX_ZOOM_IN, ZOOM_FACTORS } from '../constants';
import { selectedDateAtom, visibleTimeAtom } from '../state';

const useZoom = () => {
  const [visibleTime, setVisibleTime] = useAtom(visibleTimeAtom);
  const selectedDate = useAtomValue(selectedDateAtom);
  const startOfDate = useMemo(() => startOfDay(selectedDate), [selectedDate]);
  const endOfDate = useMemo(() => endOfDay(selectedDate), [selectedDate]);
  const ZOOM_IN_FACTOR = ZOOM_FACTORS.IN;
  const ZOOM_OUT_FACTOR = ZOOM_FACTORS.OUT;

  const zoomIn = useCallback(() => {
    const currentDuration = visibleTime.end - visibleTime.start;
    if (currentDuration <= MAX_ZOOM_IN) return;

    const center = (visibleTime.start + visibleTime.end) / 2;
    const newHalfDuration = currentDuration / ZOOM_IN_FACTOR / 2;

    setVisibleTime({
      start: center - newHalfDuration,
      end: center + newHalfDuration,
    });
  }, [visibleTime, setVisibleTime, ZOOM_IN_FACTOR]);

  const zoomOut = useCallback(() => {
    const currentDuration = visibleTime.end - visibleTime.start;
    const center = (visibleTime.start + visibleTime.end) / 2;
    const newHalfDuration = currentDuration / ZOOM_OUT_FACTOR / 2;

    const newStart = center - newHalfDuration;
    const newEnd = center + newHalfDuration;

    setVisibleTime({
      start: Math.max(newStart, startOfDate.getTime()),
      end: Math.min(newEnd, endOfDate.getTime()),
    });
  }, [
    visibleTime.end,
    visibleTime.start,
    ZOOM_OUT_FACTOR,
    setVisibleTime,
    startOfDate,
    endOfDate,
  ]);

  const resetZoom = useCallback(() => {
    const centerTime = (visibleTime.start + visibleTime.end) / 2;
    const halfRange = 3 * 60 * 60 * 1000;
    const newStart = centerTime - halfRange;
    const newEnd = centerTime + halfRange;

    setVisibleTime({
      start: newStart,
      end: newEnd,
    });
  }, [visibleTime, setVisibleTime]);

  const canZoomIn = visibleTime.end - visibleTime.start > MAX_ZOOM_IN;
  const canZoomOut =
    visibleTime.start > startOfDay(visibleTime.start).getTime() ||
    visibleTime.end < endOfDay(visibleTime.end).getTime();

  return {
    zoomIn,
    zoomOut,
    resetZoom,
    canZoomIn,
    canZoomOut,
  };
};

export default useZoom;
