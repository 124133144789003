import { Area, AreaTable } from '@/common/types/area';
import { parse } from 'date-fns';
import { getReservationStartEndTime } from '@/feat/reservation/utils';
import { orderBy, differenceBy } from 'lodash-es';
import { ReservationItem } from '@/common/types/reservation';
import { ServiceTiming } from '@/common/types/schedule';

export const isBlockedTable = (table: AreaTable, area: Area, time: Date) => {
  if (area) {
    if (!area.enabled) {
      return true;
    }
    if (
      area.areaBlocks?.some((b) => {
        const start = parse(
          `${b.date} ${b.from}`,
          'yyyy-MM-dd HH:mm:ss',
          new Date()
        );
        const end = parse(
          `${b.date} ${b.to}`,
          'yyyy-MM-dd HH:mm:ss',
          new Date()
        );
        return start <= time && end >= time;
      })
    ) {
      return false;
    }
  }
  if (!table.enabled) {
    return true;
  }
  return table.tableBlocks?.some((t) => {
    const start = parse(
      `${t.date} ${t.from}`,
      'yyyy-MM-dd HH:mm:ss',
      new Date()
    );
    const end = parse(`${t.date} ${t.to}`, 'yyyy-MM-dd HH:mm:ss', new Date());
    return start <= time && end >= time;
  });
};

export function getTableErrors(
  table: AreaTable,
  selectedTime: Date,
  reservations: ReservationItem[],
  areaByTableId: Record<string, Area>,
  reassigningReservation: ReservationItem,
  maxPax: number,
  selectedServiceTiming: ServiceTiming,
  area: Area
) {
  const clashingReservations = orderBy(
    reservations.filter((r1) => {
      // filter by status first

      return reservations.some((r2) => {
        if (
          r2.rStatus !== 'R::BOOKED' &&
          r2.rStatus !== 'R::CONFIRMED' &&
          r2.rStatus !== 'R::SEATED'
        ) {
          return false;
        }

        const { start: r1Start, end: r1End } = getReservationStartEndTime(r1);
        const { start: r2Start, end: r2End } = getReservationStartEndTime(r2);
        return r2Start < r1End && r2End > r1Start;
      });
    }),
    [(iter) => iter.rDateTime],
    ['asc']
  );

  const isBlockedOutTable = areaByTableId[table.id]
    ? isBlockedTable(table, areaByTableId[table.id] as Area, selectedTime)
    : true;

  const occupiedReservations = orderBy(
    reservations.filter((r1) => {
      if (r1.rStatus !== 'R::SEATED') {
        return false;
      }

      return reservations.some((r2) => {
        const { start: r1Start, end: r1End } = getReservationStartEndTime(r1);
        const { start: r2Start, end: r2End } = getReservationStartEndTime(r2);
        return r2Start < r1End && r2End >= r1Start;
      });
    }),
    [(iter) => iter.rLimitTime],
    ['asc']
  );

  return getTableErrorValue(
    differenceBy(clashingReservations, occupiedReservations, 'id'),
    occupiedReservations,
    !!isBlockedOutTable,
    selectedTime,
    reassigningReservation,
    maxPax,
    selectedServiceTiming,
    area
  );
}

export function getTableErrorValue(
  clashingReservations: ReservationItem[],
  occupiedReservations: ReservationItem[],
  isBlockedOutTable: boolean,
  selectedTime: Date,
  reassigningReservation: ReservationItem,
  maxPax: number,
  selectedServiceTiming: ServiceTiming,
  area: Area
) {
  let paxErrors = [];
  const resrvPax = Number(reassigningReservation?.pax);
  if (Number(maxPax) > resrvPax) {
    paxErrors?.push({
      source: 'maxPax',
      value: maxPax,
      conflictValue: Number(maxPax) - resrvPax,
    });
  }
  if (Number(maxPax) < resrvPax) {
    paxErrors?.push({
      source: 'minPax',
      value: maxPax,
      conflictValue: resrvPax - Number(maxPax),
    });
  }

  let errors = [];
  if (isBlockedOutTable) {
    errors.push({
      source: 'blocked',
    });
  }
  if (!!clashingReservations.length && !occupiedReservations?.length) {
    const clashingReservationsFilterByTime = clashingReservations.filter(
      (resvr) => {
        return (
          resvr?.rDateTime >= selectedTime &&
          resvr?.id !== reassigningReservation?.id
        );
      }
    );

    if (!!clashingReservationsFilterByTime.length) {
      errors.push({
        source: 'clashed',
        value: clashingReservationsFilterByTime?.[0]?.rDateTime,
      });
    }
  }
  if (!!occupiedReservations.length) {
    const occupiedReservationsFilterByTime = occupiedReservations.filter(
      (resvr) => {
        return (
          resvr?.rLimitTime >= selectedTime &&
          resvr?.id !== reassigningReservation?.id
        );
      }
    );

    if (!!occupiedReservationsFilterByTime.length) {
      errors.push({
        source: 'occupied',
        value: occupiedReservationsFilterByTime?.[0]?.rLimitTime,
      });
    }
  }

  if (
    !!selectedServiceTiming &&
    selectedServiceTiming?.areas?.length &&
    !selectedServiceTiming?.isAllArea &&
    !selectedServiceTiming?.areas?.some(
      (areaService) => areaService?.id === area?.areaId
    )
  ) {
    const areasText = selectedServiceTiming?.areas
      ?.map((area) => area.name || area.displayName)
      ?.join(',');
    errors.push({
      source: 'outsideAreasSchedule',
      value: areasText,
    });
  }
  return [...paxErrors, ...errors];
}
