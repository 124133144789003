import { useTopNavDate } from '@/common/hooks/storage';
import { timeScheduleSelectAtom } from '@/feat/reservation/state';
import { useAtom, useAtomValue } from 'jotai';
import { useEffect } from 'react';
import { currentTimeAtom } from '../state';
import { format, parse } from 'date-fns';

const UPDATE_INTERVAL = 60_000;

export const useCurrentTime = () => {
  const [currentTime, setCurrentTime] = useAtom(currentTimeAtom);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, UPDATE_INTERVAL);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [setCurrentTime]);

  return currentTime;
};

export const useSelectedTime = () => {
  const currentTime = useAtomValue(currentTimeAtom);
  const selectedTimeSlot = useAtomValue(timeScheduleSelectAtom);
  const [selectedDate] = useTopNavDate();

  if (selectedTimeSlot === 'Current Time' || selectedTimeSlot === 'All Day') {
    // return current time with date from selectedDate
    return parse(
      `${format(selectedDate, 'yyyy-MM-dd')} ${format(currentTime, 'HH:mm')}`,
      'yyyy-MM-dd HH:mm',
      new Date()
    );
  }
  // return selected date with selected time slot
  return parse(
    `${format(selectedDate, 'yyyy-MM-dd')} ${selectedTimeSlot}`,
    'yyyy-MM-dd hh:mm aa',
    new Date()
  );
};
