import { IconButton, InputAdornment, Stack } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { filterAtom } from '@/feat/reservation/components/gantt-view/state';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'next-i18next';
import { TextField } from '@mui/material';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import useDebounce from '@/common/hooks/use-debounce';

export default function SearchBarFilter() {
  const { t } = useTranslation();
  const [inputSearch, setInputSearch] = useState('');
  const debounceInputSearch = useDebounce(inputSearch);
  const setReservationFilter = useSetAtom(filterAtom);
  const focusRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setReservationFilter((prev) => ({
      ...prev,
      reservationSearchValue: debounceInputSearch,
    }));
  }, [debounceInputSearch, setReservationFilter]);

  return (
    <Stack
      direction="row"
      sx={{
        minWidth: 400,
        width: {
          xs: '100%',
          md: 'unset',
        },
      }}
    >
      <TextField
        inputRef={focusRef}
        fullWidth
        placeholder={t('Search by name, phone, email')}
        size="small"
        InputProps={{
          onChange: (e) => setInputSearch(e.target.value),
          value: inputSearch,
          sx: {
            color: 'grey.600',
            backgroundColor: 'grey.300',

            '& .MuiInputBase-input': {
              '&::placeholder': {
                color: 'grey.600',
              },
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent',
            },
          },
          startAdornment: (
            <InputAdornment position="start">
              <IconButton
                sx={{ m: -1 }}
                disableRipple
                onClick={() => {
                  focusRef?.current?.focus();
                }}
              >
                <SearchTwoToneIcon
                  sx={{
                    fontSize: 24,
                    cursor: 'pointer',
                  }}
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
}
