import {
  CircularProgress,
  Popper,
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
} from '@mui/material';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import SeatIcon from '@mui/icons-material/AirlineSeatReclineNormal';
import DepartIcon from '@mui/icons-material/Logout';
import TableBarIcon from '@mui/icons-material/TableBarTwoTone';

interface Props {
  anchorEl: HTMLDivElement | null;
  onButtonClick: (event: React.MouseEvent<HTMLElement>, value: string) => void;
  enableDepartBtn?: boolean;
  enableSeatBtn?: boolean;
  enableReassignBtn?: boolean;
  isDeparting?: boolean;
}

export default function FloatingTableAction({
  anchorEl,
  onButtonClick,
  enableSeatBtn,
  enableDepartBtn,
  enableReassignBtn,
  isDeparting,
}: Props) {
  const theme = useTheme();
  return (
    <Popper
      open={true}
      anchorEl={anchorEl}
      placement="right-start"
      modifiers={[
        {
          name: 'offset',
          options: { offset: [0, 8] },
        },
      ]}
      sx={{
        boxShadow: 4,
        border: 1,
        borderRadius: 1,
        borderColor: theme.palette.divider,
      }}
    >
      <ToggleButtonGroup
        exclusive
        orientation="vertical"
        onMouseDown={(evt) => {
          evt.stopPropagation();
        }}
        onChange={onButtonClick}
      >
        {enableSeatBtn ? (
          <ToggleButton value="seat">
            <SeatIcon fontSize="medium" />
          </ToggleButton>
        ) : null}
        {enableDepartBtn ? (
          <ToggleButton value="depart" disabled={isDeparting}>
            {isDeparting ? (
              <CircularProgress color="inherit" size={24} />
            ) : (
              <DepartIcon
                fontSize="medium"
                sx={{ color: theme.palette.error.main }}
              />
            )}
          </ToggleButton>
        ) : null}
        {enableReassignBtn ? (
          <ToggleButton value="reassign">
            <TableBarIcon fontSize="medium" />
          </ToggleButton>
        ) : null}
        <ToggleButton value="add">
          <AddIcon fontSize="medium" />
        </ToggleButton>
      </ToggleButtonGroup>
    </Popper>
  );
}
