import { getUnixTimeMs } from '@/common/lib/date-time';
import { Box, Stack } from '@mui/material';
import { endOfDay, startOfDay } from 'date-fns';
import { useAtomValue } from 'jotai';
import { flatten } from 'lodash-es';
import { memo, useMemo } from 'react';
import { Z_INDEX } from '../constants';
import {
  areasAtom,
  selectedDateAtom,
  visibleTimelineGroupAtom,
} from '../state';
import { useGetLeftOffset } from '../hooks/use-timeline-util';

const DisabledTableItem = () => {
  return (
    <Stack
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(205, 205, 205, 0.8)',
      }}
      alignItems="center"
      justifyContent="center"
    />
  );
};

const DisabledTableItemMemoized = memo(DisabledTableItem);

interface DisabledTableItemsProps {
  groupTops: number[];
  groupHeights: number[];
  canvasTimeStart: number;
  canvasTimeEnd: number;
  canvasWidth: number;
}

export function DisabledTableItems({
  groupTops,
  groupHeights,
  canvasTimeStart,
  canvasTimeEnd,
  canvasWidth,
}: DisabledTableItemsProps) {
  const areas = useAtomValue(areasAtom);
  const selectedDate = useAtomValue(selectedDateAtom);
  const visibleGroups = useAtomValue(visibleTimelineGroupAtom);

  const getLeftOffset = useGetLeftOffset(
    canvasTimeStart,
    canvasTimeEnd,
    canvasWidth
  );

  const [start, end] = useMemo(() => {
    const start = getUnixTimeMs(startOfDay(selectedDate));
    const end = getUnixTimeMs(endOfDay(selectedDate));
    return [getLeftOffset(start), getLeftOffset(end)];
  }, [getLeftOffset, selectedDate]);

  const disabledTables = useMemo(
    () =>
      flatten(areas.map((it) => it.tables || [])).filter((it) => !it.enabled),
    [areas]
  );

  return (
    <>
      {disabledTables.map((t) => {
        const groupIdx = visibleGroups.findIndex((g) => g.id === t.id);
        return (
          <Box
            key={t.id}
            sx={{
              position: 'absolute',
              top: groupTops[groupIdx],
              left: start,
              width: end - start,
              height: groupHeights[groupIdx],
              zIndex: Z_INDEX.areaTableBlockOut,
            }}
          >
            <DisabledTableItemMemoized key={t.id} />
          </Box>
        );
      })}
    </>
  );
}

export default memo(DisabledTableItems);
