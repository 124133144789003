import { useCustomerFirstName } from '@/common/hooks/use-customers';
import { ReservationItem } from '@/common/types/reservation';
import { MobileReservationItemForFloorPlan } from '@/feat/reservation/components/mobile-reservation-item';
import { timeScheduleSelectAtom } from '@/feat/reservation/state';
import { getReservationStartEndTime } from '@/feat/reservation/utils';
import { reservationSelectSeatedAtom } from '@/feat/settings/floor-plan-view/share/state';
import { css } from '@emotion/react';
import ArrowRightAltTwoToneIcon from '@mui/icons-material/ArrowRightAltTwoTone';
import SearchOffTwoToneIcon from '@mui/icons-material/SearchOffTwoTone';
import {
  Box,
  Divider,
  Stack,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material';
import { isAfter, isBefore, parse } from 'date-fns';
import { useAtom, useAtomValue } from 'jotai';
import { sortBy } from 'lodash-es';
import { Trans } from 'next-i18next';
import { ReactNode, useMemo } from 'react';
import { tabLabels } from '../../list-view/common';
import {
  dataTabsAtom,
  isSeatingModeAtom,
  selectedTablesAtom,
  storeAtom,
} from '../state';

export default function ReservationList() {
  const theme = useTheme();
  const [
    {
      reservationList: { currentTab, search },
    },
    dispatch,
  ] = useAtom(storeAtom);
  const isSeatingMode = useAtomValue(isSeatingModeAtom);
  const dataTabs = useAtomValue(dataTabsAtom);
  const reservationList = useMemo(
    () => dataTabs.find((it) => it.type === currentTab)?.data || [],
    [currentTab, dataTabs]
  );
  const scheduleTimeSelect = useAtomValue(timeScheduleSelectAtom);
  const tableSelected = useAtomValue(selectedTablesAtom);
  const reservationSeating = useAtomValue(reservationSelectSeatedAtom);
  const customerFirstName = useCustomerFirstName();
  const customerName = customerFirstName(reservationSeating?.user);

  const classifiedReservations = useMemo(() => {
    const now = new Date();
    const parsedScheduleTime =
      scheduleTimeSelect !== 'All Day' && scheduleTimeSelect !== 'Current Time'
        ? parse(scheduleTimeSelect, 'h:mm a', new Date())
        : null;

    const classifiedData = reservationList.reduce(
      (acc, it) => {
        const { start, end } = getReservationStartEndTime(it);

        if (scheduleTimeSelect === 'All Day') {
          acc.all.push(it);
        } else if (scheduleTimeSelect === 'Current Time') {
          if (isSeatingMode) {
            (it.rStatus === 'R::BOOKED' || it.rStatus === 'R::CONFIRMED'
              ? acc.upcoming
              : acc.other
            ).push(it);
          } else {
            if (
              isAfter(end, now) &&
              (isBefore(start, now) || it.rStatus === 'R::SEATED')
            ) {
              acc.current.push(it);
            } else if (
              (it.rStatus === 'R::BOOKED' || it.rStatus === 'R::CONFIRMED') &&
              isAfter(start, now)
            ) {
              acc.upcoming.push(it);
            } else if (isBefore(end, now)) {
              acc.other.push(it);
            }
          }
        } else {
          (parsedScheduleTime && isAfter(start, parsedScheduleTime)
            ? acc.upcoming
            : acc.other
          ).push(it);
        }
        return acc;
      },
      {
        all: [],
        current: [],
        upcoming: [],
        other: [],
      } as {
        all: ReservationItem[];
        current: ReservationItem[];
        upcoming: ReservationItem[];
        other: ReservationItem[];
      }
    );

    const sortingFn = (reservations: ReservationItem[]) =>
      sortBy(reservations, [(it) => it.rDateTime]);

    const { all, current, upcoming, other } = classifiedData;

    return {
      all: sortingFn(all),
      current: sortingFn(current),
      upcoming: sortingFn(upcoming),
      other: sortingFn(other),
    };
  }, [scheduleTimeSelect, reservationList, isSeatingMode]);

  return (
    <>
      {!isSeatingMode && (
        <>
          <Tabs
            value={currentTab}
            onChange={(_, newVal) =>
              dispatch({ type: 'SET_RESERVATION_TAB', value: newVal })
            }
            variant="scrollable"
            allowScrollButtonsMobile
            css={css`
              position: relative;
              background: white;
              & > .MuiTabs-scrollButtons {
                z-index: 1;
                background: white;
                border-radius: 0;
                position: absolute;
                top: 0;
                width: 48px;
                height: 48px;
                opacity: 1;

                &.Mui-disabled {
                  display: none;
                }
                &:first-of-type {
                  left: 0;
                }
                &:last-of-type {
                  right: 0;
                }
                & > .MuiSvgIcon-root {
                  width: 28px;
                  height: 28px;
                }
              }
              .MuiTab-root.MuiTab-root {
                margin-right: 0;
                padding: 0 5px;
              }
              .MuiTab-root.Mui-selected {
                color: ${theme.palette.primary.main};
              }
            `}
          >
            {dataTabs.map(({ type, numReservations }) => (
              <Tab
                key={type}
                value={type}
                label={
                  <span>
                    {tabLabels[type]} ({numReservations})
                  </span>
                }
              />
            ))}
          </Tabs>
          <Divider />
        </>
      )}
      {!reservationList.length ? (
        search ? (
          <Stack sx={{ flex: 1 }} justifyContent="center" alignItems="center">
            <SearchOffTwoToneIcon
              sx={{ color: theme.palette.grey[500_48], fontSize: 72 }}
            />
            <Typography variant="body1" sx={{ color: theme.palette.grey[500] }}>
              <Trans>No results found</Trans>
            </Typography>
          </Stack>
        ) : null
      ) : (
        <Stack
          gap={2}
          css={css`
            padding-bottom: 16px;
            margin: -4px 0;
            max-height: 100%;
            overflow-y: auto;
          `}
        >
          {isSeatingMode && (
            <Box
              sx={{
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center',
                position: 'sticky',
                top: 0,
                backgroundColor: '#FFF',
                zIndex: 100,
              }}
            >
              <Typography
                component="div"
                variant="subtitle1"
                sx={{
                  display: 'flex',
                  maxWidth: '200px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontWeight: 700,
                  color: theme.palette.primary.main,
                  mb: 2,
                }}
              >
                <Trans>Table </Trans>
                {tableSelected[0]?.name}
              </Typography>
              <ArrowRightAltTwoToneIcon
                fontSize="medium"
                sx={{ mx: 1, mb: 2, color: theme.palette.primary.main }}
              />
              {!!reservationSeating ? (
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: theme.palette.primary.main,
                    maxWidth: '200px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    fontWeight: 700,
                    mb: 2,
                  }}
                >
                  {customerName}
                </Typography>
              ) : (
                <Typography sx={{ mb: 2 }}>
                  <Trans variant="body1">Select a Customer</Trans>
                </Typography>
              )}
            </Box>
          )}
          {scheduleTimeSelect === 'All Day' && (
            <ReservationSection
              title={null}
              reservations={classifiedReservations.all}
            />
          )}

          {scheduleTimeSelect === 'Current Time' && (
            <>
              <ReservationSection
                title={!isSeatingMode ? <Trans>Current</Trans> : null}
                reservations={classifiedReservations.current}
              />
              <ReservationSection
                title={!isSeatingMode ? <Trans>Upcoming</Trans> : null}
                reservations={classifiedReservations.upcoming}
              />
              <ReservationSection
                title={<Trans>Others</Trans>}
                reservations={classifiedReservations.other}
              />
            </>
          )}

          {scheduleTimeSelect !== 'All Day' &&
            scheduleTimeSelect !== 'Current Time' && (
              <>
                <ReservationSection
                  title={
                    <>
                      {scheduleTimeSelect} <Trans>Onwards</Trans>
                    </>
                  }
                  reservations={classifiedReservations.upcoming}
                />
                <ReservationSection
                  title={<Trans>Others</Trans>}
                  reservations={classifiedReservations.other}
                />
              </>
            )}
        </Stack>
      )}
    </>
  );
}

function ReservationSection({
  title,
  reservations,
}: {
  title?: ReactNode;
  reservations: ReservationItem[];
}) {
  if (reservations.length === 0) return null;

  return (
    <>
      {title !== null && <Divider>{title}</Divider>}
      {reservations.map((reservation) => {
        return (
          <MobileReservationItemForFloorPlan
            key={reservation.id}
            reservation={reservation}
          />
        );
      })}
    </>
  );
}
