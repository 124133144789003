import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { selectedItemAtom, selectedReservationAtom } from '../state';
import { SelectedItemType, TimelineItemType } from '../types';

export function useSelection() {
  const selectedReservation = useAtomValue(selectedReservationAtom);
  const selectedItem = useAtomValue(selectedItemAtom);

  const isMultiTablesReservationSelected = useMemo(() => {
    if (!selectedReservation) {
      return false;
    }
    return (selectedReservation.tables?.length ?? 0) > 1;
  }, [selectedReservation]);

  const selectedReservationTableId = useMemo(() => {
    if (
      !selectedItem ||
      selectedItem.type !== SelectedItemType.TIMELINE_ITEM ||
      selectedItem.item.type !== TimelineItemType.RESERVATION
    ) {
      return null;
    }
    return selectedItem.item.tableId;
  }, [selectedItem]);

  const selectedReservationTableIds = useMemo(() => {
    if (!selectedReservation) {
      return [];
    }
    return selectedReservation.tables?.map((it) => it.id) || [];
  }, [selectedReservation]);

  return {
    isMultiTablesReservationSelected,
    selectedReservationTableId,
    selectedReservationTableIds,
  };
}
