import { useTimeBlock } from '@/feat/reservation/context';
import { useTopNavDate } from '@/common/hooks/storage';
import { useEffect, useCallback } from 'react';
import { endOfDay, format, parse, startOfDay } from 'date-fns';
import { useSetAtom } from 'jotai';
import { visibleTimeAtom } from '../state';

export const useTimeBlockChange = () => {
  const { timeBlock } = useTimeBlock();
  const [topNavDate] = useTopNavDate();
  const setVisibleTime = useSetAtom(visibleTimeAtom);

  const getParsedTime = useCallback((date: Date, time: string) => {
    try {
      return parse(
        `${format(date, 'yyyy-MM-dd')} ${time}`,
        'yyyy-MM-dd HH:mm:ss',
        new Date()
      );
    } catch (error) {
      console.error('Error parsing date:', error);
      return null;
    }
  }, []);

  useEffect(() => {
    if (!timeBlock || !timeBlock.start || !timeBlock.end) {
      setVisibleTime({
        start: startOfDay(topNavDate).setHours(10, 0, 0, 0),
        end: endOfDay(topNavDate).setHours(16, 0, 0, 0),
      });
      return;
    }

    const newStart = timeBlock.start
      ? getParsedTime(topNavDate, timeBlock.start)
      : startOfDay(topNavDate);
    const newEnd = timeBlock.end
      ? getParsedTime(topNavDate, timeBlock.end)
      : endOfDay(topNavDate);

    if (newStart && newEnd) {
      setVisibleTime({ start: newStart.getTime(), end: newEnd.getTime() });
    }
  }, [timeBlock, setVisibleTime, topNavDate, getParsedTime]);
};
