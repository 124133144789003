import { PrimaryButton } from '@/common/components/buttons';
import { useTopNavDate } from '@/common/hooks/storage';
import useI18nTimeUtils from '@/common/i18n-time-utils';
import { reservationDrawerAtom } from '@/feat/reservation/components/global-reservation-drawer';
import { timeScheduleSelectAtom } from '@/feat/reservation/state';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import CloseFullscreenTwoTone from '@mui/icons-material/CloseFullscreenTwoTone';
import OpenInFullTwoTone from '@mui/icons-material/OpenInFullTwoTone';
import { Card, IconButton, Stack, Typography } from '@mui/material';
import { format, isToday } from 'date-fns';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { Trans, useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import {
  currentTimeAtom,
  isReassigningModeAtom,
  isSeatingModeAtom,
  selectedTablesAtom,
  storeAtom,
} from '../state';
import ReservationList from './reservation-list';
import Search from './search';
import ReservationActionButton from './reservation-action-button';

export default function ReservationPanel() {
  const [
    {
      reservationList: { isReservationPanelMinimized: isMinimized },
    },
    dispatch,
  ] = useAtom(storeAtom);
  const isSeatingMode = useAtomValue(isSeatingModeAtom);
  const isReassigningMode = useAtomValue(isReassigningModeAtom);
  const setReservationDrawer = useSetAtom(reservationDrawerAtom);
  const scheduleTimeSelect = useAtomValue(timeScheduleSelectAtom);
  const currentTime = useAtomValue(currentTimeAtom);

  const { t } = useTranslation();
  const [topNavDate] = useTopNavDate();
  const { formatDate } = useI18nTimeUtils();

  const timeLabel = useMemo(() => {
    const isTodayDate = isToday(topNavDate);
    const formattedDate = formatDate(topNavDate, {
      en: 'd MMM yyyy',
      zh: 'yyyy MMM do',
    });
    const dayOfWeekDate = formatDate(topNavDate, { en: 'EEE' });

    const formattedTime =
      scheduleTimeSelect === 'Current Time' || scheduleTimeSelect === 'All Day'
        ? format(currentTime, 'h:mm a')
        : scheduleTimeSelect;

    return `${
      isTodayDate ? t('Today') : dayOfWeekDate
    }, ${formattedDate}, ${formattedTime}`;
  }, [topNavDate, formatDate, scheduleTimeSelect, currentTime, t]);

  const handleToggleReservationPanel = () => {
    dispatch({ type: 'TOGGLE_RESERVATION_PANEL' });
  };

  const handleAddReservation = () => {
    setReservationDrawer({ mode: 'Add' });
  };

  return (
    <Card
      variant="outlined"
      sx={[
        {
          p: 2,
          display: {
            xs: 'none',
            md: 'flex',
          },
          flexDirection: 'column',
          width: '500px',
          minWidth: '500px',
          zIndex: 2,
          height: isMinimized ? '68px' : '100%',
          transition: 'height 0.3s ease-in-out, border-radius 0.3s ease-in-out',
          gap: 2,
        },
        isMinimized
          ? { position: 'absolute' }
          : {
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            },
      ]}
    >
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
        >
          <IconButton onClick={handleToggleReservationPanel} size="small">
            {isMinimized ? <OpenInFullTwoTone /> : <CloseFullscreenTwoTone />}
          </IconButton>
          <Typography variant="h5">{timeLabel}</Typography>
        </Stack>
        {(() => {
          if (isSeatingMode)
            return (
              <Typography variant="subtitle1" sx={{ color: 'grey.600' }}>
                <Trans>Seating...</Trans>
              </Typography>
            );

          if (isReassigningMode)
            return (
              <Typography variant="subtitle1" sx={{ color: 'grey.600' }}>
                <Trans>Reassigning Table...</Trans>
              </Typography>
            );

          return (
            <PrimaryButton
              onClick={handleAddReservation}
              startIcon={<AddTwoToneIcon />}
            >
              {t('Add')}
            </PrimaryButton>
          );
        })()}
      </Stack>
      {isMinimized ? null : <ReservationListContainer />}
    </Card>
  );
}

function ReservationListContainer() {
  const tableSelected = useAtomValue(selectedTablesAtom);

  return (
    <>
      <Search />
      {tableSelected.length > 0 && (
        <ReservationActionButton table={tableSelected[0]!} />
      )}
      <ReservationList />
    </>
  );
}
