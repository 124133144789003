import { IconButton, Stack, Typography, useTheme } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import BlockIcon from '@mui/icons-material/Block';
import React from 'react';
import { Z_INDEX } from '../constants';

interface AreaHeader {
  width: number;
  height: number;
  title: React.ReactNode;
  isCollapsed: boolean;
  onToggleCollapse: (newValue: boolean) => void;
  onBlock: () => void;
}

const AreaHeader = ({
  width,
  title,
  height,
  isCollapsed,
  onToggleCollapse,
  onBlock,
}: AreaHeader) => {
  const theme = useTheme();
  return (
    <Stack
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        width: width,
        height: height,
        position: 'relative',
        zIndex: Z_INDEX.group,
        background: theme.palette.grey[300],
        paddingX: 1,
        pointerEvents: 'none',
      }}
    >
      <Stack direction="row" gap={1} alignItems="center">
        <Typography variant="subtitle2">{title}</Typography>
        <IconButton
          size="small"
          sx={{ pointerEvents: 'all' }}
          onClick={onBlock}
        >
          <BlockIcon />
        </IconButton>
      </Stack>
      <IconButton
        sx={{ pointerEvents: 'all' }}
        size="small"
        onClick={() => onToggleCollapse(!isCollapsed)}
      >
        {isCollapsed ? <ExpandMore /> : <ExpandLess />}
      </IconButton>
    </Stack>
  );
};

export default AreaHeader;
