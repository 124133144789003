import { PrimaryButton, SecondaryButton } from '@/common/components/buttons';
import DatePicker from '@/common/components/date-picker';
import Select from '@/common/components/select';
import { Area } from '@/common/types/area';
import { zodResolver } from '@hookform/resolvers/zod';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteTwoTone';
import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Switch,
  Typography,
  useTheme,
} from '@mui/material';
import { isSameDay, parse, startOfDay } from 'date-fns';
import { nanoid } from 'nanoid';
import { Trans, useTranslation } from 'next-i18next';
import { useEffect, useMemo } from 'react';
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { z } from 'zod';
import { generateBlockingTimeOptions } from './util';

const blockSchema = z.object({
  id: z.string(),
  date: z.date().optional(),
  from: z.string().optional(),
  to: z.string().optional(),
});

const formDataSchema = z.object({
  blockTemporarily: z.boolean(),
  blockIndefinitely: z.boolean(),
  blocks: z.array(blockSchema),
});

export type FormData = z.infer<typeof formDataSchema>;

interface Props {
  area: Area | null;
  isOpen: boolean;
  isLoading?: boolean;
  selectedDate?: Date;
  onClose: () => void;
  onConfirm: (area: Area, data: FormData) => void;
}

export default function AreaBlockOutModal({
  isOpen,
  isLoading,
  onClose,
  selectedDate,
  area,
  onConfirm,
}: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const blocks = useMemo(
    () =>
      area?.areaBlocks?.filter((b) => {
        if (!selectedDate) {
          return (
            parse(b.date, 'yyyy-MM-dd', new Date()) >= startOfDay(new Date())
          );
        }
        return isSameDay(selectedDate, parse(b.date, 'yyyy-MM-dd', new Date()));
      }) || [],
    [area?.areaBlocks, selectedDate]
  );

  const defaultValues: FormData = useMemo(
    () => ({
      blockIndefinitely:
        typeof area?.enabled === 'number' ? !area.enabled : false,
      blockTemporarily: !!blocks.length,
      blocks:
        blocks.map((it) => ({
          id: it.id,
          from: it.from,
          to: it.to,
          date: parse(it.date, 'yyyy-MM-dd', new Date()),
        })) || [],
    }),
    [blocks, area]
  );

  const { control, handleSubmit, reset, watch } = useForm<FormData>({
    resolver: zodResolver(formDataSchema),
    defaultValues,
  });

  const blockIndefinitely = watch('blockIndefinitely');
  const blockTemporarily = watch('blockTemporarily');

  const { fields, append, remove } = useFieldArray<FormData, 'blocks'>({
    control,
    name: 'blocks',
  });

  const handleClose = () => {
    reset();
    onClose();
  };

  const onSubmit: SubmitHandler<FormData> = (data) => {
    if (!area) return;
    onConfirm(area, data);
  };

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="sm">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          <Trans>Block {{ areaName: area?.displayName || area?.name }} </Trans>
        </DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>
              <Trans>Block Indefinitely</Trans>
            </Typography>

            <Controller
              name="blockIndefinitely"
              control={control}
              render={({ field }) => (
                <Switch {...field} checked={field.value} />
              )}
            />
          </Stack>

          {!blockIndefinitely && (
            <>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography>
                  <Trans>Block Temporarily</Trans>
                </Typography>
                <Controller
                  name="blockTemporarily"
                  control={control}
                  render={({ field }) => (
                    <Switch {...field} checked={field.value} />
                  )}
                />
              </Stack>

              {blockTemporarily && (
                <Stack justifyContent="flex-start" gap={2}>
                  {fields.map((field, index) => {
                    const fromOptions = generateBlockingTimeOptions(
                      '00:00:00',
                      '23:45:00'
                    );
                    const toOptions = generateBlockingTimeOptions(
                      '00:00:00',
                      '23:45:00'
                    );
                    return (
                      <Grid container key={field.id} columnSpacing={1}>
                        <Grid item xs={5}>
                          <Controller
                            name={`blocks.${index}.date`}
                            control={control}
                            render={({
                              field: { ref, value, onChange, ...restField },
                            }) => (
                              <DatePicker
                                {...restField}
                                disabled={!!selectedDate}
                                inputRef={ref}
                                label={t('Date')}
                                value={value || null}
                                onChange={(value) => onChange(value)}
                                desktopModeMediaQuery={theme.breakpoints.up(
                                  'sm'
                                )}
                                minDate={new Date()}
                                InputProps={{ size: 'small' }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Controller
                            control={control}
                            name={`blocks.${index}.from`}
                            render={({ field }) => (
                              <Select
                                {...field}
                                options={fromOptions}
                                label={t('From')}
                                size="small"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Controller
                            control={control}
                            name={`blocks.${index}.to`}
                            render={({ field }) => (
                              <Select
                                {...field}
                                options={toOptions}
                                label={t('To')}
                                size="small"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <SecondaryButton
                            variant="outlined"
                            size="small"
                            sx={{
                              minWidth: 36,
                              p: 1,
                            }}
                            onClick={() => {
                              remove(index);
                            }}
                          >
                            <DeleteIcon fontSize="small" color="error" />
                          </SecondaryButton>
                        </Grid>
                      </Grid>
                    );
                  })}
                  <PrimaryButton
                    variant="text"
                    startIcon={<AddIcon />}
                    onClick={() => {
                      append({
                        id: nanoid(),
                        date: selectedDate || new Date(),
                        from: '00:00:00',
                        to: '23:45:00',
                      });
                    }}
                  >
                    <Trans>Add Block Periods</Trans>
                  </PrimaryButton>
                </Stack>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <SecondaryButton variant="text" onClick={handleClose}>
            <Trans>Cancel</Trans>
          </SecondaryButton>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            color="primary"
            type="submit"
          >
            <Trans>Confirm</Trans>
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}
