import { SecondaryButton } from '@/common/components/buttons';
import { Button, Card, CardContent } from '@mui/material';
import { TimelineStateConsumer } from '@oddle.me/react-calendar-timeline';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { Trans } from 'next-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { LINE_HEIGHT, Z_INDEX } from '../constants';
import {
  disabledOverlayItemsAtom,
  multiTableCheckboxItemByIdAtom,
  multiTableCheckboxItemsAtom,
  outerScrollElAtom,
  scrollTopAtom,
  selectedItemAtom,
  selectedReservationAtom,
  timelineGroupsAtom,
  viewModeAtom,
} from '../state';
import { SelectedItemType, TimelineItemType } from '../types';

export const useMultiTableHandle = () => {
  const setMultiTableCheckboxItems = useSetAtom(multiTableCheckboxItemsAtom);
  const multiTableCheckboxItemById = useAtomValue(
    multiTableCheckboxItemByIdAtom
  );

  const isChecked = useCallback(
    (id: string) => {
      const checkboxItem = multiTableCheckboxItemById[id];
      if (!checkboxItem) {
        return false;
      }
      return (
        checkboxItem.type === TimelineItemType.CHECKBOX && checkboxItem.checked
      );
    },
    [multiTableCheckboxItemById]
  );

  const handleCheckboxChange = (id: string, checked: boolean) => {
    setMultiTableCheckboxItems((prev) =>
      prev.map((item) => (item.id === id ? { ...item, checked } : item))
    );
  };

  return { isChecked, handleCheckboxChange };
};

export const useMultiTableCheckboxUpdate = () => {
  const setMultiTableCheckboxItems = useSetAtom(multiTableCheckboxItemsAtom);
  const setDisabledOverlayItems = useSetAtom(disabledOverlayItemsAtom);
  const selectedItem = useAtomValue(selectedItemAtom);
  const timelineGroups = useAtomValue(timelineGroupsAtom);
  const viewMode = useAtomValue(viewModeAtom);
  const selectedReservation = useAtomValue(selectedReservationAtom);

  useEffect(() => {
    if (
      viewMode.isMultiTableAssignment &&
      selectedReservation &&
      selectedItem?.type === SelectedItemType.TIMELINE_ITEM
    ) {
      const reservationId =
        selectedItem.item.type === TimelineItemType.RESERVATION
          ? selectedItem.item.reservationId
          : '';
      setMultiTableCheckboxItems(
        timelineGroups
          .filter((g) => g.type === 'table')
          .map((g) => ({
            ...selectedItem.item,
            group: g.id,
            type: TimelineItemType.CHECKBOX,
            id: reservationId + '_' + g.id,
            checked: (selectedReservation.tables || [])
              .map((t) => t.id)
              .includes(g.id.toString()),
            canMove: false,
            canResize: false,
            canChangeGroup: false,
            tableId: g.id.toString(),
            span: 1,
          }))
      );
      setDisabledOverlayItems([
        { endTime: selectedItem.item.start_time },
        { startTime: selectedItem.item.end_time },
      ]);
    } else {
      setMultiTableCheckboxItems([]);
      setDisabledOverlayItems([]);
    }
  }, [
    selectedItem,
    selectedReservation,
    setDisabledOverlayItems,
    setMultiTableCheckboxItems,
    timelineGroups,
    viewMode.isMultiTableAssignment,
  ]);
};

export const MultiTableConfirmationBox = () => {
  const timelineGroups = useAtomValue(timelineGroupsAtom);
  const scrollTop = useAtomValue(scrollTopAtom);
  const outerScrollEl = useAtomValue(outerScrollElAtom);
  const [containerElm, setContainerElm] = useState<HTMLDivElement | null>(null);
  const selectedItem = useAtomValue(selectedItemAtom);
  const [{ isMultiTableAssignment }, setViewMode] = useAtom(viewModeAtom);

  const timelineGroupIdx = useMemo(() => {
    return selectedItem?.type === SelectedItemType.TIMELINE_ITEM &&
      selectedItem.item.type === TimelineItemType.PLACEHOLDER
      ? timelineGroups.findIndex((g) => g.id === selectedItem.item.group)
      : -1;
  }, [selectedItem, timelineGroups]);

  const timelineItemTop = LINE_HEIGHT * timelineGroupIdx;

  const top = useMemo(() => {
    if (!outerScrollEl || !containerElm) {
      return 0;
    }
    const viewHeight = outerScrollEl.getBoundingClientRect().height;
    const elmHeight = containerElm.getBoundingClientRect().height;

    return Math.min(
      scrollTop + viewHeight - elmHeight - 20,
      Math.max(timelineItemTop, scrollTop + 20)
    );
  }, [outerScrollEl, containerElm, scrollTop, timelineItemTop]);

  const handleConfirm = () => {
    if (!selectedItem || selectedItem.type !== SelectedItemType.TIMELINE_ITEM) {
      return;
    }
  };

  const handleCancel = () => {
    setViewMode((prev) => ({ ...prev, isMultiTableAssignment: false }));
  };

  if (
    !selectedItem ||
    selectedItem.type !== SelectedItemType.TIMELINE_ITEM ||
    !isMultiTableAssignment
  ) {
    return null;
  }

  return (
    <TimelineStateConsumer>
      {({ getLeftOffsetFromDate }) => {
        return (
          <Card
            ref={setContainerElm}
            sx={{
              position: 'absolute',
              top: top,
              left: getLeftOffsetFromDate(selectedItem.item.end_time) + 30,
              zIndex: Z_INDEX.popOver,
              visibility: top ? 'visible' : 'hidden',
            }}
          >
            <Card>
              <CardContent>
                <Button onClick={handleConfirm}>
                  <Trans>Confirm</Trans>
                </Button>
                <SecondaryButton onClick={handleCancel}>
                  <Trans>Cancel</Trans>
                </SecondaryButton>
              </CardContent>
            </Card>
          </Card>
        );
      }}
    </TimelineStateConsumer>
  );
};
