import { PrimaryButton } from '@/common/components/buttons';
import { Stack, useTheme } from '@mui/material';
import { Trans } from 'next-i18next';
import { usePlaceholder } from '../hooks/use-placeholder';
import { PlaceholderType, TimelineItem } from '../types';

interface PlaceholderItemProps {
  type: PlaceholderType;
  item: TimelineItem;
}

export const PlaceholderItem = ({ type, item }: PlaceholderItemProps) => {
  const theme = useTheme();
  const { addReservationWalkIn } = usePlaceholder();

  const handleClick = () => {
    const { tableId, start_time } = item;
    addReservationWalkIn(type, tableId, start_time.valueOf());
  };

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        width: '100%',
        height: '100%',
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        borderRadius: 0.5,
        backgroundColor: theme.palette.primary.main_8,
      }}
    >
      <PrimaryButton
        size="small"
        onClick={handleClick}
        onTouchStart={handleClick}
      >
        {type === PlaceholderType.WALK_IN && <Trans>Add Walk-in</Trans>}
        {type === PlaceholderType.RESERVATION && <Trans>Add Reservation</Trans>}
      </PrimaryButton>
    </Stack>
  );
};
