import { useTopNavDate } from '@/common/hooks/storage';
import { useAreas } from '@/common/hooks/use-areas';
import { useRefetchReservationsOnEvents } from '@/common/hooks/use-refetch-reservation-on-event';
import { useReservations } from '@/common/hooks/use-reservations';
import { useTickets } from '@/common/hooks/use-tickets';
import { useSession } from '@/feat/auth/context';
import IconAdd from '@mui/icons-material/Add';
import { Fab, Stack } from '@mui/material';
import { format } from 'date-fns';
import { useSetAtom } from 'jotai';
import { ScopeProvider } from 'jotai-scope';
import { useEffect } from 'react';
import BlockingAreaDialog from './components/BlockingAreaDialog';
import BlockingTableDialog from './components/BlockingTableDialog';
import Filter from './components/Filter';
import GanttTimeline from './components/Timeline';
import UnblockingTableDialog from './components/UnblockingTableDialog';
import UpdateReservationConfirmation from './components/UpdateReservationConfirmation';
import { ZoomControl } from './components/ZoomControl';
import { usePlaceholder } from './hooks/use-placeholder';
import * as ganttViewAtoms from './state';
import {
  areasAtom,
  reservationItemsAtom,
  selectedDateAtom,
  ticketsAtom,
} from './state';
import { PlaceholderType } from './types';

const GanttView = () => {
  const setAreas = useSetAtom(areasAtom);
  const setReservationItems = useSetAtom(reservationItemsAtom);
  const setSelectedDate = useSetAtom(selectedDateAtom);
  const { addReservationWalkIn } = usePlaceholder();
  const setTickets = useSetAtom(ticketsAtom);
  const { data: session } = useSession();

  const { data: areas } = useAreas();
  const { data: tickets } = useTickets({
    params: {
      brandId: session?.brand?.id || '',
    },
    config: {
      enabled: !!session?.brand?.id,
    },
  });
  const [selectedDate] = useTopNavDate();
  const { data: reservationRes, refetch: refetchReservations } =
    useReservations({
      params: { date: format(selectedDate, 'yyyy-MM-dd') },
    });

  // Update atom effect
  useEffect(() => {
    if (areas) {
      setAreas(areas);
    }
  }, [areas, setAreas]);

  useEffect(() => {
    if (reservationRes?.data) {
      setReservationItems(reservationRes.data);
    }
  }, [reservationRes?.data, setReservationItems]);

  useEffect(() => {
    if (selectedDate) {
      setSelectedDate(selectedDate);
    }
  }, [selectedDate, setSelectedDate]);

  useEffect(() => {
    if (tickets) {
      setTickets(tickets?.items || []);
    }
  }, [setTickets, tickets]);

  useRefetchReservationsOnEvents(refetchReservations);

  return (
    <Stack direction="column" gap={2} sx={{ flex: 1, height: 0 }}>
      <Stack
        alignItems="center"
        justifyContent="space-between"
        direction="row"
        flexWrap="wrap"
        gap={1}
      >
        <Filter />
        <ZoomControl />
      </Stack>
      <GanttTimeline />
      <Fab
        color="primary"
        sx={{ position: 'fixed', bottom: 100, right: 100 }}
        onClick={() => addReservationWalkIn(PlaceholderType.RESERVATION)}
      >
        <IconAdd fontSize="large" />
      </Fab>
      <BlockingAreaDialog />
      <BlockingTableDialog />
      <UnblockingTableDialog />
      <UpdateReservationConfirmation />
    </Stack>
  );
};

const GanttViewWithScope = () => {
  return (
    <ScopeProvider
      atoms={[
        areasAtom,
        reservationItemsAtom,
        selectedDateAtom,
        ticketsAtom,
        ganttViewAtoms.areaByIdAtom,
        ganttViewAtoms.areaByTableIdAtom,
        ganttViewAtoms.blockingAreaIdAtom,
        ganttViewAtoms.blockingTableIdAtom,
        ganttViewAtoms.collapsedAreaIdsAtom,
        ganttViewAtoms.currentTimeAtom,
        ganttViewAtoms.disabledOverlayItemsAtom,
        ganttViewAtoms.filterAtom,
        ganttViewAtoms.multiTableCheckboxItemByIdAtom,
        ganttViewAtoms.multiTableCheckboxItemsAtom,
        ganttViewAtoms.outerScrollElAtom,
        ganttViewAtoms.reservationChangesAtom,
        ganttViewAtoms.reservationItemByIdAtom,
        ganttViewAtoms.reservationProblemsProcessorAtom,
        ganttViewAtoms.scrollTopAtom,
        ganttViewAtoms.selectedItemAtom,
        ganttViewAtoms.selectedReservationAtom,
        ganttViewAtoms.tableByIdAtom,
        ganttViewAtoms.ticketsAtom,
        ganttViewAtoms.timelineGroupsAtom,
        ganttViewAtoms.timelineItemsAtom,
        ganttViewAtoms.timelineItemsByIdAtom,
        ganttViewAtoms.unblockingTableIdAtom,
        ganttViewAtoms.updatingReservationAtom,
        ganttViewAtoms.viewModeAtom,
        ganttViewAtoms.visibleTimeAtom,
        ganttViewAtoms.visibleTimelineGroupAtom,
      ]}
    >
      <GanttView />
    </ScopeProvider>
  );
};

export default GanttViewWithScope;
