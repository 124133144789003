import NextLink from '@/common/components/next-link';
import { AutoRsrvStatusBtn } from '@/common/components/reservation-status-btn';
import RestaurantSourceIcon from '@/common/components/restaurant-src-icon';
import useChangeReservationStatus from '@/common/hooks/use-change-reservation-status';
import { useCustomerNameFmt } from '@/common/hooks/use-customers';
import useI18nTimeUtils from '@/common/i18n-time-utils';
import { ReservationItem } from '@/common/types/reservation';
import { ticketColor } from '@/common/types/ticket.base';
import CelebrationTwoToneIcon from '@mui/icons-material/CelebrationTwoTone';
import EditTwoTone from '@mui/icons-material/EditTwoTone';
import LinkIcon from '@mui/icons-material/Link';
import LogoutTwoTone from '@mui/icons-material/LogoutTwoTone';
import MonetizationOnTwoToneIcon from '@mui/icons-material/MonetizationOnTwoTone';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import OpenInNewTwoToneIcon from '@mui/icons-material/OpenInNewTwoTone';
import SmsTwoToneIcon from '@mui/icons-material/SmsTwoTone';
import SupportAgentTwoToneIcon from '@mui/icons-material/SupportAgentTwoTone';
import TableBarTwoTone from '@mui/icons-material/TableBarTwoTone';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import {
  Box,
  Card,
  Chip,
  CircularProgress,
  Stack,
  ToggleButton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { isSameDay, parse } from 'date-fns';
import { useAtomValue, useSetAtom } from 'jotai';
import { Trans } from 'next-i18next';
import React, { useCallback, useMemo, useRef } from 'react';
import { useReservationItemAction } from '../reservation/components/gantt-view/hooks/use-reservation-item-action';
import { useSelection } from '../reservation/components/gantt-view/hooks/use-selection';
import { OverlapBlockOutProblemDetector } from '../reservation/components/gantt-view/reservation-changes';
import {
  areaByIdAtom,
  areaByTableIdAtom,
  reservationItemsAtom,
  selectedDateAtom,
  selectedItemAtom,
  selectedReservationAtom,
  tableByIdAtom,
} from '../reservation/components/gantt-view/state';
import {
  SelectedItemType,
  TimelineReservationItem,
} from '../reservation/components/gantt-view/types';
import useTicketUtils from '../settings/tickets/utils';
import { useResizeObserver } from '@/common/hooks/use-size-observer';
import {
  getMultiTableReservationChipColor,
  getTimeDifference,
} from '../reservation/utils';
import CustomerSegmentTags from '@/common/components/reserve-intelligence/customer-segment-tags';
import { LINE_HEIGHT } from '../reservation/components/gantt-view/constants';
import { reservationSegmentsAtom } from '@/feat/reservation/state';

interface Props {
  reservation: ReservationItem;
  timelineItem: TimelineReservationItem;
  resizeRef?: React.Ref<any>;
  resizeClassName?: string;
}

const TypographyMaxLine = styled(Typography)(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  wordBreak: 'break-word',
  fontSize: 10,
  color: theme.palette.text.secondary,
}));

function ReservationTimelineItem({
  reservation,
  timelineItem,
  resizeRef,
  resizeClassName,
}: Props) {
  const {
    ticket,
    tables,
    source,
    user,
    numberOfAdults,
    numberOfChildren,
    reservationTime,
    reservationDate,
    rStatus,
    staffNotes,
    notes: customerSpecialRequest,
    occasions,
  } = reservation;

  const customerNameFmt = useCustomerNameFmt();
  const customerName = user && customerNameFmt(user);
  const theme = useTheme();
  const { to12HourBase } = useI18nTimeUtils();
  const areaById = useAtomValue(areaByIdAtom);
  const areaByTableId = useAtomValue(areaByTableIdAtom);
  const tableById = useAtomValue(tableByIdAtom);
  const selectedDate = useAtomValue(selectedDateAtom);
  const { mutateAsync: handleChangeStatus, isLoading: isChangingStatus } =
    useChangeReservationStatus();
  const setReservationItems = useSetAtom(reservationItemsAtom);
  const setSelectedItem = useSetAtom(selectedItemAtom);
  const { openViewReservationDrawer, openEditReservationDrawer } =
    useReservationItemAction();
  const selectedReservation = useAtomValue(selectedReservationAtom);
  const { generatePriceOfTicket } = useTicketUtils();
  const cardRef = useRef<HTMLDivElement>(null);
  const listSegmentReservation = useAtomValue(reservationSegmentsAtom);

  const isMultiTablesReservation = useMemo(() => {
    return (reservation.tables?.length ?? 0) > 1;
  }, [reservation.tables?.length]);

  const { isMultiTablesReservationSelected, selectedReservationTableId } =
    useSelection();

  const isSameSelectedReservation = useMemo(() => {
    if (!selectedReservation) {
      return false;
    }
    return selectedReservation.id === reservation.id;
  }, [reservation.id, selectedReservation]);

  const isSelected = useMemo(() => {
    if (!selectedReservation) {
      return false;
    }
    return (
      isSameSelectedReservation &&
      selectedReservationTableId === timelineItem.tableId
    );
  }, [
    isSameSelectedReservation,
    selectedReservation,
    selectedReservationTableId,
    timelineItem.tableId,
  ]);
  const chipColor = useMemo(
    () => getMultiTableReservationChipColor(reservation, theme),
    [reservation, theme]
  );

  const handleDepart = useCallback(() => {
    handleChangeStatus({
      newStatus: 'R::COMPLETED',
      reservation: reservation,
    }).then((res) => {
      setReservationItems((prev) =>
        prev.map((item) => (item.id === res.id ? res : item))
      );
    });
  }, [handleChangeStatus, reservation, setReservationItems]);

  const canBeDeparted = useMemo(() => {
    if (rStatus !== 'R::SEATED') return false;
    const rTime = parse(
      `${reservationDate} ${reservationTime}`,
      'yyyy-MM-dd HH:mm:ss',
      new Date()
    );
    return isSameDay(rTime, selectedDate);
  }, [rStatus, reservationDate, reservationTime, selectedDate]);

  const blockOutProblemDetector = useMemo(
    () =>
      new OverlapBlockOutProblemDetector(areaById, areaByTableId, tableById),
    [areaById, areaByTableId, tableById]
  );

  const isWithinBlockOutSection = useMemo(
    () => blockOutProblemDetector.isWithinBlockOutSection(reservation),
    [blockOutProblemDetector, reservation]
  );

  const dataCustomerCDP = listSegmentReservation.find(
    (item) => item.id === user?.id
  );

  const handleResize = useCallback(
    (width: number, height: number) => {
      if (isSelected) {
        setSelectedItem((prev) =>
          prev?.type === SelectedItemType.TIMELINE_ITEM
            ? { ...prev, size: { width, height } }
            : prev
        );
      }
    },
    [isSelected, setSelectedItem]
  );

  useResizeObserver(cardRef, handleResize);

  if (!reservation) return null;

  return (
    <Card
      ref={cardRef}
      sx={{
        minHeight: '100%',
        width: '100%',
        borderRadius: 1,
        border: `${isSelected ? 2 : 1}px solid ${
          isSameSelectedReservation
            ? theme.palette.primary.main
            : theme.palette.grey[600]
        }`,
        boxSizing: 'border-box',
        overflow: 'visible',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: 0.5,
        backgroundColor: isWithinBlockOutSection
          ? theme.palette.warning.lighter
          : undefined,
        opacity:
          isMultiTablesReservationSelected && !isSameSelectedReservation
            ? 0.3
            : 1,
        userSelect: 'none',
      }}
    >
      <Stack
        direction="row"
        sx={{
          height: LINE_HEIGHT,
          width: '100%',
          overflow: 'hidden',
          padding: 0.5,
          boxSizing: 'border-box',
        }}
        gap={0.5}
      >
        <AutoRsrvStatusBtn
          btnVariant="COMPACT_SQUARE"
          size="medium"
          reservationItem={reservation}
          sx={{ height: '100%', flex: '0 0 38px' }}
          enableOnTouchEnd
        />

        <Box
          sx={{
            fontSize: 10,
            lineHeight: 2,
            width: '-webkit-fill-available',
            flex: 1,
          }}
        >
          <Stack>
            <Stack direction="row" alignItems="center" gap={0.25}>
              <Box
                className="div-center"
                sx={{
                  width: 16,
                  height: 16,
                  '& svg': {
                    width: 12,
                  },
                }}
              >
                <RestaurantSourceIcon source={source} />
              </Box>
              <Typography
                variant="subtitle2"
                sx={{
                  flex: 1,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {customerName}
              </Typography>
              {isMultiTablesReservation ? (
                <Chip
                  label={
                    <Stack direction="row" gap={0.5}>
                      <Stack direction="row">
                        <Typography variant="body2">
                          {numberOfAdults}
                        </Typography>
                        {!!numberOfChildren && (
                          <Typography variant="caption2">
                            +{numberOfChildren}
                          </Typography>
                        )}
                      </Stack>
                      <LinkIcon fontSize="small" />
                    </Stack>
                  }
                  size="small"
                  color={chipColor?.chipColor}
                  variant="outlined"
                  sx={{ background: chipColor?.background }}
                />
              ) : (
                <Stack direction="row">
                  <Typography variant="body2">{numberOfAdults}</Typography>
                  {!!numberOfChildren && (
                    <Typography variant="caption2">
                      +{numberOfChildren}
                    </Typography>
                  )}
                </Stack>
              )}
            </Stack>

            <CustomerSegmentTags
              segments={dataCustomerCDP?.segments ?? []}
              disableTooltip={true}
              type={'RESERVE'}
              sx={{
                '& .MuiChip-root': {
                  my: 0.5,
                  height: 24,
                },
              }}
            />

            <Stack direction="row" alignItems="center" gap={0.5}>
              <Typography
                sx={{
                  fontSize: 10,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {to12HourBase(reservationTime)}
              </Typography>
              <Stack direction="row" alignItems="center">
                <Box
                  className="div-center"
                  sx={{ width: 16, height: 16, mr: 0.25 }}
                >
                  <Box
                    sx={{
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      background: ticketColor(ticket?.color).swatch,
                    }}
                  />
                </Box>
                <Typography
                  variant="caption2"
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {ticket?.name}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Stack>
      {isSameSelectedReservation && (
        <Box
          ref={isSelected ? resizeRef : undefined}
          className={isSelected ? resizeClassName : undefined}
          sx={{
            width: 24,
            height: 24,
            lineHeight: 'normal',
            position: 'absolute',
            left: '100%',
            top: 40,
            transform: 'translate(-50%, -50%)',
            borderRadius: '50%',
            background: theme.palette.primary.light,
            paddingX: 0.5,
            opacity: isSelected ? 1 : 0.5,
          }}
        >
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ width: '100%', height: '100%', pointerEvents: 'none' }}
          >
            <OpenInFullIcon
              sx={{
                fontSize: 12,
                color: theme.palette.common.white,
                transform: 'rotateY(0deg) rotate(45deg)',
              }}
            />
          </Stack>
        </Box>
      )}
      {isSelected && (
        <Stack px={0.5} sx={{ lineHeight: 'normal' }}>
          {!!ticket?.typePayment &&
            ticket?.typePayment !== 1 &&
            !!ticket.price && (
              <Stack direction="row" gap={0.5}>
                <MonetizationOnTwoToneIcon
                  sx={{
                    transform: 'translateY(2px)',
                  }}
                />
                <TypographyMaxLine variant="body2">
                  {generatePriceOfTicket(
                    ticket?.price || 0,
                    ticket?.typePayment,
                    ticket?.typePrice
                  )}
                </TypographyMaxLine>
              </Stack>
            )}

          {!!customerSpecialRequest && (
            <Stack direction="row" gap={0.5}>
              <SmsTwoToneIcon
                sx={{
                  transform: 'translateY(2px)',
                }}
              />
              <TypographyMaxLine variant="body2">
                {customerSpecialRequest}
              </TypographyMaxLine>
            </Stack>
          )}

          {!!staffNotes && (
            <Stack direction="row" gap={0.5}>
              <SupportAgentTwoToneIcon
                sx={{
                  transform: 'translateY(2px)',
                }}
              />
              <TypographyMaxLine variant="body2">
                {staffNotes}
              </TypographyMaxLine>
            </Stack>
          )}

          {!!occasions && (
            <Stack direction="row" gap={0.5}>
              <CelebrationTwoToneIcon
                sx={{
                  transform: 'translateY(2px)',
                }}
              />
              <TypographyMaxLine variant="body2">{occasions}</TypographyMaxLine>
            </Stack>
          )}
        </Stack>
      )}

      {isSelected && (customerName || user?.phone) && (
        <Box sx={{ paddingX: 0.5, lineHeight: 'normal' }}>
          <Stack
            direction="row"
            alignItems="center"
            gap={0.5}
            sx={{
              backgroundColor: theme.palette.grey[500_16],
              borderRadius: 0.5,
              paddingX: 1,
              paddingY: 0.5,
            }}
          >
            <Stack sx={{ flex: 1 }}>
              <Typography sx={{ fontSize: 10 }}>{customerName}</Typography>
              {dataCustomerCDP?.reservationLastVisitedDate && (
                <Typography variant="caption2" sx={{ color: 'grey.600' }}>
                  <Trans>
                    Last visited{' '}
                    {{
                      timeDifference: getTimeDifference(
                        dataCustomerCDP?.reservationLastVisitedDate
                      ),
                    }}{' '}
                    ago
                  </Trans>
                </Typography>
              )}
              <Typography variant="caption2" sx={{ color: 'grey.600' }}>
                {user?.phone}
              </Typography>
              <Typography variant="caption2" sx={{ color: 'grey.600' }}>
                {user?.notesDiner}
              </Typography>
            </Stack>
            <NextLink
              target="_blank"
              href={`/customers/${user?.mcaId}`}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <OpenInNewTwoToneIcon
                fontSize="small"
                sx={{ color: theme.palette.grey[600] }}
              />
            </NextLink>
          </Stack>
        </Box>
      )}
      {isSelected && (
        <Stack
          sx={{
            backgroundColor: theme.palette.primary.main,
            padding: 1,
          }}
          direction="row"
          lineHeight="normal"
        >
          <Stack direction="row" sx={{ flex: 1, gap: 0.5 }}>
            <StyledToggleButton
              value=""
              size="small"
              onClick={() => openViewReservationDrawer(reservation)}
              onTouchEnd={() => openViewReservationDrawer(reservation)}
            >
              <VisibilityTwoToneIcon fontSize="small" />
            </StyledToggleButton>
            <StyledToggleButton
              value=""
              size="small"
              onClick={() => openEditReservationDrawer(reservation)}
              onTouchEnd={() => openEditReservationDrawer(reservation)}
            >
              <EditTwoTone fontSize="small" />
            </StyledToggleButton>
            <Stack
              direction={'row'}
              alignItems={'center'}
              gap={0.5}
              px={1}
              sx={{
                background: theme.palette.primary.main,
                color: theme.palette.common.white,
                border: '2px solid rgba(145, 158, 171, 0.32)',
                borderRadius: '6px',
                fontSize: '14px',
                fontWeight: '700',
              }}
            >
              <TableBarTwoTone fontSize="small" />
              {tables ? tables.map((t) => t.name).join(', ') : null}
            </Stack>
          </Stack>
          <Box>
            <Tooltip arrow title={<Trans>Depart</Trans>} disableFocusListener>
              <span>
                <StyledToggleButton
                  value=""
                  size="small"
                  onClick={handleDepart}
                  onTouchEnd={handleDepart}
                  disabled={!canBeDeparted || isChangingStatus}
                >
                  {!isChangingStatus ? (
                    <LogoutTwoTone fontSize="small" />
                  ) : (
                    <CircularProgress size={20} />
                  )}
                </StyledToggleButton>
              </span>
            </Tooltip>
          </Box>
        </Stack>
      )}
    </Card>
  );
}

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  background: '#ffffff',
  '&:hover': { background: theme.palette.grey[400] },
}));

export default ReservationTimelineItem;
