import { TimelineStateConsumer } from '@oddle.me/react-calendar-timeline';
import React, { useCallback } from 'react';
import { TimelineChildrenProps, TimelineContextType } from '../types';

export type ChildrenParamsType = {
  timelineContext: TimelineContextType;
  timelineChildrenProps: TimelineChildrenProps;
};

export default function TimelineExtraWrapper({
  children,
  ...restProps
}: React.PropsWithChildren<
  any & {
    children: (
      injectingProps: ChildrenParamsType
    ) => React.ReactNode | React.ReactElement;
  }
>) {
  const renderChildren = useCallback(
    (context: TimelineContextType) => {
      return children({
        timelineContext: context,
        timelineChildrenProps: restProps,
      });
    },
    [children, restProps]
  );

  return <TimelineStateConsumer>{renderChildren}</TimelineStateConsumer>;
}
