import { QUANTITY_OF_SHOW_PAX_OPTIONS } from '@/feat/reservation/components/gantt-view/constants';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PersonTwoToneIcon from '@mui/icons-material/PersonTwoTone';
import {
  Checkbox,
  Menu,
  MenuItem,
  Stack,
  ToggleButton,
  Typography,
  useTheme,
} from '@mui/material';
import { useAtom } from 'jotai';
import { Trans } from 'next-i18next';
import { useMemo, useState } from 'react';
import { filterAtom } from '../state';

const PaxFilter = () => {
  const [filterValue, setFilterValue] = useAtom(filterAtom);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const paxOptions = useMemo(() => {
    return new Array(QUANTITY_OF_SHOW_PAX_OPTIONS)
      .fill(null)
      .map((it, idx) => ({
        value: idx + 1,
      }));
  }, []);

  const handleMenuItemClick = (value: number) => {
    setFilterValue((prev) => {
      const currentPax = prev.pax ?? [];
      if (currentPax.includes(value)) {
        const newPax = currentPax.filter((pax) => pax !== value);
        return { ...prev, pax: newPax.length ? newPax : null };
      } else {
        return { ...prev, pax: [...currentPax, value] };
      }
    });
  };

  return (
    <>
      <Stack flexDirection="row" gap={0.5} alignItems="center" flexWrap="wrap">
        <ToggleButton
          value=""
          sx={{ background: theme.palette.common.white }}
          size="small"
          onClick={handleClick}
        >
          <Stack direction="row" spacing={3}>
            <Stack direction="row" spacing={0.5}>
              <PersonTwoToneIcon fontSize="medium" />
              <Typography>
                <Trans>Pax</Trans>
              </Typography>
            </Stack>
            <KeyboardArrowDownIcon fontSize="medium" />
          </Stack>
        </ToggleButton>
      </Stack>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {paxOptions.map((item, index) => (
          <MenuItem
            key={index}
            sx={{
              width: 100,
            }}
            onClick={() => handleMenuItemClick(item.value)}
          >
            <Checkbox checked={filterValue?.pax?.includes(item.value)} />
            <Typography variant="subtitle2">{item.value}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default PaxFilter;
