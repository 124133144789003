import { PrimaryButton, SecondaryButton } from '@/common/components/buttons';
import useI18nTimeUtils from '@/common/i18n-time-utils';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { parse } from 'date-fns';
import { useAtomValue } from 'jotai';
import { Trans, useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import { useUpdateReservation } from '../hooks/use-update-reservation';
import {
  reservationChangeProblemsAtom,
  reservationChangesAtom,
  reservationClashedIdsAtom,
  reservationItemsAtom,
  updatingReservationAtom,
} from '../state';
import {
  ReservationChange,
  ReservationChangeProblem,
  ReservationChangeProblemLevel,
  ReservationChangeProblemType,
  ReservationChangeType,
} from '../types';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import { useCustomerNameFmt } from '@/common/hooks/use-customers';
import { startCase } from 'lodash-es';
import useCallbackRef from '@/common/hooks/use-callback-ref';

function UpdateReservationConfirmation() {
  const { resetUpdatingReservation, applyChanges } = useUpdateReservation();

  const changes = useAtomValue(reservationChangesAtom);
  const problems = useAtomValue(reservationChangeProblemsAtom);
  const resrvClashedIds = useAtomValue(reservationClashedIdsAtom);
  const reservationItems = useAtomValue(reservationItemsAtom);

  const customerNameFmt = useCustomerNameFmt();

  const theme = useTheme();
  const { t } = useTranslation();

  const visible = useMemo(() => {
    return problems.length > 0;
  }, [problems.length]);

  const hasError = problems.some(
    (p) => p.level === ReservationChangeProblemLevel.ERROR
  );

  const clashedCustomer = useMemo(() => {
    if (resrvClashedIds.length < 1) return '';
    if (resrvClashedIds.length === 1) {
      const reservation = reservationItems?.find(
        (r) => r.id === String(resrvClashedIds[0])
      );
      return startCase(customerNameFmt(reservation?.user));
    }

    return 'others';
  }, [customerNameFmt, resrvClashedIds, reservationItems]);

  const confirmationTitle = useMemo(() => {
    let isChangeTime = false;
    let isChangeTable = false;
    let isChangeDuration = false;

    if (!Array.isArray(changes) || !changes.length) return null;

    changes.forEach((c) => {
      if (c.type === ReservationChangeType.RESERVATION_TIME)
        isChangeTime = true;

      if (c.type === ReservationChangeType.TABLE) isChangeTable = true;

      if (c.type === ReservationChangeType.DINING_INTERVAL)
        isChangeDuration = true;
    });

    if (isChangeTime) {
      return clashedCustomer
        ? t('Confirm change in reservation time and clash?')
        : t('Confirm change in reservation time?');
    }

    if (isChangeTable) {
      return clashedCustomer
        ? t('Confirm change in reservation table and clash?')
        : t('Confirm change in reservation table?');
    }

    if (isChangeDuration) {
      return clashedCustomer
        ? t('Confirm change in reservation duration and clash?')
        : t('Confirm change in reservation duration?');
    }

    return null;
  }, [changes, clashedCustomer, t]);

  const handleClose = () => {
    resetUpdatingReservation();
  };

  const handleConfirm = () => {
    applyChanges();
  };

  return (
    <Dialog
      onClose={handleClose}
      open={visible}
      fullWidth
      maxWidth="sm"
      PaperProps={{ sx: { display: 'flex', flexDirection: 'column' } }}
    >
      <DialogTitle id="alert-dialog-title">
        {hasError ? (
          <Trans>Cannot update reservation</Trans>
        ) : (
          <Stack direction="row" gap={1}>
            <WarningTwoToneIcon
              sx={{
                fontSize: 24,
                color: theme.palette.warning.main,
              }}
            />

            {confirmationTitle}
          </Stack>
        )}
      </DialogTitle>
      <DialogContent>
        <ProblemList problems={problems} />

        <ChangeContent
          changes={changes}
          clashedCustomer={clashedCustomer}
          isBlockedOutTable={problems.some(
            (p) => p.type === ReservationChangeProblemType.OVERLAP_BLOCK_OUT
          )}
        />
      </DialogContent>
      <DialogActions>
        {!hasError && (
          <>
            <SecondaryButton onClick={handleClose} variant="text">
              <Trans>Cancel</Trans>
            </SecondaryButton>
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={handleConfirm}
            >
              <Trans>Confirm</Trans>
            </LoadingButton>
          </>
        )}
        {hasError && (
          <PrimaryButton onClick={handleClose}>
            <Trans>Close</Trans>
          </PrimaryButton>
        )}
      </DialogActions>
    </Dialog>
  );
}

const ChangeContent = ({
  changes,
  clashedCustomer,
  isBlockedOutTable,
}: {
  changes: ReservationChange[];
  clashedCustomer: string;
  isBlockedOutTable: boolean;
}) => {
  const { formatSecondToTime, formatDate } = useI18nTimeUtils();
  const { t } = useTranslation();
  const updatingResrv = useAtomValue(updatingReservationAtom);

  const customerNameFmt = useCustomerNameFmt();

  const changeObj: Record<ReservationChangeType, ReservationChange> | null =
    useMemo(() => {
      let changeContent = {} as Record<
        ReservationChangeType,
        ReservationChange
      >;

      if (!Array.isArray(changes) || !changes.length) return null;

      changes.forEach((c) => {
        if (c.type === ReservationChangeType.RESERVATION_TIME) {
          changeContent[ReservationChangeType.RESERVATION_TIME] = { ...c };
        }

        if (c.type === ReservationChangeType.TABLE) {
          changeContent[ReservationChangeType.TABLE] = { ...c };
        }

        if (c.type === ReservationChangeType.DINING_INTERVAL) {
          changeContent[ReservationChangeType.DINING_INTERVAL] = { ...c };
        }
      });

      return changeContent;
    }, [changes]);

  const changeContentRenderer = useCallbackRef(
    (change: ReservationChange | undefined) => {
      if (!change) return null;

      const userName = startCase(customerNameFmt(updatingResrv?.user));
      const clashedTable = updatingResrv?.tables?.[0]?.name;

      switch (change.type) {
        case ReservationChangeType.DINING_INTERVAL: {
          const oldInterval =
            change.oldValue !== null
              ? formatSecondToTime(change.oldValue)
              : null;
          const newInterval =
            change.newValue !== null
              ? formatSecondToTime(change.newValue)
              : null;

          const changingInterval =
            oldInterval !== null && newInterval !== null
              ? t(' from {{ oldInterval }} to {{ newInterval }} ', {
                  oldInterval,
                  newInterval,
                })
              : '';

          const content = clashedCustomer ? (
            <Trans
              i18nKey="Changing {{ userName }}'s reservation duration <bold>{{ changingInterval }}</bold> will clash with {{ clashedCustomer }} on Table {{ clashedTable }}. Would you like to proceed?"
              values={{
                userName,
                changingInterval,
                clashedCustomer,
                clashedTable,
              }}
              components={{
                bold: (
                  <Typography
                    variant="subtitle1"
                    component="span"
                    sx={{
                      color: 'text.secondary',
                      fontWeight: 700,
                      fontSize: 16,
                    }}
                  />
                ),
              }}
            />
          ) : (
            <Trans
              i18nKey="Changing {{ userName }}'s reservation duration <bold>{{ changingInterval }}</bold>. Would you like to proceed?"
              values={{
                userName,
                changingInterval,
              }}
              components={{
                bold: (
                  <Typography
                    variant="subtitle1"
                    component="span"
                    sx={{
                      color: 'text.secondary',
                      fontWeight: 700,
                      fontSize: 16,
                    }}
                  />
                ),
              }}
            />
          );

          return (
            <Typography
              variant="body1"
              sx={{ color: 'text.secondary', mt: isBlockedOutTable ? 2 : 0 }}
            >
              {content}
            </Typography>
          );
        }
        case ReservationChangeType.RESERVATION_TIME: {
          const oldTime =
            change.oldValue !== null
              ? formatDate(parse(change?.oldValue, 'HH:mm:ss', new Date()), {
                  en: 'hh:mm aa',
                  zh: 'hh:mm aa',
                })
              : null;
          const newTime =
            change.newValue !== null
              ? formatDate(parse(change?.newValue, 'HH:mm:ss', new Date()), {
                  en: 'hh:mm aa',
                  zh: 'hh:mm aa',
                })
              : null;

          const changingTime =
            oldTime !== null && newTime !== null
              ? t('from {{ oldTime }} to {{ newTime }}', {
                  oldTime,
                  newTime,
                })
              : '';
          const content = clashedCustomer ? (
            <Trans
              i18nKey="Changing {{ userName }}'s reservation (<bold>{{ changingTime }}</bold>) will clash with {{ clashedCustomer }} on Table {{ clashedTable }}. Would you like to proceed?"
              values={{
                userName,
                changingTime,
                clashedCustomer,
                clashedTable,
              }}
              components={{
                bold: (
                  <Typography
                    variant="subtitle1"
                    component="span"
                    sx={{
                      color: 'text.secondary',
                      fontWeight: 700,
                      fontSize: 16,
                    }}
                  />
                ),
              }}
            />
          ) : (
            <Trans
              i18nKey="Changing {{ userName }}'s reservation <bold>{{ changingTime }}</bold>. Would you like to proceed?"
              values={{
                userName,
                changingTime,
              }}
              components={{
                bold: (
                  <Typography
                    variant="subtitle1"
                    component="span"
                    sx={{
                      color: 'text.secondary',
                      fontWeight: 700,
                      fontSize: 16,
                    }}
                  />
                ),
              }}
            />
          );
          return (
            <Typography
              variant="body1"
              sx={{ color: 'text.secondary', mt: isBlockedOutTable ? 2 : 0 }}
            >
              {content}
            </Typography>
          );
        }
        case ReservationChangeType.TABLE: {
          if (!clashedCustomer && !isBlockedOutTable) return null;
          return (
            <Typography
              variant="body1"
              sx={{ color: 'text.secondary', mt: isBlockedOutTable ? 2 : 0 }}
            >
              {t(
                "Changing {{userName}}'s reservation will clash with {{clashedCustomer}} on Table {{clashedTable}}",
                { userName, clashedCustomer, clashedTable }
              )}
            </Typography>
          );
        }
        default:
          return null;
      }
    }
  );

  return (
    <>
      {changeContentRenderer(
        changeObj?.[ReservationChangeType.RESERVATION_TIME] ||
          changeObj?.[ReservationChangeType.TABLE] ||
          changeObj?.[ReservationChangeType.DINING_INTERVAL]
      )}
    </>
  );
};

const ProblemList = ({
  problems,
}: {
  problems: ReservationChangeProblem[];
}) => {
  const firstError = problems.find(
    (p) => p.level === ReservationChangeProblemLevel.ERROR
  );

  const getProblemTitle = (problem: ReservationChangeProblem) => {
    switch (problem.type) {
      case ReservationChangeProblemType.OVERLAP_BLOCK_OUT:
        return <Trans>Reservation is inside blocked out table!</Trans>;
      case ReservationChangeProblemType.OVERLAP_RESERVATION_TABLE:
        return (
          <Trans>Table has been occupied by another reservation(s)!</Trans>
        );
      default:
        return null;
    }
  };

  if (firstError) {
    return <Alert severity="error">{getProblemTitle(firstError)}</Alert>;
  }

  return (
    <>
      {problems
        .filter(
          (p) =>
            p.level === ReservationChangeProblemLevel.WARNING &&
            p.type === ReservationChangeProblemType.OVERLAP_BLOCK_OUT
        )
        .map((p, idx) => {
          return (
            <Alert
              severity="warning"
              key={idx}
              sx={{
                '& .MuiAlert-icon': {
                  display: 'none',
                },
              }}
            >
              {getProblemTitle(p)}
            </Alert>
          );
        })}
    </>
  );
};

export default UpdateReservationConfirmation;
