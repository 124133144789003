import {
  useCreateAreaBlock,
  useCreateTableBlock,
  useDeleteAreaBlock,
  useDeleteTableBlock,
} from '@/common/hooks/use-block-tables';
import { Table } from '@/common/types/table';
import { isSameDay, parse } from 'date-fns';
import { useAtom, useAtomValue } from 'jotai';
import { useMemo } from 'react';
import {
  areaByIdAtom,
  blockingAreaIdAtom,
  blockingTableIdAtom,
  selectedDateAtom,
  tableByIdAtom,
  unblockingTableIdAtom,
} from '../state';

export const useBlocking = () => {
  const [blockingAreaId, setBlockingAreaId] = useAtom(blockingAreaIdAtom);
  const [blockingTableId, setBlockingTableId] = useAtom(blockingTableIdAtom);
  const [unblockingTableId, setUnblockingTableId] = useAtom(
    unblockingTableIdAtom
  );
  const selectedDate = useAtomValue(selectedDateAtom);

  const areaById = useAtomValue(areaByIdAtom);
  const tableById = useAtomValue(tableByIdAtom);

  const blockingArea = useMemo(() => {
    return blockingAreaId ? areaById[blockingAreaId] : null;
  }, [areaById, blockingAreaId]);

  const blockingTable = useMemo(() => {
    return blockingTableId ? tableById[blockingTableId] : null;
  }, [blockingTableId, tableById]);

  const unblockingTable = useMemo(() => {
    return unblockingTableId ? tableById[unblockingTableId] : null;
  }, [tableById, unblockingTableId]);

  const createTableBlockMutation = useCreateTableBlock();
  const deleteTableBlockMutation = useDeleteTableBlock();
  const createAreaBlockMutation = useCreateAreaBlock();
  const deleteAreaBlockMutation = useDeleteAreaBlock();

  const getTableBlockedItem = (table?: Table) => {
    return table?.tableBlocks?.find(
      (b) =>
        b.tableId === table.id &&
        isSameDay(parse(b.date, 'yyyy-MM-dd', new Date()), selectedDate)
    );
  };

  return {
    setBlockingAreaId,
    blockingArea,
    setBlockingTableId,
    blockingTable,
    setUnblockingTableId,
    unblockingTable,
    unblockingTableId,
    createTableBlock: createTableBlockMutation.mutateAsync,
    isCreatingTableBlock: createTableBlockMutation.isLoading,
    deleteTableBlock: deleteTableBlockMutation.mutateAsync,
    isDeletingTableBlock: deleteTableBlockMutation.isLoading,
    createAreaBlock: createAreaBlockMutation.mutateAsync,
    isCreatingAreaBlock: createAreaBlockMutation.isLoading,
    deleteAreaBlock: deleteAreaBlockMutation.mutateAsync,
    isDeletingAreaBlock: deleteAreaBlockMutation.isLoading,
    getTableBlockedItem,
  };
};
