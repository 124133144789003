import { PrimaryButton, SecondaryButton } from '@/common/components/buttons';
import {
  ReservationType,
  timeScheduleSelectAtom,
} from '@/feat/reservation/state';
import { reservationWithMenuAtom } from '@/feat/settings/floor-plan-view/share/state';
import useResponsive from '@/minimals.cc/hooks/useResponsive';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import AirlineSeatReclineNormalTwoToneIcon from '@mui/icons-material/AirlineSeatReclineNormalTwoTone';
import DoDisturbTwoToneIcon from '@mui/icons-material/DoDisturbTwoTone';
import { Box, useTheme } from '@mui/material';
import { isAfter, parse } from 'date-fns';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'next-i18next';
import {
  DEFAULT_RESERVATION_DURATION,
  DEFAULT_WALK_IN_DURATION,
} from '../../gantt-view/constants';
import { reservationDrawerAtom } from '../../global-reservation-drawer';
import {
  dataTabsAtom,
  isSeatingModeAtom,
  selectedTablesAtom,
  storeAtom,
} from '../state';
import { useTopNavDate } from '@/common/hooks/storage';
import { format } from 'date-fns';
import { AreaTable } from '@/common/types/area';

interface ViewFloorPlanTable {
  table: AreaTable;
}
export default function ReservationActionButton({ table }: ViewFloorPlanTable) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useResponsive('down', 'md');

  const setReservationDrawer = useSetAtom(reservationDrawerAtom);
  const tableSelected = useAtomValue(selectedTablesAtom);
  const scheduleTimeSelect = useAtomValue(timeScheduleSelectAtom);
  const dataTabs = useAtomValue(dataTabsAtom);
  const reservationShowMenu = useAtomValue(reservationWithMenuAtom);
  const isSeatingMode = useAtomValue(isSeatingModeAtom);
  const [selectedDate] = useTopNavDate();
  const [, dispatch] = useAtom(storeAtom);

  const setDrawerWithInitialValues = (reservationType: ReservationType) => {
    const DINING_DURATION =
      reservationType === 'Reservation'
        ? DEFAULT_RESERVATION_DURATION
        : DEFAULT_WALK_IN_DURATION;

    const tablesInitialValue = tableSelected?.map((table) => ({
      id: table.id,
      name: table.name,
      maxPax: table.maxPax,
    }));

    let reservationTimeSelect;

    if (
      scheduleTimeSelect === 'All Day' ||
      scheduleTimeSelect === 'Current Time'
    ) {
      reservationTimeSelect = undefined;
    } else {
      const parsedTime = parse(scheduleTimeSelect, 'hh:mm a', new Date());
      reservationTimeSelect = format(parsedTime, 'HH:mm:ss');
    }

    setReservationDrawer({
      mode: 'Add',
      reservationType,
      initialValue: {
        diningInterval: DINING_DURATION / 1000,
        reservationDate: format(selectedDate, 'yyyy-MM-dd'),
        reservationTime: reservationTimeSelect,
        tables: tablesInitialValue ?? undefined,
      },
    });
  };
  const onBockOutButtonClick = () => {
    alert('The function coming soon');
  };

  const onSeatedButtonClick = () => {
    dispatch({ type: 'SEATING_TABLE', tableId: table.id });
  };

  return (
    <>
      {!isMobile &&
        scheduleTimeSelect === 'Current Time' &&
        !isSeatingMode &&
        !reservationShowMenu && (
          <Box sx={{ flexDirection: 'row', gap: 0.5, display: 'flex' }}>
            <PrimaryButton
              sx={{ height: '48px', width: '100%' }}
              onClick={() => setDrawerWithInitialValues('WalkIn')}
            >
              <AddTwoToneIcon sx={{ fontSize: 24, mr: 1 }} />
              {t('Walk In')}
            </PrimaryButton>
            <SecondaryButton
              variant="text"
              sx={{
                height: '48px',
                width: '100%',
                borderColor: theme.palette.primary.main_48,
                borderWidth: 1,
                borderStyle: 'solid',
                color: `${theme.palette.primary.main} !important`,
              }}
              onClick={() => {
                setDrawerWithInitialValues('Reservation');
              }}
            >
              <AddTwoToneIcon sx={{ fontSize: 24, mr: 1 }} />
              {t('Reservation')}
            </SecondaryButton>
            <SecondaryButton
              sx={{ height: '48px', width: '100%', backgroundColor: '#FFF' }}
              onClick={onSeatedButtonClick}
              disabled={!dataTabs[1]?.data?.length}
            >
              <AirlineSeatReclineNormalTwoToneIcon
                sx={{ fontSize: 24, mr: 1 }}
              />
              {t('Seat')}
            </SecondaryButton>
          </Box>
        )}

      {!isMobile &&
        isAfter(
          parse(scheduleTimeSelect, 'h:mm a', new Date()),
          new Date()
        ) && (
          <Box sx={{ flexDirection: 'row', gap: 0.5, display: 'flex' }}>
            <PrimaryButton
              sx={{ height: '48px', width: '100%' }}
              onClick={() => setDrawerWithInitialValues('Reservation')}
            >
              <AddTwoToneIcon sx={{ fontSize: 24, mr: 1 }} />
              {t('Reservation')}
            </PrimaryButton>
            <SecondaryButton
              sx={{ height: '48px', width: '100%', backgroundColor: '#FFF' }}
              onClick={onBockOutButtonClick}
            >
              <DoDisturbTwoToneIcon sx={{ fontSize: 24, mr: 1 }} />
              {t('Block Out')}
            </SecondaryButton>
          </Box>
        )}
    </>
  );
}
