import { TABLE } from '@/feat/settings/floor-plan-view/share/constant';
import Ground from '@/feat/settings/floor-plan-view/share/ground';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Stack,
  Typography,
} from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useMemo, useRef } from 'react';
import { storeAtom, tablesAtom } from '../state';
import GroundControl from './ground-control';
import ViewFloorPlanTable from './table';
import useContainerSize from '@/feat/settings/floor-plan-view/hooks/use-container-size';
import { useTrackingGroundMovement } from '../hooks/use-ground';
import { getTableContainerSize } from '@/feat/settings/floor-plan-view/util';
import { Trans } from 'next-i18next';
import NextLink from '@/common/components/next-link';

export default function Canvas() {
  const tables = useAtomValue(tablesAtom);
  const canvasRef = useRef<HTMLDivElement | null>(null);
  const [canvasWidth, canvasHeight] = useContainerSize(canvasRef);
  const {
    masterData: { areas, isLoading, lastFetched },
    groundControl: { maxZoomLevel, minZoomLevel },
  } = useAtomValue(storeAtom);
  const dispatch = useSetAtom(storeAtom);
  const handleTransformed = useTrackingGroundMovement();

  const areasWithXY = useMemo(
    () => areas.filter((it) => it.x && it.y),
    [areas]
  );

  const tablesWithXY = useMemo(
    () => tables.filter((it) => it.x && it.y),
    [tables]
  );

  const { minX, minY } = useMemo(() => {
    const allX = [...areasWithXY, ...tablesWithXY].map((it) => it.x || 0);
    const allY = [...areasWithXY, ...tablesWithXY].map((it) => it.y || 0);
    return {
      minX: Math.min(...allX),
      minY: Math.min(...allY) - 70,
    };
  }, [areasWithXY, tablesWithXY]);

  const visibleTables = useMemo(() => {
    return tablesWithXY.map((it) => ({
      ...it,
      x: (it.x || 0) - minX,
      y: (it.y || 0) - minY,
    }));
  }, [minX, minY, tablesWithXY]);

  const visibleAreas = useMemo(() => {
    return areasWithXY.map((it) => ({
      ...it,
      x: (it.x || 0) - minX,
      y: (it.y || 0) - minY,
    }));
  }, [minX, minY, areasWithXY]);

  const { maxX, maxY } = useMemo(() => {
    const allItems = [...visibleTables, ...visibleAreas];
    const allItemSizes = allItems.map((it) => {
      if ('shape' in it) {
        const { containerHeight, containerWidth } = getTableContainerSize(it);
        return {
          maxX: it.x + containerWidth + 2 * TABLE.PADDING,
          maxY: it.y + containerHeight + 2 * TABLE.PADDING,
        };
      }
      return {
        maxX: it.x || 0,
        maxY: it.y || 0,
      };
    });
    return {
      maxX: Math.max(...allItemSizes.map((it) => it.maxX)),
      maxY: Math.max(...allItemSizes.map((it) => it.maxY)),
    };
  }, [visibleAreas, visibleTables]);

  const onGroundClick = () => {
    dispatch({ type: 'CLEAR_SELECTED_TABLES' });
    dispatch({
      type: 'TOGGLE_SELECTED_RESERVATION',
      selectedReservationId: null,
    });
  };

  const handleFitScreenScale = useCallback(
    (scale: number) => {
      dispatch({ type: 'UPDATE_FIT_SCREEN_SCALE', value: scale });
    },
    [dispatch]
  );
  if (tablesWithXY.length === 0 && lastFetched && !isLoading) {
    return (
      <Stack
        sx={{ flex: 1, overflow: 'hidden' }}
        justifyContent="center"
        alignItems="center"
        ref={canvasRef}
      >
        <Card sx={{ maxWidth: 500, boxShadow: 4 }}>
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{ textAlign: 'center' }}
            >
              <Trans>Setup your floor plan view</Trans>
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ textAlign: 'center' }}
            >
              <Trans>
                Head over to "Settings" &gt; "Table and Floor plan" &gt;
                "Restaurant Floor Plan" &gt; "Manage" to setup your floor plan
              </Trans>
            </Typography>
          </CardContent>
          <CardActions sx={{ justifyContent: 'center' }}>
            <Button LinkComponent={NextLink} href="/settings/floor-plan-editor">
              <Trans>Set up now</Trans>
            </Button>
          </CardActions>
        </Card>
      </Stack>
    );
  }

  return (
    <Box sx={{ flex: 1, overflow: 'hidden' }} ref={canvasRef}>
      <Ground
        maxContentX={maxX}
        maxContentY={maxY}
        onGroundClick={onGroundClick}
        disableGridBackground
        groundControl={<GroundControl />}
        maxContainerHeight={canvasHeight}
        maxContainerWidth={canvasWidth}
        minZoom={minZoomLevel}
        maxZoom={maxZoomLevel}
        onTransformed={handleTransformed}
        onFitScreenScaleChange={handleFitScreenScale}
      >
        {visibleAreas.map((a) => (
          <Typography
            key={a.areaId || a.id || ''}
            variant="subtitle1"
            sx={(theme) => ({
              color: theme.palette.text.disabled,
              position: 'absolute',
              fontSize: '1.5rem',
              pointerEvents: 'none',
              transform: `translate(${a.x || 0}px, ${a.y || 0}px)`,
            })}
          >
            {a.name || a.displayName || ''}
          </Typography>
        ))}
        {visibleTables.map((t) => (
          <ViewFloorPlanTable table={t} key={t.id} />
        ))}
      </Ground>
    </Box>
  );
}
