import { useCallback } from 'react';

export const useGetLeftOffset = (
  canvasTimeStart: number,
  canvasTimeEnd: number,
  canvasWidth: number
) => {
  const widthToZoomRatio = canvasWidth / (canvasTimeEnd - canvasTimeStart);
  const func = useCallback(
    (time: number) => {
      const timeOffset = time - canvasTimeStart;
      return timeOffset * widthToZoomRatio;
    },
    [canvasTimeStart, widthToZoomRatio]
  );
  return func;
};
