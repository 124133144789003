import { ReservationItem } from '@/common/types/reservation';
import { ActionType } from '@/feat/reservation/components/floor-plan-view/action';

export interface ReassignTableState {
  reassigningReservation: ReservationItem | null;
  newTableIds: string[];
}

const initialState: ReassignTableState = {
  reassigningReservation: null,
  newTableIds: [],
};

export const reassignTableReducer = (
  state: ReassignTableState = initialState,
  action: ActionType
): ReassignTableState => {
  switch (action.type) {
    case 'INITIAL_REASSIGNING_RESERVATION': {
      return {
        ...state,
        reassigningReservation: action.reassigningReservation,
        newTableIds:
          action.reassigningReservation.tables?.map((t) => t.id) || [],
      };
    }
    case 'UPDATE_NEW_TABLES': {
      return {
        ...state,
        newTableIds: action.newTableIds,
      };
    }
    case 'CLEAR_REASSIGNING_RESERVATION': {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
