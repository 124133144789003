import { useSnackbar } from '@/common/components/snack-bar';
import FitScreenIcon from '@mui/icons-material/FitScreen';
import VisibilityIcon from '@mui/icons-material/VisibilityTwoTone';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import {
  Box,
  Checkbox,
  Divider,
  MenuItem,
  MenuList,
  Popover,
  Radio,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useAtom } from 'jotai';
import { Trans, useTranslation } from 'next-i18next';
import { useRef, useState } from 'react';
import { useControls } from 'react-zoom-pan-pinch';
import { GroundControlState } from '../reducers/ground-control';
import { storeAtom } from '../state';

export default function GroundControl() {
  const theme = useTheme();
  const { zoomIn, zoomOut, instance, centerView } = useControls();
  const [isChangingViewMode, setIsChangingViewMode] = useState(false);
  const viewModeRef = useRef<HTMLButtonElement | null>(null);
  const [
    {
      groundControl: {
        seatedStatus,
        tableInfo,
        timeline,
        maxZoomLevel,
        minZoomLevel,
      },
    },
    dispatch,
  ] = useAtom(storeAtom);
  const { showMessage } = useSnackbar();
  const { t } = useTranslation();

  const handleCloseViewMode = () => {
    setIsChangingViewMode(false);
  };

  const updateGroundControl = (newValue: Partial<GroundControlState>) => {
    dispatch({
      type: 'UPDATE_GROUND_CONTROL_VIEW_MODE',
      payload: newValue,
    });
  };

  const handleChangeZoom = (
    _: React.MouseEvent<HTMLElement>,
    value: string
  ) => {
    const scale = instance.transformState.scale;
    if (value === 'in') {
      zoomIn();
      if (scale >= maxZoomLevel) {
        showMessage(t("You've reached the maximum zoom-in limit"));
      }
    } else if (value === 'out') {
      zoomOut();
      if (scale <= minZoomLevel) {
        showMessage(t("You've reached the maximum zoom-out limit"));
      }
    } else if (value === 'reset') {
      centerView(minZoomLevel);
    } else if (value === 'view') {
      setIsChangingViewMode(true);
    }
  };

  return (
    <>
      <Box sx={{ position: 'absolute', right: 50, top: 88, zIndex: 20 }}>
        <ToggleButtonGroup
          color="primary"
          value={isChangingViewMode ? 'view' : null}
          orientation="vertical"
          sx={{
            border: (theme) => `1px solid ${theme.palette.grey[400]}`,
            borderRadius: 1,
            boxShadow: 2,
          }}
          exclusive
          onChange={handleChangeZoom}
        >
          <Tooltip
            placement="left"
            arrow
            title={
              <Stack direction="row" gap={0.5}>
                <ZoomOutIcon fontSize="medium" />
                <Typography>
                  <Trans>Zoom In</Trans>
                </Typography>
              </Stack>
            }
          >
            <ToggleButton value="in" aria-label="zoom in">
              <ZoomInIcon fontSize="medium" />
            </ToggleButton>
          </Tooltip>
          <Tooltip
            placement="left"
            arrow
            title={
              <Stack direction="row" gap={0.5}>
                <FitScreenIcon fontSize="medium" />
                <Typography>
                  <Trans>Fit To Screen</Trans>
                </Typography>
              </Stack>
            }
          >
            <ToggleButton value="reset" aria-label="reset zoom">
              <FitScreenIcon fontSize="medium" />
            </ToggleButton>
          </Tooltip>
          <Tooltip
            placement="left"
            arrow
            title={
              <Stack direction="row" gap={0.5}>
                <ZoomOutIcon fontSize="medium" />
                <Typography>
                  <Trans>Zoom Out</Trans>
                </Typography>
              </Stack>
            }
          >
            <ToggleButton value="out" aria-label="zoom out">
              <ZoomOutIcon fontSize="medium" />
            </ToggleButton>
          </Tooltip>
          <Divider sx={{ mb: 0.5 }} />
          <Tooltip
            placement="left"
            arrow
            title={
              <Stack direction="row" gap={0.5}>
                <VisibilityIcon fontSize="medium" />
                <Typography>
                  <Trans>View Options</Trans>
                </Typography>
              </Stack>
            }
          >
            <ToggleButton
              value="view"
              aria-label="reset zoom"
              ref={viewModeRef}
            >
              <VisibilityIcon fontSize="medium" />
            </ToggleButton>
          </Tooltip>
        </ToggleButtonGroup>
      </Box>
      <Popover
        open={isChangingViewMode}
        anchorEl={viewModeRef.current}
        onClose={handleCloseViewMode}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuList sx={{ width: 300 }}>
          <MenuItem>
            <Typography
              variant="subtitle2"
              color={theme.palette.text.secondary}
            >
              <Trans>Table Viewing Options</Trans>
            </Typography>
          </MenuItem>
          <Divider>
            <Typography variant="body2" color={theme.palette.text.secondary}>
              <Trans>Timeline</Trans>
            </Typography>
          </Divider>
          <MenuItem
            onClick={() => {
              updateGroundControl({
                timeline: 'current',
              });
            }}
          >
            <Radio checked={timeline === 'current'} />
            <Trans>Current</Trans>
          </MenuItem>
          <MenuItem
            onClick={() => {
              updateGroundControl({
                timeline: 'upcoming',
              });
            }}
          >
            <Radio checked={timeline === 'upcoming'} />
            <Trans>Upcoming</Trans>
          </MenuItem>
          <MenuItem
            onClick={() => {
              updateGroundControl({
                timeline: 'all-day',
              });
            }}
          >
            <Radio checked={timeline === 'all-day'} />
            <Trans>All Day</Trans>
          </MenuItem>
          {timeline === 'current' ? (
            <>
              <Divider>
                <Typography
                  variant="body2"
                  color={theme.palette.text.secondary}
                >
                  <Trans>Seated Status</Trans>
                </Typography>
              </Divider>
              <MenuItem
                onClick={() => {
                  updateGroundControl({
                    seatedStatus: 'time-left',
                  });
                }}
              >
                <Radio checked={seatedStatus === 'time-left'} />
                <Trans>Time Left</Trans>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  updateGroundControl({
                    seatedStatus: 'duration',
                  });
                }}
              >
                <Radio checked={seatedStatus === 'duration'} />
                <Trans>Duration</Trans>
              </MenuItem>
              <Divider>
                <Typography
                  variant="body2"
                  color={theme.palette.text.secondary}
                >
                  <Trans>Table Info</Trans>
                </Typography>
              </Divider>
              <MenuItem
                onClick={() => {
                  updateGroundControl({
                    tableInfo: {
                      ...tableInfo,
                      upcomingReminder: !tableInfo.upcomingReminder,
                    },
                  });
                }}
              >
                <Checkbox checked={tableInfo.upcomingReminder} />
                <Trans>Upcoming Reminder</Trans>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  updateGroundControl({
                    tableInfo: {
                      ...tableInfo,
                      upcomingName: !tableInfo.upcomingName,
                    },
                  });
                }}
              >
                <Checkbox checked={tableInfo.upcomingName} />
                <Trans>Upcoming Name</Trans>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  updateGroundControl({
                    tableInfo: {
                      ...tableInfo,
                      seatedPax: !tableInfo.seatedPax,
                    },
                  });
                }}
              >
                <Checkbox checked={tableInfo.seatedPax} />
                <Trans>Seated Pax</Trans>
              </MenuItem>
            </>
          ) : null}
        </MenuList>
      </Popover>
    </>
  );
}
