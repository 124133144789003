import { PrimaryButton } from '@/common/components/buttons';
import RestaurantSourceIcon from '@/common/components/restaurant-src-icon';
import StickyTable from '@/common/components/sticky-table';
import {
  QuickAction,
  ReservationStatus,
} from '@/common/types/reservation-status-flow';
import { Typography, Box, useTheme, Stack } from '@mui/material';
import { Trans, useTranslation } from 'next-i18next';
import React, { ReactNode, useEffect, useMemo } from 'react';
import { Column, useTable } from 'react-table';
import QuickActionBtn from './quick-action-btn';
import DoneAllTwoToneIcon from '@mui/icons-material/DoneAllTwoTone';
import ChairTwoToneIcon from '@mui/icons-material/ChairTwoTone';
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import SmsTwoToneIcon from '@mui/icons-material/SmsTwoTone';
import SupportAgentTwoToneIcon from '@mui/icons-material/SupportAgentTwoTone';
import InboxTwoToneIcon from '@mui/icons-material/InboxTwoTone';
import NotInterestedTwoToneIcon from '@mui/icons-material/NotInterestedTwoTone';
import { css } from '@emotion/react';
import { ReservationItem } from '@/common/types/reservation';
import { useCustomerNameFmt } from '@/common/hooks/use-customers';
import { formatCurrency } from '@/common/lib/money';
import useLocaleCfg from '@/common/hooks/use-locale-cfg';
import { TouchTooltip } from '@/common/components/tooltip';
import { useMerchantReservation } from '@/common/hooks/use-reservations';
import { ticketColor, TICKET_TYPE_PAYMENT } from '@/common/types/ticket.base';
import useCallbackRef from '@/common/hooks/use-callback-ref';
import { sortBy } from 'lodash-es';
import { AutoRsrvStatusBtn } from '@/common/components/reservation-status-btn';
import useResponsive from '@/minimals.cc/hooks/useResponsive';
import useChangeReservationStatus from '@/common/hooks/use-change-reservation-status';
import useI18nTimeUtils from '@/common/i18n-time-utils';
import { occasionLabels, reservationSegmentsAtom } from '../../state';
import {
  getTimeDifference,
  isNotificationEnable,
  totalPaidReservationPayment,
} from '../../utils';
import { isPRTicket } from '@/common/types/ticket';
import { assertUnreachable } from '@/common/utils';
import last from 'lodash/last';
import orderBy from 'lodash/orderBy';
import {
  ConfirmationNotificationChannelType,
  ReservationNotificationResult,
} from '@/common/types/confirmation-notification';
import PhoneMissedOutlined from '@mui/icons-material/PhoneMissed';
import { useFeatureFlags } from '@/common/hooks/use-feature-flag';
import CancelTwoTone from '@mui/icons-material/CancelTwoTone';
import CheckTwoTone from '@mui/icons-material/CheckTwoTone';
import CustomerSegmentTags from '@/common/components/reserve-intelligence/customer-segment-tags';
import { useAtomValue } from 'jotai';

export type ReservationColumn = {
  reservationDetails: ReactNode;
  customer: ReactNode;
  pax: ReactNode;
  table: ReactNode;
  ticket: ReactNode;
  notes: ReactNode;
  action: ReactNode;
};

const statusSortOrder: Record<ReservationStatus, number> = {
  'R::PENDING': 1,
  'R::BOOKED': 2,
  'R::CONFIRMED': 3,
  'R::LATE': 4,
  'R::SEATED': 5,
  'R::BILL': 6,
  'R::OVERSTAYED': 7,
  'R::NO_SHOW': 8,
  'R::COMPLETED': 9,
  'R::CANCELLED': 10,
  'R::EXPIRED': 11,
};

interface IProps {
  data: ReservationItem[];
  renderEmpty?: ReactNode;
  onViewResrv: (reservationId: string) => any;
  onEditResrv: (reservationId: string) => any;
}

const ListViewTable = (props: IProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const locale = useLocaleCfg();
  const { formatDate } = useI18nTimeUtils();
  const isNotDesktop = useResponsive('down', 'lg');
  const { confirmationNotificationEnabled } = useFeatureFlags();
  const listSegmentReservation = useAtomValue(reservationSegmentsAtom);

  const {
    data: listReservations,
    renderEmpty,
    onViewResrv: _onViewResrv,
    onEditResrv: _onEditResrv,
  } = props;

  const onViewResrv = useCallbackRef(_onViewResrv || (() => {}));
  const onEditResrv = useCallbackRef(_onEditResrv || (() => {}));

  const { mutateAsync: handleChangeStatus } = useChangeReservationStatus();
  const { data: dataMerchantReservation } = useMerchantReservation();

  const customerNameFmt = useCustomerNameFmt();

  const handleSeat = (reservation: ReservationItem) => {
    handleChangeStatus({
      newStatus: 'R::SEATED',
      reservation: reservation,
    });
  };

  const separateOccasions = (occasions: string): string[] => {
    if (!dataMerchantReservation?.occasions) return [];

    const masterDataOccasions = dataMerchantReservation.occasions.map(
      ({ name }) => name
    );
    const splitOccasions = occasions.split(', ');

    return splitOccasions.filter((occasion) =>
      masterDataOccasions.includes(occasion)
    );
  };

  const renderBtnAction = useCallbackRef(
    (action: QuickAction, rItem: ReservationItem) => {
      const { type, isDisabled } = action;
      const disabled = isDisabled?.(rItem);
      switch (type) {
        case 'A::MARK_NOSHOW':
          return (
            <QuickActionBtn
              disabled={disabled}
              description={t('No Show')}
              onClick={() =>
                handleChangeStatus({
                  newStatus: 'R::NO_SHOW',
                  reservation: rItem,
                })
              }
            >
              <NotInterestedTwoToneIcon sx={{ fontSize: 20 }} />
            </QuickActionBtn>
          );
        case 'A::CONFIRM':
          return (
            <QuickActionBtn
              disabled={disabled}
              description={t('Confirm')}
              onClick={() =>
                handleChangeStatus({
                  newStatus: 'R::CONFIRMED',
                  reservation: rItem,
                })
              }
            >
              <DoneAllTwoToneIcon sx={{ fontSize: 20 }} />
            </QuickActionBtn>
          );
        case 'A::SEAT':
          return (
            <QuickActionBtn
              disabled={disabled}
              description={t('Seat')}
              onClick={() => handleSeat(rItem)}
            >
              <ChairTwoToneIcon sx={{ fontSize: 20 }} />
            </QuickActionBtn>
          );
        case 'A::DEPART':
          return (
            <QuickActionBtn
              disabled={disabled}
              description={t('Depart')}
              onClick={() =>
                handleChangeStatus({
                  newStatus: 'R::COMPLETED',
                  reservation: rItem,
                })
              }
            >
              <LogoutTwoToneIcon sx={{ fontSize: 20 }} />
            </QuickActionBtn>
          );
        case 'A::EDIT':
          return (
            <QuickActionBtn
              disabled={disabled}
              description={t('Edit')}
              onClick={() => {
                onEditResrv(rItem.id);
              }}
            >
              <EditTwoToneIcon sx={{ fontSize: 20 }} />
            </QuickActionBtn>
          );
        case 'A::CANCEL':
          return (
            <QuickActionBtn
              disabled={disabled}
              description={t('Cancel')}
              onClick={() =>
                handleChangeStatus({
                  newStatus: 'R::CANCELLED',
                  reservation: rItem,
                })
              }
            >
              <CancelTwoTone sx={{ fontSize: 20 }} />
            </QuickActionBtn>
          );
        case 'A::BOOK':
          return (
            <QuickActionBtn
              disabled={disabled}
              description={t('Book')}
              onClick={() =>
                handleChangeStatus({
                  newStatus: 'R::BOOKED',
                  reservation: rItem,
                })
              }
            >
              <CheckTwoTone sx={{ fontSize: 20 }} />
            </QuickActionBtn>
          );
        default:
          assertUnreachable(type);
          return null;
      }
    }
  );

  const renderNotes = useCallbackRef((rItem: ReservationItem) => {
    const { occasions, otherOccasions, notes, staffNotes } = rItem;
    const listOccasions = separateOccasions(occasions || '');

    const numNotes =
      [!!listOccasions.length, !!notes, !!staffNotes].filter((item) => item)
        .length || 0; // count num valid notes

    return (
      <Box
        sx={{
          position: 'relative',
          height: {
            sm: `${27 * numNotes}px`,
            lg: 'unset',
          },
        }}
      >
        <Box
          sx={{
            position: {
              sm: 'absolute',
              lg: 'unset',
            },
            width: {
              sm: 'max-content',
              lg: 'unset',
            },
            mt: {
              sm: 1,
              lg: 0,
            },
          }}
        >
          {!!listOccasions.length && (
            <Box sx={{ mb: 0.5 }}>
              {listOccasions.map((item: string, index: number) => {
                return (
                  <PrimaryButton
                    sx={{
                      minWidth: 'unset',
                      padding: '0.5px 4px',
                      mr: 0.5,
                      mb: 0.5,
                      color: 'grey.600',
                      backgroundColor: theme.palette.grey[500_16],
                      pointerEvents: 'none',
                      fontSize: '10px',
                    }}
                    key={index}
                  >
                    {occasionLabels(item)}
                  </PrimaryButton>
                );
              })}
              {otherOccasions && (
                <TouchTooltip arrow placement="top" title={otherOccasions}>
                  <PrimaryButton
                    sx={{
                      color: 'grey.600',
                      background: theme.palette.grey[500_16],
                      padding: '2px 8px',
                      mr: 0.5,
                      mb: 0.5,
                      minWidth: 'unset',
                      fontSize: '10px',
                      '&:hover': {
                        background: theme.palette.grey[500_16],
                      },
                    }}
                  >
                    {t('Others')}
                  </PrimaryButton>
                </TouchTooltip>
              )}
            </Box>
          )}
          {notes && (
            <Box sx={{ display: 'flex' }}>
              <SmsTwoToneIcon
                sx={{
                  fontSize: 16,
                  color: 'grey.600',
                  marginTop: '3px',
                  mr: 0.5,
                }}
              />
              <Typography variant="caption">{notes}</Typography>
            </Box>
          )}
          {staffNotes && (
            <Box sx={{ display: 'flex' }}>
              <SupportAgentTwoToneIcon
                sx={{
                  fontSize: 16,
                  color: 'grey.600',
                  marginTop: '3px',
                  mr: 0.5,
                }}
              />

              <Typography variant="caption">{staffNotes}</Typography>
            </Box>
          )}
        </Box>
      </Box>
    );
  });

  const sortedReservations = React.useMemo(
    () =>
      sortBy(listReservations, [
        (it) => it.rDateTime,
        (it) => statusSortOrder[it.rStatus],
      ]),
    [listReservations]
  );

  // require name is "data" with useTable
  const data = React.useMemo<ReservationColumn[]>(() => {
    return sortedReservations.map((rItem) => {
      const {
        rDateTime,
        code,
        isWalkIn,
        source,
        user,
        pax,
        tables,
        ticket,
        allowableActions,
        confirmationNotifications,
      } = rItem;
      let labelTicketPayment = '';

      const userSegmentsData =
        listSegmentReservation.find((item) => item.id === user?.id)?.segments ||
        [];

      const reservationLastVisitedDate = listSegmentReservation.find(
        (item) => item.id === user?.id
      )?.reservationLastVisitedDate;
      let areasName: string[] = [];
      tables?.forEach((table) => {
        const areaName: string =
          table?.tableInAreas?.[0]?.layoutArea?.name ||
          table?.tableInAreas?.[0]?.layoutArea?.displayName ||
          '';

        const isExistedArea = areasName?.some(
          (item: string) => item === areaName
        );

        if (!isExistedArea && !!areaName)
          (areasName as string[]).push(areaName);
      });

      const lastNotification =
        confirmationNotificationEnabled && confirmationNotifications
          ? last(
              orderBy(
                confirmationNotifications.filter((it) => it.isSent),
                (it) => it.sendAt
              )
            )
          : null;

      const isPhoneCallNotification =
        confirmationNotificationEnabled &&
        !!lastNotification &&
        lastNotification.channel === ConfirmationNotificationChannelType.Phone;

      const isMissedCallNotification =
        confirmationNotificationEnabled &&
        isPhoneCallNotification &&
        lastNotification?.result === ReservationNotificationResult.Missed;

      if (isPRTicket(ticket)) {
        switch (ticket.typePayment) {
          case TICKET_TYPE_PAYMENT['Deposit']:
            labelTicketPayment = t('Deposit');
            break;
          case TICKET_TYPE_PAYMENT['Prepaid']:
            labelTicketPayment = t('Prepaid');
            break;
          case TICKET_TYPE_PAYMENT['CardGuarantee']:
            labelTicketPayment = t('Card Guarantee');
            break;
          default:
            assertUnreachable(ticket.typePayment);
            break;
        }
      }

      return {
        reservationDetails: (
          <Box>
            <Stack flexDirection="row" gap={1} alignItems="center">
              {lastNotification && isNotificationEnable(rItem) ? (
                isMissedCallNotification ? (
                  <PhoneMissedOutlined fontSize="medium" color="error" />
                ) : null
              ) : null}
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                {formatDate(rDateTime, { en: 'hh:mma' })}
              </Typography>
            </Stack>
            <Box
              sx={{ mb: 0.25, cursor: 'default' }}
              onClick={(event) => event.stopPropagation()}
            >
              <AutoRsrvStatusBtn reservationItem={rItem} />
            </Box>

            <Box>
              <Typography variant="caption" sx={{ color: 'grey.500' }}>
                #{code}
              </Typography>
            </Box>
          </Box>
        ),
        customer: (
          <Box sx={{ position: 'relative' }}>
            <Box sx={{ display: 'flex', minHeight: '44px' }}>
              <Box sx={{ mt: 0.3, mr: 1 }}>
                <RestaurantSourceIcon source={source} />
              </Box>
              {isWalkIn && !user ? (
                <Typography variant="body2">{t('Walk-in')}</Typography>
              ) : (
                <>
                  {user && (
                    <Stack>
                      <Typography variant="body2">
                        {customerNameFmt(user)}
                      </Typography>
                      {reservationLastVisitedDate && (
                        <Typography
                          variant="caption"
                          sx={{ color: 'grey.600' }}
                        >
                          <Trans>
                            Last visited{' '}
                            {{
                              timeDifference: getTimeDifference(
                                reservationLastVisitedDate
                              ),
                            }}{' '}
                            ago
                          </Trans>
                        </Typography>
                      )}

                      <Typography variant="caption" sx={{ color: 'grey.600' }}>
                        {user.phone}
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{ color: 'grey.600', mb: 0.5 }}
                      >
                        {user.email}
                      </Typography>

                      <CustomerSegmentTags
                        segments={userSegmentsData}
                        type={'RESERVE'}
                        sx={{
                          '& .MuiChip-root': {
                            height: 24,
                          },
                        }}
                      />
                    </Stack>
                  )}
                </>
              )}
            </Box>
            <Box
              sx={{
                display: {
                  lg: 'none',
                },
              }}
            >
              {renderNotes(rItem)}
            </Box>
          </Box>
        ),
        pax: (
          <Box>
            <Typography variant="body2">{pax}</Typography>
          </Box>
        ),
        table: (
          <>
            {!!tables?.length && (
              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    maxWidth: '100px',
                    display: '-webkit-box',
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {tables.map((table) => table?.name).join(', ')}
                </Typography>

                <Typography
                  variant="body2"
                  sx={{
                    color: 'grey.600',
                    maxWidth: '100px',
                    display: '-webkit-box',
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {!!areasName?.length && areasName?.join(', ')}
                </Typography>
              </Box>
            )}
          </>
        ),
        ticket: (
          <>
            <Box sx={{ display: 'flex' }}>
              {!isWalkIn && (
                <Box>
                  <Box
                    sx={{
                      background: ticketColor(ticket?.color).swatch,
                      width: 10.67,
                      height: 10.67,
                      borderRadius: '50%',
                      mr: 1,
                      mt: 0.75,
                    }}
                  />
                </Box>
              )}

              <Box>
                {isWalkIn ? (
                  <Typography variant="body2">{t('Walk-in')}</Typography>
                ) : (
                  <>
                    {ticket && (
                      <>
                        <Typography
                          variant="body2"
                          sx={{
                            maxWidth: {
                              lg: '280px',
                              sx: 'unset',
                            },
                            overflow: 'hidden',
                            whiteSpace: {
                              lg: 'nowrap',
                              sx: 'unset',
                            },
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {ticket.name}
                        </Typography>
                        {isPRTicket(ticket) && (
                          <Typography
                            variant="body2"
                            sx={{ color: 'grey.600' }}
                          >
                            {formatCurrency(
                              totalPaidReservationPayment({
                                pax: pax || 0,
                                price: ticket.price,
                                typePrice: ticket.typePrice,
                              }),
                              locale
                            )}{' '}
                            {labelTicketPayment}
                          </Typography>
                        )}
                      </>
                    )}
                  </>
                )}
              </Box>
            </Box>
          </>
        ),
        notes: <>{renderNotes(rItem)}</>,
        action: (
          <Stack direction="row" sx={{ cursor: 'default' }} gap={1}>
            {allowableActions?.map((item, index) => (
              <React.Fragment key={index}>
                {renderBtnAction(item, rItem)}
              </React.Fragment>
            ))}
          </Stack>
        ),
      };
    });
  }, [
    sortedReservations,
    listSegmentReservation,
    confirmationNotificationEnabled,
    formatDate,
    t,
    customerNameFmt,
    renderNotes,
    locale,
    renderBtnAction,
  ]);

  const paxWidth = useMemo(() => {
    return locale.selectedLang.id === 'ENGLISH' ? 32 : 64;
  }, [locale.selectedLang.id]);

  const columns = React.useMemo<Column<typeof data[number]>[]>(
    () => [
      {
        accessor: 'reservationDetails',
        Header: t('Details'),
        width: 164,
      },
      {
        accessor: 'customer',
        Header: t('Customer'),
        minWidth: 144,
        maxWidth: 151,
      },
      { accessor: 'pax', Header: t('Pax'), width: paxWidth },
      { accessor: 'table', Header: t('Table'), width: 112 },
      {
        accessor: 'ticket',
        Header: t('Ticket/Walk-in'),
        minWidth: 136,
        maxWidth: 300,
      },
      {
        accessor: 'notes',
        Header: t('Special Req./Staff Notes'),
      },
      { accessor: 'action', Header: t('Quick Actions'), width: 160 },
    ],
    [paxWidth, t]
  );

  const hiddenColumns = React.useMemo(
    () => (isNotDesktop ? ['notes'] : []),
    [isNotDesktop]
  );

  const table = useTable({
    data,
    columns,
    initialState: {
      hiddenColumns: hiddenColumns,
    },
  });

  useEffect(() => {
    table.setHiddenColumns(hiddenColumns);
  }, [hiddenColumns, table]);

  return (
    <>
      <StickyTable
        css={css`
          .StickyTable-table {
            min-width: 1300px;

            .MuiTableBody-root .MuiTableCell-root {
              vertical-align: top;
            }
            .MuiTableBody-root .MuiTableRow-root {
              cursor: pointer;
              &:hover {
                background: ${theme.palette.grey[500_16]};

                td:first-of-type {
                  border-radius: 8px 0 0 8px;
                }

                td:last-child {
                  border-radius: 0 8px 8px 0;
                }
              }
            }
          }
        `}
        table={table}
        overflow
        onClickRow={(selectedRow) => {
          const rItem = sortedReservations[selectedRow.index];

          if (!rItem) return;

          onViewResrv(rItem.id);
        }}
      />
      {!sortedReservations?.length && (
        <Box className="div-center" sx={{ flexGrow: 1 }}>
          {renderEmpty || (
            <Box sx={{ textAlign: 'center' }}>
              <InboxTwoToneIcon
                sx={{ color: theme.palette.grey[500_48], fontSize: 72 }}
              />
              <Typography
                variant="body1"
                sx={{ color: theme.palette.grey[500] }}
              >
                {t('No upcoming reservations.')}
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default ListViewTable;
