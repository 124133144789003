import useI18nTimeUtils from '@/common/i18n-time-utils';
import { AreaTable } from '@/common/types/area';
import {
  getMultiTableReservationChipColor,
  getReservationStartEndTime,
} from '@/feat/reservation/utils';
import {
  useFloorPlanTable,
  useHandleTableClick,
} from '@/feat/settings/floor-plan-view/hooks/use-floor-plan-table';
import {
  Box,
  Chip,
  Stack,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { intervalToDuration } from 'date-fns';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { Trans } from 'next-i18next';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { reservationDrawerAtom } from '../../global-reservation-drawer';
import { useSelectedTime } from '../hooks/use-current-time';
import {
  areaByTableAtom,
  isTransformingAtom,
  reservationsByTableAtom,
  storeAtom,
} from '../state';
import FloatingTableAction from './floating-table-action';

import useChangeReservationStatus from '@/common/hooks/use-change-reservation-status';
import JoinRightIcon from '@mui/icons-material/JoinRight';
import LinkIcon from '@mui/icons-material/Link';
import PendingActionsIcon from '@mui/icons-material/PendingActionsOutlined';
import { useReassigningTable } from '../hooks/use-reassigning';
import {
  useClashingNextReservation,
  useClashingReservations,
  useFutureReservations,
  useLateReservation,
  useSeatedReservation,
  useUpcomingReservation,
} from '../hooks/use-table';
import { isBlockedTable } from '../utils';
import { format, parse } from 'date-fns';
import { DEFAULT_RESERVATION_DURATION } from '../../gantt-view/constants';
import { useTopNavDate } from '@/common/hooks/storage';
import { timeScheduleSelectAtom } from '@/feat/reservation/state';

interface ViewFloorPlanTable {
  table: AreaTable;
}

export default function ViewFloorPlanTable({ table }: ViewFloorPlanTable) {
  const boundingRef = useRef<HTMLDivElement | null>(null);
  const [boundingElm, setBoundingElm] = useState<HTMLDivElement | null>(null);
  const [
    {
      canvasListInteraction: { selectedTableIds, seatingTableId },
      groundControl: { tableInfo, seatedStatus, timeline },
      reservationList: { selectedReservationId },
    },
    dispatch,
  ] = useAtom(storeAtom);

  const isSeatingTable = seatingTableId === table.id;
  const isSelected = selectedTableIds.includes(table.id);
  const isTransforming = useAtomValue(isTransformingAtom);

  const {
    isAssignable,
    isCurrentAssigningTable,
    isReassigningMode,
    toggleReassigningTable,
  } = useReassigningTable(table);

  const theme = useTheme();
  const reservationsByTable = useAtomValue(reservationsByTableAtom);
  const areaByTableId = useAtomValue(areaByTableAtom);
  const selectedTime = useSelectedTime();
  const { compactDuration, formatDuration, formatDate } = useI18nTimeUtils();

  const reservations = useMemo(
    () => reservationsByTable[table.id] || [],
    [reservationsByTable, table.id]
  );
  const setReservationDrawer = useSetAtom(reservationDrawerAtom);

  // COMPUTED VALUE
  const upcomingReservation = useUpcomingReservation(reservations);
  const lateReservation = useLateReservation(reservations);
  const seatedReservation = useSeatedReservation(reservations);
  const clashingReservations = useClashingReservations(reservations);
  const clashNextReservation = useClashingNextReservation(reservations);
  const futureReservations = useFutureReservations(reservations);
  const [selectedDate] = useTopNavDate();
  const scheduleTimeSelect = useAtomValue(timeScheduleSelectAtom);

  const changeReservationStatusMutation = useChangeReservationStatus();

  const isBlockedOutTable = useMemo(() => {
    const area = areaByTableId[table.id];
    return area ? isBlockedTable(table, area, selectedTime) : true;
  }, [areaByTableId, selectedTime, table]);

  // EVENT HANDLERS
  const handleFloatingButtonClick = useCallback(
    (_: React.MouseEvent<HTMLElement>, value: string) => {
      if (value === 'add') {
        let reservationTimeSelect;

        if (
          scheduleTimeSelect === 'All Day' ||
          scheduleTimeSelect === 'Current Time'
        ) {
          reservationTimeSelect = undefined;
        } else {
          const parsedTime = parse(scheduleTimeSelect, 'hh:mm a', new Date());
          reservationTimeSelect = format(parsedTime, 'HH:mm:ss');
        }

        setReservationDrawer({
          mode: 'Add',
          initialValue: {
            diningInterval: DEFAULT_RESERVATION_DURATION / 1000,
            reservationDate: format(selectedDate, 'yyyy-MM-dd'),
            reservationTime: reservationTimeSelect,
            tables: [table],
          },
        });
      }
      if (value === 'seat') {
        dispatch({ type: 'SEATING_TABLE', tableId: table.id });
      }
      if (value === 'depart' && seatedReservation?.item) {
        changeReservationStatusMutation
          .mutateAsync({
            newStatus: 'R::COMPLETED',
            reservation: seatedReservation.item,
          })
          .then(() => {
            dispatch({ type: 'CLEAR_SELECTED_TABLES' });
          });
      }
      if (value === 'reassign' && seatedReservation?.item) {
        dispatch({
          type: 'INITIAL_REASSIGNING_RESERVATION',
          reassigningReservation: seatedReservation.item,
        });
      }
    },
    [
      changeReservationStatusMutation,
      dispatch,
      scheduleTimeSelect,
      seatedReservation,
      selectedDate,
      setReservationDrawer,
      table,
    ]
  );

  const handleMouseClick = useHandleTableClick(() => {
    if (!isBlockedOutTable) {
      if (isReassigningMode) {
        toggleReassigningTable();
        return;
      }

      dispatch({ type: 'SET_SELECTED_TABLE', tableId: table.id });
      if (
        seatedReservation &&
        selectedReservationId !== seatedReservation.item.id
      ) {
        dispatch({
          type: 'TOGGLE_SELECTED_RESERVATION',
          reservationId: seatedReservation.item.id,
        });
      }
    }
  });

  // UI
  const tableShapeBackgroundStyle: SxProps<Theme> = useMemo(() => {
    if (isBlockedOutTable) {
      return {
        border: `3px solid ${theme.palette.grey[500]}`,
        backgroundImage: `repeating-linear-gradient(135deg, transparent, transparent 10px, #DFE3E8  10px, #DFE3E8 20px )`,
      };
    }
    if (timeline === 'all-day' || timeline === 'upcoming') {
      return null;
    }
    if (seatedReservation && !isReassigningMode) {
      const { seatedPctg } = seatedReservation;
      return {
        border: `3px solid ${theme.palette.primary.main}`,
        backgroundImage: `linear-gradient(-${table.rotate}deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.light} ${seatedPctg}%, ${theme.palette.primary.main_12} ${seatedPctg}%, ${theme.palette.primary.main_12} 100%)`,
      };
    }
    if (isSelected || isCurrentAssigningTable) {
      return {
        background: theme.palette.primary.lighter,
        border: `3px solid ${theme.palette.primary.main}`,
      };
    }
    if (clashingReservations.length) {
      return {
        border: `3px solid ${theme.palette.error.main}`,
        background: theme.palette.error.lighter,
      };
    }
    if (isAssignable) {
      return {
        background: theme.palette.success.lighter,
        border: `3px solid ${theme.palette.success.dark}`,
      };
    }
    return null;
  }, [
    isBlockedOutTable,
    timeline,
    seatedReservation,
    isReassigningMode,
    isSelected,
    isCurrentAssigningTable,
    clashingReservations.length,
    isAssignable,
    theme.palette.grey,
    theme.palette.primary.light,
    theme.palette.primary.main_12,
    theme.palette.primary.lighter,
    theme.palette.primary.main,
    theme.palette.error.main,
    theme.palette.error.lighter,
    theme.palette.success.lighter,
    theme.palette.success.dark,
    table.rotate,
  ]);

  const tableBoundingStyle: SxProps<Theme> = useMemo(() => {
    const baseStyle: SxProps<Theme> = {
      boxSizing: 'border-box',
      padding: 2.25,
      alignItems: 'center',
      gap: 1.5,
      position: 'relative',
      borderRadius: 0.5,
    };
    if (isBlockedOutTable) {
      return baseStyle;
    }
    if (isReassigningMode) {
      if (isCurrentAssigningTable) {
        return [
          baseStyle,
          {
            background: theme.palette.primary.main_12,
          },
        ];
      }
      if (isAssignable) {
        return [baseStyle, { background: theme.palette.success.main_8 }];
      }
    }
    if (isSelected || isSeatingTable) {
      return [
        baseStyle,
        {
          background: theme.palette.primary.main_32,
        },
      ];
    }
    return baseStyle;
  }, [
    isBlockedOutTable,
    isReassigningMode,
    isSelected,
    isSeatingTable,
    isAssignable,
    theme,
    isCurrentAssigningTable,
  ]);

  const tableInnerElm = useMemo(() => {
    if (timeline === 'current') {
      if (seatedReservation && !isReassigningMode) {
        // seated reservation text
        const { seatedPctg, timeLeftDuration, seatedDuration, item } =
          seatedReservation;
        const { customerFirstName, pax } = item;
        const textStyle: SxProps<Theme> = [
          { textAlign: 'center' },
          seatedPctg > 60
            ? { color: theme.palette.common.white }
            : { textAlign: 'center', color: theme.palette.primary.main },
        ];
        const shortenedName =
          customerFirstName && customerFirstName.length > 10
            ? customerFirstName.substring(0, 10) + '...'
            : customerFirstName;
        return (
          <>
            <Typography variant="caption" sx={textStyle}>
              {table.name}
            </Typography>
            <Typography variant="caption" sx={textStyle}>
              {seatedPctg >= 100 ? (
                <Trans>Overstayed</Trans>
              ) : seatedStatus === 'time-left' ? (
                <Trans>
                  {{
                    time: compactDuration(
                      formatDuration(timeLeftDuration, {
                        format: ['hours', 'minutes'],
                      })
                    ),
                  }}{' '}
                  left
                </Trans>
              ) : (
                compactDuration(
                  formatDuration(seatedDuration, {
                    format: ['hours', 'minutes'],
                  })
                )
              )}
            </Typography>
            <Typography variant="caption" sx={textStyle}>
              {shortenedName}
              {tableInfo.seatedPax ? ` (${pax})` : null}
            </Typography>
          </>
        );
      }
      const hasClashed = clashingReservations.length;
      return (
        <>
          <Typography
            variant="subtitle2"
            sx={{
              textAlign: 'center',
              color: hasClashed ? theme.palette.error.main : undefined,
            }}
          >
            {table.name}
          </Typography>
          <Typography
            variant="caption"
            sx={{
              textAlign: 'center',
              color: hasClashed ? theme.palette.error.main : undefined,
            }}
          >
            <Trans>{{ pax: table.maxPax }} Pax</Trans>
          </Typography>
        </>
      );
    } else {
      return (
        <Stack gap={1}>
          <Stack sx={{ pt: 0.4 }} justifyContent="center" alignItems="center">
            <Typography variant="caption">{table.name}</Typography>
          </Stack>
          {(timeline === 'upcoming' ? futureReservations : reservations)
            .slice(0, 2)
            .map((r) => {
              const isClashed = clashingReservations.includes(r);
              const isLated = lateReservation === r;
              const isSeated = seatedReservation?.item === r;
              const isFuture = futureReservations.includes(r);
              const { start } = getReservationStartEndTime(r);
              return (
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    background: isClashed
                      ? theme.palette.error.main
                      : isLated
                      ? theme.palette.warning.main
                      : isSeated
                      ? theme.palette.primary.main
                      : isFuture
                      ? theme.palette.info.main
                      : theme.palette.grey[200],
                    width: '100%',
                    color: theme.palette.common.white,
                    borderRadius: '5px',
                    py: 0.2,
                  }}
                  key={r.id}
                >
                  <Typography variant="caption">
                    {formatDate(start, {
                      en: 'h:mm a',
                      zh: 'h:mm a',
                    })}
                  </Typography>
                </Stack>
              );
            })}
          {reservations.length - 2 > 0 && (
            <Stack
              sx={{
                background: theme.palette.primary.main,
                width: '100%',
                color: theme.palette.common.white,
                borderRadius: '5px',
                py: 0.2,
              }}
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="caption">
                + {reservations.length - 2}
              </Typography>
            </Stack>
          )}
        </Stack>
      );
    }
  }, [
    timeline,
    seatedReservation,
    isReassigningMode,
    clashingReservations,
    theme.palette.error.main,
    theme.palette.common.white,
    theme.palette.primary.main,
    theme.palette.warning.main,
    theme.palette.info.main,
    theme.palette.grey,
    table.name,
    table.maxPax,
    seatedStatus,
    compactDuration,
    formatDuration,
    tableInfo.seatedPax,
    futureReservations,
    reservations,
    lateReservation,
    formatDate,
  ]);

  const tableBelowElm = useMemo(() => {
    if (timeline === 'all-day' || timeline === 'upcoming') {
      return null;
    }
    if (isCurrentAssigningTable) {
      return (
        <Typography
          variant="subtitle2"
          sx={{ color: theme.palette.primary.main }}
        >
          <Trans>Selected</Trans>
        </Typography>
      );
    }
    if (
      seatedReservation &&
      seatedReservation.item.tables &&
      seatedReservation.item.tables.length >= 2
    ) {
      const numberOfAdults = seatedReservation.item.numberOfAdults || 0;
      const numberOfChildren = seatedReservation.item.numberOfChildren || 0;
      const chipColor = getMultiTableReservationChipColor(
        seatedReservation.item,
        theme
      );
      return (
        <Chip
          label={
            <Stack direction="row" gap={0.5}>
              <Stack direction="row">
                <Typography variant="body2">{numberOfAdults}</Typography>
                {!!numberOfChildren && (
                  <Typography variant="caption2">
                    +{numberOfChildren}
                  </Typography>
                )}
              </Stack>
              <LinkIcon fontSize="small" />
            </Stack>
          }
          size="small"
          color={chipColor?.chipColor}
          variant="outlined"
          sx={{
            background: chipColor?.background,
          }}
        />
      );
    }
    if (isSeatingTable) {
      return (
        <Typography
          variant="subtitle2"
          sx={{ color: theme.palette.primary.main }}
        >
          <Trans>Seating ...</Trans>
        </Typography>
      );
    }
    const upcomingOrLate = upcomingReservation || lateReservation;
    if (upcomingOrLate && !seatedReservation && !clashingReservations.length) {
      if (!tableInfo.upcomingName) {
        return null;
      }
      return (
        <Typography
          variant="subtitle2"
          sx={{
            color: upcomingReservation
              ? theme.palette.info.main
              : theme.palette.warning.main,
          }}
        >
          <Trans>
            {upcomingReservation?.customerFirstName ||
              lateReservation?.customerFirstName}
          </Trans>
        </Typography>
      );
    }
    if (clashingReservations.length && !seatedReservation) {
      return (
        <Typography
          variant="subtitle2"
          sx={{ color: theme.palette.error.main }}
        >
          <Trans>
            {{ noOfClashing: clashingReservations.length }} Clashing
          </Trans>
        </Typography>
      );
    }
    if (isAssignable) {
      return (
        <Typography
          variant="subtitle2"
          sx={{ color: theme.palette.success.dark }}
        >
          <Trans>Select Table</Trans>
        </Typography>
      );
    }

    return null;
  }, [
    clashingReservations.length,
    isAssignable,
    isCurrentAssigningTable,
    isSeatingTable,
    lateReservation,
    seatedReservation,
    tableInfo.upcomingName,
    theme,
    timeline,
    upcomingReservation,
  ]);

  const tableTopRightElm = useMemo(() => {
    if (
      isReassigningMode ||
      !tableInfo.upcomingReminder ||
      timeline === 'all-day' ||
      timeline === 'upcoming'
    ) {
      return null;
    }
    if (clashNextReservation) {
      return (
        <Stack
          flexDirection="row"
          gap={0.25}
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant="caption"
            sx={{ color: theme.palette.error.main }}
          >
            <Trans>Clash Next</Trans>
          </Typography>
          <JoinRightIcon color="error" fontSize="small" />
        </Stack>
      );
    }
    // upcoming & late should be prioritized
    if (upcomingReservation || lateReservation) {
      const r = upcomingReservation || lateReservation;
      if (!r) {
        return null;
      }
      const { start: reservationTime } = getReservationStartEndTime(r);
      const duration = compactDuration(
        formatDuration(
          intervalToDuration({
            start: reservationTime,
            end: selectedTime,
          })
        )
      );
      return (
        <Stack
          flexDirection="row"
          gap={0.25}
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant="caption"
            sx={{
              color: upcomingReservation
                ? theme.palette.info.main
                : theme.palette.warning.main,
            }}
          >
            {upcomingReservation ? (
              <Trans>In {{ duration }}</Trans>
            ) : (
              <Trans>Late {{ duration }}</Trans>
            )}
          </Typography>
          <PendingActionsIcon
            color={upcomingReservation ? 'info' : 'warning'}
            fontSize="small"
          />
        </Stack>
      );
    }
    // clashing
    if (clashingReservations.length) {
      return (
        <Stack
          flexDirection="row"
          gap={0.25}
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant="caption"
            sx={{ color: theme.palette.error.main }}
          >
            {clashNextReservation ? (
              <Trans>Clash Next</Trans>
            ) : (
              <Trans>Clashing</Trans>
            )}
          </Typography>
          <JoinRightIcon color="error" fontSize="small" />
        </Stack>
      );
    }

    return null;
  }, [
    clashNextReservation,
    clashingReservations.length,
    compactDuration,
    formatDuration,
    isReassigningMode,
    lateReservation,
    selectedTime,
    tableInfo.upcomingReminder,
    theme.palette.error.main,
    theme.palette.info.main,
    theme.palette.warning.main,
    timeline,
    upcomingReservation,
  ]);

  const { tableBaseStyle, wrapperBaseStyle } = useFloorPlanTable(table);

  useEffect(() => {
    // we need this effect to re-organize the popover when the ground is transforming
    setBoundingElm(isTransforming ? null : boundingRef.current);
  }, [isTransforming]);

  return (
    <Box
      sx={{
        position: 'absolute',
        transform: `translate(${table.x || 0}px, ${table.y || 0}px)`,
      }}
    >
      <Stack
        ref={boundingRef}
        sx={tableBoundingStyle}
        onMouseDown={(evt) => {
          evt.stopPropagation();
        }}
      >
        <Box sx={wrapperBaseStyle}>
          <Stack
            sx={[
              tableBaseStyle,
              timeline !== 'current' ? { justifyContent: 'flex-start' } : null,
              { cursor: isBlockedOutTable ? 'default' : 'pointer' },
              tableShapeBackgroundStyle,
            ]}
            {...handleMouseClick}
          />
          <Stack
            sx={[
              {
                position: 'absolute',
                left: '50%',
                top: timeline === 'current' ? '50%' : '0%',
                transform:
                  timeline === 'current'
                    ? 'translate(-50%, -50%)'
                    : 'translate(-50%, 0%)',
                pointerEvents: 'none',
                width: '100%',
              },
            ]}
          >
            {tableInnerElm}
          </Stack>
        </Box>
        {tableBelowElm}
        {tableTopRightElm ? (
          <Box
            sx={{
              position: 'absolute',
              p: 0.5,
              top: 0,
              right: 0,
              background: theme.palette.grey[100],
              borderRadius: 0.5,
            }}
          >
            {tableTopRightElm}
          </Box>
        ) : null}
      </Stack>
      {isSelected && !isSeatingTable && boundingElm && !isReassigningMode ? (
        <FloatingTableAction
          onButtonClick={handleFloatingButtonClick}
          anchorEl={boundingElm}
          enableDepartBtn={!!seatedReservation}
          enableReassignBtn={!!seatedReservation}
          enableSeatBtn={
            !seatedReservation &&
            futureReservations.length > 0 &&
            futureReservations.some(
              (it) =>
                it.rStatus === 'R::BOOKED' || it.rStatus === 'R::CONFIRMED'
            )
          }
          isDeparting={changeReservationStatusMutation.isLoading}
        />
      ) : null}
    </Box>
  );
}
