import { HeaderBranding } from '@/common/components/header-branding';
import { useTopNavDate } from '@/common/hooks/storage';
import useCallbackRef from '@/common/hooks/use-callback-ref';
import useDebounce from '@/common/hooks/use-debounce';
import {
  ReservationViewModeProvider,
  useReservationViewMode,
} from '@/common/hooks/use-reservation-view-mode';
import {
  useGetReservationSegmentTag,
  useReservations,
} from '@/common/hooks/use-reservations';
import { AuthGuard } from '@/common/guards/auth';
import LeftSideBar from '@/common/layouts/left-side-bar';
import { MobileTopNavSlot1 } from '@/common/layouts/left-side-bar/mobile-top-nav';
import TopBar from '@/common/layouts/top-bar';
import NotePopover from '@/common/layouts/top-bar/note-popover';
import { usePageAnalytics } from '@/common/lib/segment';
import { NextPageWithLayout, assertUnreachable } from '@/common/utils';
import { FloorPlanView } from '@/feat/reservation/components/floor-plan-view';
import GanttView from '@/feat/reservation/components/gantt-view';
import {
  IDataTab,
  ListViewProps,
  TabStatus,
  statusesTab,
} from '@/feat/reservation/components/list-view/common';
import { DesktopListView } from '@/feat/reservation/components/list-view/desktop-list-view';
import { MobileListView } from '@/feat/reservation/components/list-view/mobile-list-view';
import { useTimeBlock } from '@/feat/reservation/context';
import { css } from '@emotion/react';
import { format } from 'date-fns';
import { groupBy, sumBy } from 'lodash-es';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import {
  commonSSRProps,
  withSessionSsr,
} from '@/feat/auth/iron-session.server';
import SearchButton from '@/common/layouts/top-bar/search-button';
import { useSetAtom } from 'jotai';
import { reservationSegmentsAtom } from '@/feat/reservation/state';
import { useRefetchReservationsOnEvents } from '@/common/hooks/use-refetch-reservation-on-event';

export default function ReservationPage() {
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [inputSearch, setInputSearch] = useState('');
  const debounceInputSearch = useDebounce(inputSearch);
  const [topNavDate] = useTopNavDate();
  const { timeBlock } = useTimeBlock();
  const [viewMode] = useReservationViewMode();

  const {
    data: dataGetReservations,
    isFetching,
    refetch: refetchReservations,
  } = useReservations({
    params: {
      date: format(topNavDate, 'yyyy-MM-dd'),
      keysearch: isSearchActive ? debounceInputSearch : '',
      startTime: timeBlock.start
        ? `${format(topNavDate, 'yyyy-MM-dd')} ${timeBlock.start}`
        : '',
      endTime: timeBlock.end
        ? `${format(topNavDate, 'yyyy-MM-dd')} ${timeBlock.end}`
        : '',
    },
  });

  const [_tab, setTab] = React.useState<TabStatus>('ALL');
  const tab: TabStatus = isSearchActive ? 'ALL' : _tab;
  const changeTab = useCallbackRef((tab: TabStatus) => {
    setInputSearch('');
    return setTab(tab);
  });
  const dataTabs = React.useMemo<IDataTab[]>(() => {
    const tempDataTab: IDataTab[] = [
      { type: 'ALL', data: [], numPax: 0, numReservations: 0 },
      // { type: 'REQUESTED', data: [], numPax: 0, numReservations: 0 },
      { type: 'UPCOMING', data: [], numPax: 0, numReservations: 0 },
      { type: 'SEATED', data: [], numPax: 0, numReservations: 0 },
      { type: 'COMPLETED', data: [], numPax: 0, numReservations: 0 },
      { type: 'ABSENT', data: [], numPax: 0, numReservations: 0 },
      { type: 'PENDING', data: [], numPax: 0, numReservations: 0 },
      { type: 'EXPIRED', data: [], numPax: 0, numReservations: 0 },
    ];

    if (!dataGetReservations) return tempDataTab;
    const { data, total, totalPax } = dataGetReservations;
    if (!data || !data.length) return tempDataTab;

    const groupDataByStatus = groupBy(data, (item) => item.status); // is Record<status, reservation[]>
    tempDataTab.forEach((item) => {
      if (item.type === 'ALL') {
        item.data = data;
        item.numPax = isSearchActive
          ? sumBy(data, (reservation) => reservation.pax || 0)
          : totalPax;
        item.numReservations = isSearchActive ? data.length : total;
        return;
      }

      statusesTab[item.type].forEach((statusTab) => {
        item.data.push(...(groupDataByStatus[statusTab] || []));
      });
      item.numPax = sumBy(item.data, (reservation) => reservation.pax || 0);
      item.numReservations = item.data.length;
    });

    return tempDataTab;
  }, [dataGetReservations, isSearchActive]);
  const tabData = React.useMemo(
    () => dataTabs.find((item) => item.type === tab)?.data || [],
    [dataTabs, tab]
  );

  const allTabData = useMemo(() => {
    const allTab = dataTabs.find((item) => item.type === 'ALL');
    return allTab ? allTab.data : [];
  }, [dataTabs]);

  const customerIds = useMemo(() => {
    const uniqueIds = new Set(allTabData.map((item) => item.customerId));
    return Array.from(uniqueIds).filter((id) => id !== null) as string[];
  }, [allTabData]);

  const setReservationSegmentsAtom = useSetAtom(reservationSegmentsAtom);
  const { flatData } = useGetReservationSegmentTag(
    customerIds.length > 0 ? { customerIds } : {}
  );

  useEffect(() => {
    setReservationSegmentsAtom(flatData);
  }, [flatData, setReservationSegmentsAtom]);

  useRefetchReservationsOnEvents(refetchReservations);
  usePageAnalytics();

  const listViewProps: ListViewProps = {
    isFetching,
    isSearchActive,
    setIsSearchActive,
    inputSearch,
    setInputSearch,
    tab,
    changeTab,
    dataTabs,
    tabData,
  };

  return (
    <>
      {(() => {
        const desktopListView = <DesktopListView {...listViewProps} />;
        if (viewMode === 'timeline') {
          return <GanttView />;
        }
        if (viewMode === 'list' || viewMode === null) {
          return desktopListView;
        }
        if (viewMode === 'floorplan') {
          return <FloorPlanView />;
        }
        assertUnreachable(viewMode);
      })()}
      <MobileListView {...listViewProps} />
    </>
  );
}

const getLayout: NextPageWithLayout['getLayout'] = (page: ReactElement) => (
  <AuthGuard>
    <ReservationViewModeProvider>
      <LeftSideBar
        mainCss={css`
          height: 100%;
          overflow: hidden;
        `}
      >
        <MobileTopNavSlot1.Source>
          <div
            css={css`
              padding-top: 16px;
              display: flex;
              & > .NoteBtn-root {
                font-size: 32px;
                padding: 12px;
                margin: -12px;
              }
              & > * {
                min-width: 0;
              }
            `}
          >
            <HeaderBranding />
            <div style={{ flex: 1 }} />
            <SearchButton />
            <div style={{ width: 12 }} />
            <NotePopover />
          </div>
        </MobileTopNavSlot1.Source>
        <TopBar sx={{ mb: 2 }} />
        {page}
      </LeftSideBar>
    </ReservationViewModeProvider>
  </AuthGuard>
);
ReservationPage.getLayout = getLayout;

export const getServerSideProps = withSessionSsr(async (ctx) => {
  return { props: await commonSSRProps(ctx) };
});
