import { Typography, useTheme } from '@mui/material';
import LogoOddle from '../assets/logo-oddle';
import useOutletSwitch from '../hooks/use-outlet-switch';
import { css } from '@emotion/react';

export function HeaderBranding() {
  const { selectedBrand, selectedOutlet } = useOutletSwitch();
  const { palette } = useTheme();

  return (
    <div
      css={css`
        display: flex;
      `}
    >
      <LogoOddle
        width={72}
        height={72}
        css={css`
          margin: -16px;
        `}
      />
      <div
        css={css`
          padding: 0 10px;
        `}
      >
        <Typography style={{ fontWeight: 600, color: palette.grey[800] }}>
          {selectedBrand?.brandName}
        </Typography>
        <Typography
          variant="body2"
          style={{
            fontSize: '14px',
            fontWeight: 400,
            color: palette.grey[600],
          }}
        >
          {selectedOutlet?.outletName}
        </Typography>
      </div>
    </div>
  );
}
