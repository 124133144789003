import { AreaTable } from '@/common/types/area';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import { areaByTableAtom, isReassigningModeAtom, storeAtom } from '../state';
import { isBlockedTable } from '../utils';
import { useSelectedTime } from './use-current-time';

export const useReassigningTable = (table: AreaTable) => {
  const [
    {
      reassignTable: { newTableIds },
    },
    dispatch,
  ] = useAtom(storeAtom);
  const areaByTableId = useAtomValue(areaByTableAtom);
  const isReassigningMode = useAtomValue(isReassigningModeAtom);
  const isCurrentAssigningTable = newTableIds?.some(
    (t) => t === String(table?.id)
  );

  const selectedTime = useSelectedTime();

  const isAssignable = useMemo(() => {
    if (!isReassigningMode) {
      return false;
    }
    const area = areaByTableId[table.id];
    if (!area) {
      return false;
    }
    return !isBlockedTable(table, area, selectedTime);
  }, [areaByTableId, isReassigningMode, selectedTime, table]);

  const toggleReassigningTable = useCallback(() => {
    dispatch({
      type: 'UPDATE_NEW_TABLES',
      newTableIds: newTableIds.includes(table.id)
        ? newTableIds.filter((t) => t !== table.id)
        : newTableIds.concat(table.id),
    });
  }, [dispatch, newTableIds, table.id]);

  return {
    isReassigningMode,
    isAssignable,
    isCurrentAssigningTable,
    toggleReassigningTable,
  };
};
